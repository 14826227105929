import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AlignRightOutlined, NotificationOutlined } from '@ant-design/icons';
import { Drawer, Badge } from 'antd';
import SVG from 'react-inlinesvg';
import "antd/dist/antd.css";

import logo from '../../assets/svg/logo.svg'

const Header = ({ sActiveNoti, userInfo, fnChangeState, fnSignOut }) => {
    const [visible, setVisible] = useState(false);
    const [activeNoti, setActiveNoti] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    }

    const onClose = () => {
        setVisible(false);
    };

    const changeState = async (sContext) => {
        if (typeof (fnChangeState) === 'function') {
            await fnChangeState(sContext);
        }
    }

    const signOut = async (sContext) => {
        if (typeof (fnSignOut) === 'function') {
            await fnSignOut();
        }
    }

    const CalculatePage = () => {
        return (
            <Drawer 
                title={<DrawerPTag>정산팀</DrawerPTag>}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <DrawerButtonTag onClick={() => changeState("calculate")}>정산 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("faxfee")}>세금계산서 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={signOut}>로그아웃</DrawerButtonTag>
            </Drawer>
        )
    }

    const DevelopPage = () => {
        return (
            <Drawer 
                title={<DrawerPTag>개발팀</DrawerPTag>}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <DrawerButtonTag onClick={signOut}>로그아웃</DrawerButtonTag>
            </Drawer>
        )
    }

    const MasterPage = () => {
        return (
            <Drawer 
                title={<DrawerPTag>대표님</DrawerPTag>}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <DrawerButtonTag onClick={() => changeState("web_banner")}>웹 배너 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("web_inquiry")}>홍보물 신청 내역</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("web_notice")}>웹 공지사항 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("app_notice")}>앱 공지사항 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("app_inquiry")}>앱 문의사항 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("user")}>회원 정보 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("order")}>주문 내역 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("store")}>매장 정보 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("calculate")}>정산 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("commercial")}>광고 내역</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("faxfee")}>세금계산서 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={signOut}>로그아웃</DrawerButtonTag>
            </Drawer>
        )
    }
    

    const MarketingPage = () => {
        return (
            <Drawer 
            title={<DrawerPTag>마케팅팀</DrawerPTag>}
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            >
                <DrawerButtonTag onClick={() => changeState("web_banner")}>웹 배너 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("commercial")}>광고 내역</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("web_inquiry")}>홍보물 신청 내역</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("web_notice")}>웹 공지사항 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("app_notice")}>앱 공지사항 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("app_inquiry")}>앱 문의사항 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("user")}>회원 정보 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("order")}>주문 내역 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={() => changeState("store")}>매장 정보 관리</DrawerButtonTag>
                <DrawerButtonTag onClick={signOut}>로그아웃</DrawerButtonTag>
            </Drawer>
        )
    }

    useEffect(() => {
        setActiveNoti(sActiveNoti);
    }, [sActiveNoti]);

    return (
        <Nav>
            <div className="navbar_container">
                <div className="nav_logo" onClick={() => changeState("dashboard")}>
                    <SVG className="logo" src={logo} width="4vw" padding="0" title="logo"/>
                    <span style={{color : "#fff", margin: "0 2% 0 5%"}}></span>
                    {userInfo.Name}님
                    {activeNoti &&
                        <div className="nav_btn_link_noti">
                            <Badge dot>
                                <NotificationOutlined />
                            </Badge>
                        </div>
                    }
                </div>
                <div className="mobile_icon" onClick={showDrawer}>
                    <AlignRightOutlined />
                </div>
                <div className="nav_btn">
                    <div className="nav_btn_link_info">
                        {userInfo.Team}
                    </div>
                    <div className="nav_btn_link" onClick={showDrawer}>
                        <AlignRightOutlined/>
                    </div>
                </div>
            </div>
            {userInfo.Roll === "accounting" && <CalculatePage />}
            {userInfo.Roll === "marketing" && <MarketingPage />}
            {userInfo.Roll === "master" && <MasterPage />}
            {userInfo.Roll === "develop" && <DevelopPage />}
            
        </Nav>
    )
}

const DrawerPTag = styled.p`
    border-radius: 30px;
    background: #fff;
    white-space: nowrap;
    padding: 15px 20px;
    font-size: 0.9rem;
    outline: none;
    border: none;
    text-decoration: none;
    font-weight: bold;

`;

const DrawerButtonTag = styled.button`
    border-radius: 20px;
    background: #fff;
    white-space: nowrap;
    padding: 10px 20px;
    color: #001e62;
    font-size: 0.9rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        color: #fff;
        transition: all 0.2s ease-in-out;
        background: #001e62;
    }
`;

const Nav = styled.nav`
    background: #fff;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    .navbar_container {
        display: flex;
        justify-content: space-between;
        height: 60px;
        z-index: 1;
        width: 100%;
        margin: 0 auto;
        padding: 0 24px;
        max-width: 1300px;
    }

    .nav_logo {
        justify-self: flex-start;
        align-items: center;
        display: flex;
        font-weight: bold;
        text-decoration: none;
        width: 300px;
        img {
            height: 60px;
        }
    
        img.rounded {
            @media screen and (max-width: 768px) {
                height: 40px;
            }
    
        }
        .logo {
            @media screen and (max-width: 1100px) {
                width: 6vw;
            }
            @media screen and (max-width: 800px) {
                width: 8vw;
            }
            @media screen and (max-width: 600px) {
                width: 10vw;
            }
            @media screen and (max-width: 450px) {
                width: 15vw;
            }
        }
    }

    .mobile_icon {
        display: none;

        @media screen and (max-width: 768px) {
            display: block;
            position: absolute;
            margin: 0;
            top: 0;
            right: 0;
            transform: translate(-180%, 60%);
        }
    }

    .nav_btn {
        display: flex;
        align-items: center;
        
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .nav_btn_link_info {
        border-radius: 5px;
        background: #fff;
        white-space: nowrap;
        padding: 3px 20px;
        outline: none;
        border: none;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: bold;
        margin-right: 10%;
        margin-top: 5%;
    }
    
    .nav_btn_link_noti {
        margin-left: 0%;
        margin-bottom: 9%;
    }

    
    .nav_btn_link {
        border-radius: 20px;
        background: #fff;
        white-space: nowrap;
        padding: 10px 15px;
        color: #333d4b;
        outline: none;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: bold;
        
        &:hover {
            transition: all 0.2s ease-in-out;
            background: #cc2431;
            color: #fff;
        }
    }

    @media screen and (max-width: 960px) {
        height: 60px;
        transition: 0.8s all ease;
    }
    
`;


export default Header;