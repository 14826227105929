import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import Aos from 'aos';
import "aos/dist/aos.css";

import { MasterPageStyle } from '../../assets/styleComponents/DashboardStyle';

import { OrderList, UserType, Store, WeeklyOrderList, User, UserLocation, WebsiteChart, WebsiteUserChart, WebsiteCEOPathChart, WebsiteCEOChart, Order } from '../../components/dashboard/DashboardItem';

const Dashboard = ({ sProps, fnChangeState }) => {
    const [fLoad, setFLoad] = useState(false);
    const [sLoad, setSLoad] = useState(false);
    const [uPieChartfLoad, setUPieLoad] = useState(false);
    const [oChartfLoad, setOLoad] = useState(false);
    const [uChartfLoad, setULoad] = useState(false);
    const [sChartfLoad, setSCLoad] = useState(false);
    const [vChartfLoad, setVLoad] = useState(false);
    const [mChartfLoad, setMCLoad] = useState(false);
    const [nChartfLoad, setNCLoad] = useState(false);
    const [hChartfLoad, setHCLoad] = useState(false);
    const [gChartfLoad, setGCLoad] = useState(false);

    const [iUserList, setUList] = useState({});
    const [iOrderList, setOList] = useState([]);
    const [iUserPieList, setUserPieList] = useState({});
    const [iOrderChart, setOrderChart] = useState({});
    const [sOrderChart, setSOrderChart] = useState({});
    const [iUserChart, setUserChart] = useState({});
    const [iStoreChart, setStoreChart] = useState({});
    const [iWebUserChart, setWebUserChart] = useState({});
    const [iWebTypeChart, setWebTypeChart] = useState({});
    const [iWebCEOChart, setWebCEOChart] = useState({});
    const [iWebCEOTypeChart, setWebCEOTypeChart] = useState({});

    const asyncAxios = async () => {
        const oResponse = await sProps.oManager.fDoAxios("/admin/dashboard", "post", null, null);
        if (oResponse !== undefined && oResponse !== null) {
            if (oResponse.appleId !== undefined && oResponse.appleId !== null
                && oResponse.applePercent !== undefined && oResponse.applePercent !== null
                && oResponse.kakaoId !== undefined && oResponse.kakaoId !== null
                && oResponse.kakaoPercent !== undefined && oResponse.kakaoPercent !== null
                && oResponse.normal !== undefined && oResponse.normal !== null
                && oResponse.normalPercent !== undefined && oResponse.normalPercent !== null
                && oResponse.total !== undefined && oResponse.total !== null
                && oResponse.androidUser !== undefined && oResponse.androidUser !== null
                && oResponse.appleUser !== undefined && oResponse.appleUser !== null
                && oResponse.androidUserPercent !== undefined && oResponse.androidUserPercent !== null
                && oResponse.appleUserPercent !== undefined && oResponse.appleUserPercent !== null){
                let temp = {};
                temp.appleId = oResponse.appleId;
                temp.applePercent = oResponse.applePercent;
                temp.kakaoId = oResponse.kakaoId;
                temp.kakaoPercent = oResponse.kakaoPercent;
                temp.normalPercent = oResponse.normalPercent;
                temp.appleUser = oResponse.appleUser;
                temp.androidUser = oResponse.androidUser;
                temp.androidUserPercent = oResponse.androidUserPercent;
                temp.appleUserPercent = oResponse.appleUserPercent;
                temp.normal = oResponse.normal;
                temp.total = oResponse.total;
                setFLoad(true);
                setUList(temp);
            }

            if (oResponse.orderList !== undefined && oResponse.orderList !== null && oResponse.orderList.length > 0 ){
                setSLoad(true);
                setOList(oResponse.orderList);
            }
            if (oResponse.pieChart !== undefined && oResponse.pieChart !== null && oResponse.pieChart.length > 0 ){
                const config = {
                    appendPadding: 10,
                    data: oResponse.pieChart,
                    angleField: 'value',
                    colorField: 'type',
                    radius: 1,
                    innerRadius: 0.6,
                    label: {
                      type: 'inner',
                      offset: '-50%',
                      content: '{value}',
                      style: {
                        textAlign: 'center',
                        fontSize: 14,
                      },
                    },
                    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
                    statistic: {
                      title: false,
                      content: {
                        style: {
                          whiteSpace: 'pre-wrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }
                      },
                    },
                };
                setUserPieList(config);
                setUPieLoad(true);
            }
            if (oResponse.aChart !== undefined && oResponse.aChart !== null && oResponse.aChart.length > 0 ){
                const config = {
                    data: oResponse.aChart,
                    xField: 'type',
                    yField: 'sales',
                    label: {
                      position: 'middle',
                      style: {
                        fill: '#FFFFFF',
                        opacity: 0.6,
                      },
                    },
                    meta: {
                      type: { alias: '일' },
                      sales: { alias: '건수' },
                    },
                };
                setOrderChart(config);
                setOLoad(true);
            }
            if (oResponse.kChart !== undefined && oResponse.kChart !== null && oResponse.kChart.length > 0 ){
                const config = {
                    data: oResponse.kChart,
                    xField: 'date',
                    yField: 'scales',
                    seriesField: 'name',
                    yAxis: {},
                    smooth: true,
                    animation: {
                        appear: {
                            animation: 'path-in',
                            duration: 5000,
                        },
                    },
                };
                setUserChart(config);
                setULoad(true);
            }
            if (oResponse.wChart !== undefined && oResponse.wChart !== null && oResponse.wChart.length > 0 ){
                const config = {
                    data: oResponse.wChart,
                    xField: 'date',
                    yField: 'scales',
                    seriesField: 'name',
                    yAxis: {},
                    smooth: true,
                    animation: {
                        appear: {
                            animation: 'path-in',
                            duration: 5000,
                        },
                    },
                };
                setStoreChart(config);
                setSCLoad(true);
            }
            if (oResponse.vChart !== undefined && oResponse.vChart !== null && oResponse.vChart.length > 0 ){
                const config = {
                    data: oResponse.vChart,
                    xField: 'date',
                    yField: 'scales',
                    seriesField: 'name',
                    yAxis: {},
                    smooth: true,
                    animation: {
                        appear: {
                            animation: 'path-in',
                            duration: 5000,
                        },
                    },
                };
                setSOrderChart(config);
                setVLoad(true);
            }
            if (oResponse.mChart !== undefined && oResponse.mChart !== null && oResponse.mChart.length > 0 ){
                const config = {
                    data: oResponse.mChart,
                    padding: 'auto',
                    xField: 'date',
                    yField: 'scales',
                    smooth: true,
                    xAxis: {
                      type: 'timeCat',
                      tickCount: 5,
                    },
                    
                };
                setWebUserChart(config);
                setMCLoad(true);
            }
            if (oResponse.nChart !== undefined && oResponse.nChart !== null && oResponse.nChart.length > 0 ){
                const config = {
                    data: oResponse.nChart,
                    xField: 'date',
                    yField: 'value',
                    seriesField: 'type',
                    isGroup: 'true',
                    columnStyle: {
                        radius: [20, 20, 0, 0],
                    },
                };
                setWebTypeChart(config);
                setNCLoad(true);
            }
            
            if (oResponse.gChart !== undefined && oResponse.gChart !== null && oResponse.gChart.length > 0 ){
                const config = {
                    data: oResponse.gChart,
                    padding: 'auto',
                    xField: 'date',
                    yField: 'scales',
                    smooth: true,
                    xAxis: {
                      type: 'timeCat',
                      tickCount: 5,
                    },
                    
                };
                setWebCEOChart(config);
                setGCLoad(true);
            }
            if (oResponse.hChart !== undefined && oResponse.hChart !== null && oResponse.hChart.length > 0 ){
                const config = {
                    data: oResponse.hChart,
                    xField: 'date',
                    yField: 'value',
                    seriesField: 'type',
                    isGroup: 'true',
                    columnStyle: {
                        radius: [20, 20, 0, 0],
                    },
                };
                setWebCEOTypeChart(config);
                setHCLoad(true);
            }
            
        }
    }

    const changeState = async () => {
        if(fnChangeState !== undefined && typeof fnChangeState === "function"){
            await fnChangeState("order");
        }
    }
    useEffect(() => {
        asyncAxios();
    },[]);

    useEffect(() => {
        Aos.init({ duration: 1000 });
    })

    return (
        <MasterPageStyle>
            <div style={{height: "5vh"}} />
            <div className="container">
                <Row>
                    <Col sm={3}>
                        <div data-aos="fade-up" style={{margin: "5%",height: 550, backgroundColor: "#FFB802", borderRadius: "20px"}}>
                            {!fLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#fff" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <UserType userList={iUserList}/>
                            }
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div data-aos="fade-up" data-aos-delay="200" style={{margin: "5%",height: 550, backgroundColor: "#FFFFFF", borderRadius: "20px"}}>
                            {!uPieChartfLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <UserLocation userPieList={iUserPieList} />
                            }
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div data-aos="fade-up" data-aos-delay="300" style={{margin: "5%",height: 550, backgroundColor: "#094152", borderRadius: "20px"}}>
                            {!sLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#fff" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <OrderList orderList={iOrderList} fnGoToOrder={() => changeState()}/>
                            }
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div data-aos="fade-up" data-aos-delay="400" style={{margin: "5%",height: 550, backgroundColor: "#F2F5FD", borderRadius: "20px"}}>
                            {!oChartfLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <WeeklyOrderList orderChart={iOrderChart}/>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="leftchartBox">
                            {!uChartfLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <div style={{padding: "5%"}}>
                                    <User userChart={iUserChart}/>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="leftchartBox">
                            {!sChartfLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <div style={{padding: "5%"}}>
                                    <Store storeChart={iStoreChart}/>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="leftchartBox">
                            {!vChartfLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <div style={{padding: "5%"}}>
                                    <Order orderChart={sOrderChart}/>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <div className="left_website_chartBox">
                            {!mChartfLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <div style={{padding: "5%"}}>
                                    <WebsiteChart webUserChart={iWebUserChart}/>
                                </div>
                            }
                        </div>
                    </Col>
                    <Col sm={8}>
                        <div className="right_website_chartBox">
                            {!nChartfLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <div style={{padding: "5%"}}>
                                    <WebsiteUserChart webTypeChart={iWebTypeChart}/>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <div className="left_website_chartBox">
                            {!gChartfLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <div style={{padding: "5%"}}>
                                    <WebsiteCEOChart webUserChart={iWebCEOChart}/>
                                </div>
                            }
                        </div>
                    </Col>
                    <Col sm={8}>
                        <div className="right_website_chartBox">
                            {!hChartfLoad ? 
                                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                                </div>
                            :
                                <div style={{padding: "5%"}}>
                                    <WebsiteCEOPathChart webTypeChart={iWebCEOTypeChart}/>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </MasterPageStyle>
    )
}


export default Dashboard;
