import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import { Drawer, message } from 'antd';
import "antd/dist/antd.css";

import { UnorderedListOutlined } from '@ant-design/icons';
import addImg from '../../../assets/svg/webBanner.svg';
import none_image from '../../../assets/svg/none_image.svg';

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}

const WebBanner = (oProps) => {
    const [sLoading, setLoading] = useState(true);

    const [isVisible, setVisible] = useState(false);

    const [data, setData] = useState([]);

    const [sType, setType] = useState("mobile");
    const [sNm, setNm] = useState("");
    const [sImage, setImage] = useState("");
    const [sFile, setFileList] = useState("");

    const onChangeType = (event) => {
        const value = event.target.value;
        setType(value);
    }
    const onChangeName = (event) => {
        const value = event.target.value;
        setNm(value);
    }

    const uploadLogoImage = async (e) => {
        let origin = e.target.files[0];
        if(origin !== undefined){
          if(origin.type !== undefined && origin.type != null){
            if(origin.type === "image/png" || origin.type === "image/jpeg"){
                setFileList(origin);
              origin = await getBase64(origin);
              setImage(origin);
            }
          }
        }
    }

    const imageProcess = async (fileList) => {
        let logoUpload = {};

        const formData = new FormData();
        formData.append('photo', fileList);

        const oResponse = await oProps.oManager.fDoAxios("/admin/store/register/bannerLogo", "post", "multipart", formData)
        if(oResponse !== undefined){
            if(oResponse.file_name !== undefined){
                logoUpload = oResponse;
            }
        }
        return logoUpload;
    }

    const insertAction = async () => {
        let uploadPic = {};

        if(sNm === ""){
            message.error('제목을 입력하세요');
            return false;
        }

        if (sFile === ""){
            message.error('이미지가 없습니다');
            return false;
        } else {
            const upload = await imageProcess(sFile);
            if(upload === ""){
                message.error("에러");
                return false;
            } else {
                uploadPic = upload;
            }
        }

        const oData = {
            userId: oProps.UserConfigReducer.UserId,
            sNm,
            sType,
            uploadPic,
            sIndex: "insert"
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/manage/homepage/bannerAction", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            if(oResponse){
                setType("mobile");
                setNm("");
                setImage("");
                setFileList("");
                setLoading(true);
                asyncFetch();
            } else {
                message.error("에러");
            }
        }
    }
    
    const deleteAction = async (aIndex,iParam) => {
        let kIndex = 0;
        if(!iParam){
            kIndex = 1;
        }

        const oData = {
            userId: oProps.UserConfigReducer.UserId,
            sIndex: "delete",
            aIndex,
            kIndex
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/manage/homepage/bannerAction", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            if(oResponse){
                setLoading(true);
                asyncFetch();
            } else {
                message.error("에러");
            }
        }
    }
    
    const asyncFetch = async () => {
        const oData = {
            userId: oProps.UserConfigReducer.UserId,
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/manage/web/bannerList", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            setData(oResponse);
        }
        setLoading(false);
    };
    
    useEffect(() => {
        asyncFetch();
    }, []);

    return (
        <UserStyle>
            {sLoading ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                    <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                </div> 
                :
                <div className="container">
                    <Row style={{marginTop: 70}}>
                        <Col sm={4} style={{height: 600}}>
                            <div className="add_row">
                                <div className="title">
                                    <div className="title_line">
                                        <div className="insert_button">
                                            <p>사장님창구 배너 추가하기</p>
                                        </div>
                                    </div>
                                    <div className="insert_button_line">
                                        <div className="insert_button" onClick={() => setVisible(true)}>
                                            <p>추가하기</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <img src={addImg} alt="none" />
                                </div>
                            </div>
                        </Col>
                        <Col sm={8} style={{height: 600}}>
                            <div className="list_row">
                                <div className="title_line">
                                    <UnorderedListOutlined style={{fontSize: "1.5rem"}}/>
                                    <p>등록된 배너 목록</p>
                                </div>
                                <div className="content_line">
                                    {data.map((item,index) => (
                                        <div className="list_box" key={index}>
                                            <div className="list_title">
                                                <div className="list_title_name">
                                                    <img src={item.url_path} alt="none"/>
                                                </div>
                                            </div>
                                            <div className="list_content">
                                                <div className="list_title_name">
                                                    <p>{item.title}</p>
                                                </div>
                                                <div className="list_title_company">
                                                    <p>{item.type}</p>
                                                </div>
                                            </div>
                                            <div className="list_button_line" onClick={() => deleteAction(item.key,item.status)}>
                                                <div className={item.status !== undefined && item.status !== null && item.status ? "list_button_delete" : "list_button"}>
                                                <p>{item.status ? "비활성화" : "활성화"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            <Drawer
                title={<DrawerPTag>배너 추가하기</DrawerPTag>}
                width={440}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={isVisible}
            >
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>위치선택</p>
                            <select 
                                value={sType} 
                                onChange={onChangeType}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            >
                                <option value="mobile">모바일</option>
                                <option value="banner">pc</option>
                            </select>
                        </div>
                        <div style={{height: 80, marginBottom: "50px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>제목</p>
                            <input 
                                type="text"
                                value={sNm} 
                                onChange={onChangeName}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        <div style={{height: 370}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>이미지</p>
                            <label htmlFor="logo_file" style={{width: "100%",height: 300,backgroundColor: "#f9f9f9", cursor: "pointer", borderRadius: "2em"}}>
                                {sImage !== "" ?
                                    <img style={{width: "100%", height: 300}} alt="logo" src={sImage}/>
                                    :
                                    <img style={{width: "100%", height: 300}} src={none_image} alt="none"/>
                                }
                                <input style={{position: "absolute", width: "1px", height: "1px", padding: 0, margin: "-1px", overflow: "hidden", clip:"rect(0,0,0,0)", border: 0}} type="file" id="logo_file" onChange={(e) => uploadLogoImage(e)}/>
                            </label> 
                        </div>
                        <DrawerButton onClick={insertAction}>
                            <p>추가하기</p>
                        </DrawerButton>
                    </Col>
                </Row>
            </Drawer>
        </UserStyle>
    )
}

export default WebBanner;


const DrawerPTag = styled.p`
    border-radius: 30px;
    background: #fff;
    white-space: nowrap;
    padding: 0px 5px;
    font-size: 0.9rem;
    outline: none;
    border: none;
    text-decoration: none;
    font-weight: bold;

`;


const DrawerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 10px;
    padding-top: 15px;
    background: #3d5a80;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        font-weight: 500;
        color: #fff;
    }

`;

export const UserStyle = styled.div`
    .container {
        width: 100%;
    }

    .insert_view {
        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
        }
    }

    .add_row {
        border-radius: 10px;
        border: 1px solid #3d5a80;
        width: 100%;
        height: 550px;


        .title {
            height: 400px;

            .title_line {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                height: 120px;

                p {
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }

            .insert_button_line {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                height: 180px;
                
                .insert_button {
                    border-radius: 15px;
                    width: 150px;
                    height: 40px;
                    border: 1px solid #06d6a0;
                    cursor: pointer;

                    p {
                        margin-top: 10px;
                        text-align: center;
                        font-size: 0.9rem;
                        font-weight: 500;
                        color: #06d6a0;
                    }
                }

                .insert_button:hover {
                    background: #06d6a0;
                    p {
                        color: #fff;
                    }
                }
            }
        }
        .content {
            height: 140px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .list_row {
        border-radius: 10px;
        border: 1px solid #98c1d9;
        width: 100%;
        height: 550px;

        .title_line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 50px;
            margin-left: 20px;

            p {
                margin-left: 10px;
                margin-top: 15px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .content_line {
            position: "relative";
            overflow-y: scroll;
            overflow-x: hidden;
            margin : 20px;
            height: 460px;

            .list_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px; 
                height: 100px;
                border-bottom: 1px solid #293241;

                .list_title {
                    width: 30%;
                    height: 80px;

                    .list_title_name {
                        display: flex;
                        height: 80px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .list_content {
                    width: 40%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        height: 25px;
                        p {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                    .list_title_company {
                        display: flex;
                        height: 25px;

                        p {
                            font-size: 0.7rem;
                            font-weight: 500;
                            color: #bbbbbb;
                        }
                    }
                }
                .list_button_line {
                    width: 20%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .list_button {
                        width: 60px;
                        height: 30px;
                        border: 1px solid #06d6a0;
                        cursor: pointer;
                        border-radius: 10px;
                        p {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: #06d6a0;
                        }
                    }

                    .list_button:hover {
                        background: #06d6a0;
                        p {
                            color: #fff;
                        }
                    }

                    .list_button_delete {
                        width: 60px;
                        height: 30px;
                        border: 1px solid #db3a34;
                        cursor: pointer;
                        border-radius: 10px;
                        p {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: #db3a34;
                        }
                    }

                    .list_button_delete:hover {
                        background: #db3a34;
                        p {
                            color: #fff;
                        }
                    }
                }
            }
        }

    }
`;