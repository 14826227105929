import React from 'react';
import axios from 'axios';


import ReducerSetter from '../../reducers/ReducerSetter';


const WebManager = (oProps) => {
   const oReducerSetter = React.useRef();

   const _doAxios = async (sUrl, sMethod, sHeader, oData) => {
      let oUserConfig = oReducerSetter.current._refGetUserConfig();
      let oDefHeader = {
         'Content-Type': 'application/json'
      };

      if (sHeader !== undefined && sHeader !== null && typeof sHeader === 'string') {
         if (sHeader === 'login') {
            oDefHeader = {
               'Authorization': oUserConfig.Token,
               'Content-Type': 'application/json',
               'refresh-token': oUserConfig.RefreshToken
            };
         } else if (sHeader === 'multipart') {
            oDefHeader = {
               'Content-Type': 'multipart/form-data'
            };
         }
      }

      try {
         const oResponse = await axios({
            url: process.env.REACT_APP_INIT_URL + sUrl,
            method: sMethod,
            timeout: (15 * 1000),
            headers: oDefHeader,
            data: oData,
            transformResponse: [(data) => {
               return data;
            }],
         });
         if (oResponse !== undefined && oResponse.name !== undefined && oResponse.name.toLowerCase() === 'error') {
            if (oResponse.message !== undefined && oResponse.message.toLowerCase().indexOf('401') !== -1) {
               return "Unauthorized";
            }
         }

         if (oResponse.headers !== undefined && oResponse.headers['refreshed-token'] !== undefined) {
            oUserConfig['Token'] = oResponse.headers['refreshed-token'];
            oReducerSetter.current._refSetUserConfig(oUserConfig);
         }

         if (oResponse !== undefined && oResponse.data !== undefined) {
            return JSON.parse(oResponse.data);
         } else {
            return false;
         }
      
      } catch (err) {
         if(err.message.toLowerCase().indexOf('401') !== -1){
            return "Unauthorized";
         }
      }

   }

   const _setReduxUserConfig = (oUserConfigData) => {
      if (oReducerSetter.current !== undefined) {
         oReducerSetter.current._refSetUserConfig(oUserConfigData);
      }
      return false;
   }

   const _getReduxUserConfig = () => {
      if (oReducerSetter.current !== undefined) {
         return oReducerSetter.current._refGetUserConfig();
      }
      return false;
   }

   const _getReduxUserBasket = () => {
      if (oReducerSetter.current !== undefined) {
         return oReducerSetter.current._refGetUserBasket();
      }
      return false;
   }

   const _setReduxUserBasket = (oUserBasketData) => {
      oReducerSetter.current._refSetUserBasket(oUserBasketData);
   }

   const oThisManager = {
      fDoAxios: _doAxios,
      fSetReduxUserConfig: _setReduxUserConfig,
      fGetReduxUserConfig: _getReduxUserConfig,
      fGetReduxUserBasket: _getReduxUserBasket,
      fSetReduxUserBasket: _setReduxUserBasket,
   };

   return (
      <>
         {React.cloneElement(oProps.children, { oManager: oThisManager })}
         <ReducerSetter {...oProps} ref={oReducerSetter} />
      </>
   );
}

export default WebManager;