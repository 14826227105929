/*global kakao*/
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const KakaoRoute = ({ sUserLat, sUserLng, sPickUp }) => {
    const startLat = useRef(null);
    const startLng = useRef(null);
    const routeList = useRef(null);

    const mapScript = document.createElement("script");

    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_API_KEY}&autoload=false`;

    document.head.appendChild(mapScript);
    
    const calculate = async () => {
        let tempList = [];
        for await (const iterator of sPickUp) {
            let temp = new kakao.maps.LatLng(parseFloat(iterator.lat), parseFloat(iterator.lng));
            tempList.push(temp);
        }
        routeList.current = tempList;
    }

    const onLoadMap = async () => {
        const mapContainer = document.getElementById('map');
        let mapCenter;
        let options;

        if(sPickUp !== undefined && sPickUp !== null && sPickUp.length > 0){
            startLat.current = await sPickUp[0].lat;
            startLng.current = await sPickUp[0].lng;
            await calculate();

            mapCenter = await new kakao.maps.LatLng(parseFloat(startLat.current), parseFloat(startLng.current));
            options = {
                center: mapCenter,
                level: 5
            };

            const map = await new kakao.maps.Map(mapContainer, options);
            const startSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/red_b.png'; 
            const arriveSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/blue_b.png';
            const startSize = await new kakao.maps.Size(50, 45);
            const arriveSize = await new kakao.maps.Size(50, 45);
            const startOption = { 
                offset: await new kakao.maps.Point(15, 43)
            };
            const arriveOption = { 
                offset: await new kakao.maps.Point(15, 43)
            };
    
            const startImage = await new kakao.maps.MarkerImage(startSrc, startSize, startOption);
            const arriveImage = await new kakao.maps.MarkerImage(arriveSrc, arriveSize, arriveOption);
            const startPosition = await new kakao.maps.LatLng(parseFloat(startLat.current), parseFloat(startLng.current)); 
            const arrivePosition = await new kakao.maps.LatLng(sUserLat, sUserLng);    
            
            await new kakao.maps.Marker({
                map: map, 
                position: startPosition,
                draggable: false,
                image: startImage
            });
            await new kakao.maps.Marker({  
                map: map,
                position: arrivePosition,
                draggable: false,
                image: arriveImage
            });
    
            const linePath = routeList.current;
    
            const polyline = await new kakao.maps.Polyline({
                path: linePath, 
                strokeWeight: 5, 
                strokeColor: '#6490E8', 
                strokeOpacity: 0.9, 
                strokeStyle: 'solid' 
            });
    
            await polyline.setMap(map); 

        } else {
            mapCenter = await new kakao.maps.LatLng(parseFloat(sUserLat), parseFloat(sUserLng));
            options = {
                center: mapCenter,
                level: 5
            };
            await new kakao.maps.Map(mapContainer, options);
        }


    };

    mapScript.addEventListener("load", onLoadMap);

    return (
        <Style>
            <div className="map_wrap">
                <div id="map" style={{width:"100%",height:"100%"}}></div> 
            </div>
        </Style>
    )
}

export default KakaoRoute;

const Style = styled.div`
    .map_wrap {
        height:60vh;
    }
    .mapWrapper_box {
        width:100%;
        height:60vh;
        float:left;
    }
`;
