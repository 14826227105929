import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import styled from 'styled-components';
import ReactExport from "react-data-export";
import { StockOutlined, CloudDownloadOutlined, BarsOutlined, ContactsOutlined, ProfileOutlined, FolderViewOutlined, FundProjectionScreenOutlined, CameraOutlined, FileDoneOutlined, FileSearchOutlined, CalculatorOutlined } from '@ant-design/icons';
import {
    osName,
    osVersion,
    isMobile
} from "react-device-detect";
import { Table, Select, DatePicker, message, Divider, Drawer, Modal } from 'antd';
import moment from 'moment-timezone';
import Aos from 'aos';
import "aos/dist/aos.css";
import 'moment/locale/ko';
import "antd/dist/antd.css";

import { Categorylist, Optionlist } from '../../components/store/StoreInfo';

import DoubleLineChart from '../charts/DoubleLineChart';

import none_image from '../../assets/svg/none_image.svg';

import KakaoMap from '../modal/KakaoMap';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const { Option } = Select;
const { RangePicker } = DatePicker;
const defaultFormat = "YYYY/MM/DD";

const selectOption = [
    {key: "id", name: "ID" },
    {key: "key", name: "키" },
    {key: "phone", name: "전화번호" },
    {key: "email", name: "email" },
    {key: "date", name: "가입일시" },
    {key: "storeNm", name: "매장명" },
    {key: "businessNm", name: "사업자번호" },
    {key: "storeAddress", name: "매장주소" },
    {key: "route", name: "등록경로" },
    {key: "isStart", name: "영업시작" },
    {key: "productCount", name: "등록상품수" },
    {key: "easy", name: "여유시간" },
    {key: "normal", name: "보통시간" },
    {key: "busy", name: "바쁜시간" },
    {key: "storePic", name: "매장사진" },
    {key: "productPic", name: "등록된 매뉴사진수" },
    {key: "orderNm", name: "누적 주문수" },
    {key: "recent", name: "전월 매출" },
    {key: "thisMonth", name: "당월 매출" },
];

const StoreList = ({ sProps }) => {
    const [iLoad, setILoading] = useState(true);
    const [isLoading, setLoading] = useState(true);

    const [columnList, setColumnList] = useState(["storeNm","phone","email","date","id","route",'key']);

    const [fromDate, setFromDate] = useState(moment().add(-1,"months").format(defaultFormat));
    const [toDate, setToDate] = useState(moment().format(defaultFormat));

    const [isVisible, setVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const [storeData, setStoreData] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [dayOffList, setDayOffList] = useState([]);
    const [operationList, setOperationList] = useState([]);
    const [optionList, setOptionList] = useState([]);
    const [storeOrderList, setStoreOrderList] = useState({});
    const [pickUpZone, setPickUpZone] = useState({});
    const [pictureList, setPictureList] = useState({});

    const [sOrderList, setSOrderList] = useState({});
    const [iOrderList, setIOrderList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [excelList, setExcelList] = useState([]);
    const [sColumns,setColumns] = useState([
        {
            title: "매장명",
            key: "storeNm",
            dataIndex: "storeNm",
            fixed: 'left',
            width: 150,
        },
        {
            title: "전화번호",
            key: "phone",
            dataIndex: "phone",
            width: 150,
        },
        {
            title: "email",
            key: "email",
            dataIndex: "email",
            width: 200,
        },
        {
            title: "가입일시",
            key: "date",
            dataIndex: "date",
            width: 200,
        },
        {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: 200,
        },
        {
            title: "등록경로",
            key: "route",
            dataIndex: "route",
            width: 200,
        },
        {
            title: "자세히",
            key: "key",
            dataIndex: "key",
            fixed: 'right',
            width: 200,
            render: (text, record, index) => <UserStyle><div className="order_detail_box_button" onClick={() => getStoreData(text)}><p>자세히</p></div></UserStyle>
            
        },
        
    ])
    const [anglePoint, setAnglePoint] = useState(
        { 
            pan: 0,
            tilt: 0,
            zoom: 0
        }
    );


    const getStoreData = async (e) => {
        console.log("e",e);
        setILoading(true);
        const oData = {
            sParam: e
        } 
        const oResponse = await sProps.oManager.fDoAxios("/admin/store/storeInfo/v2", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            let temp = {
                pan: oResponse.pickUpZone.parking_pan,
                tilt: oResponse.pickUpZone.parking_tilt,
                zoom: oResponse.pickUpZone.parking_zoom
            }
            setCategoryList(oResponse.categoryList);
            setDayOffList(oResponse.dayOffList);
            setOperationList(oResponse.operationList);
            setOptionList(oResponse.optionList);
            setStoreOrderList(oResponse.orderList);
            setPickUpZone(oResponse.pickUpZone);
            setAnglePoint(temp);
            setPictureList(oResponse.pictureList);
            setStoreData(oResponse.store);
            setVisible(true);
            setILoading(false);
        } else {
            message.error("알수없는 에러입니다.");
        }
    }

    const handleChange = async (value) => {
        setColumnList(value);
    }

    const onOperatingDate = (value, dateString) => {
        if(dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== ""){
            if(fromDate === moment().format(defaultFormat)){
                setFromDate(dateString[0]);
            } else {
                setFromDate(dateString[0]);
                setToDate(dateString[1]);
            }
        } else {
            setFromDate(moment().format(defaultFormat));
            setToDate(moment().add(1, 'days').format(defaultFormat));
        }
    }

    const onSearch = async () => {
        setLoading(true);

        let iResult = [];
        for await(const x of columnList) {
            let temp = {};
            temp.title = "";
            temp.key = x;
            temp.dataIndex = x;
            temp.width = 150;
            if(x === "storeNm"){
                temp.fixed = "left";
            }
             
            for await (const y of selectOption) {
                if(x === y.key){
                    temp.title = y.name;
                }
            }
            iResult.push(temp);
        }
        setColumns(iResult);

        await asyncData(iResult);
    }

    const excelDownload = async () => {
        const oData = { 
            userId: sProps.UserConfigReducer.UserId,
            osVersion: osName + osVersion,
        };
        await sProps.oManager.fDoAxios("/admin/store/storeListExcelDownload", "post", null, oData);
        message.info("파일이 정상적으로 다운로드 되었습니다");
    }

    const asyncData = async (iResult) => {
        let oData = {
            userId: sProps.UserConfigReducer.UserId,
            fromDate,
            toDate,
            osVersion: osName + osVersion,
            isMobile,
            columnList: sColumns,
        }
        if(iResult !== undefined){
            oData = {
                userId: sProps.UserConfigReducer.UserId,
                fromDate,
                toDate,
                osVersion: osName + osVersion,
                isMobile,
                columnList: iResult,
            }
        } 
        const oResponse = await sProps.oManager.fDoAxios("/admin/store/storeData", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            const config1 = {
                data: oResponse.orderChartList,
                xField: 'date',
                yField: 'scales',
                seriesField: 'name',
                yAxis: {},
                smooth: true,
                animation: {
                    appear: {
                        animation: 'path-in',
                        duration: 5000,
                    },
                },
            };

            setIOrderList(config1);
            setLoading(false);
            setSOrderList(oResponse.orderData);
            setOrderList(oResponse.orderList);
            setExcelList(oResponse.excelData);
        }
    }
    useEffect(() => {
        asyncData();
    }, [])

    useEffect(() => {
        Aos.init({ duration: 1000 });
    })

    return (
        <>
        <Row>
            <Col>
                <div className="search_box">
                    <div className="select_date">
                        <RangePicker 
                            bordered={false} 
                            className="date_picker"
                            style={{marginLeft: "5%",fontSize: "0rem", width: "90%"}}
                            format={defaultFormat}
                            value={[moment(fromDate, defaultFormat),moment(toDate, defaultFormat)]} 
                            onChange={onOperatingDate}
                        />
                    </div>
                    <div className="click_box" onClick={onSearch}>
                        <p>조회하기</p>
                    </div>
                    {(excelList.length > 0 && !isMobile)?
                        <div className="excel_down" onClick={excelDownload}>
                            <ExcelFile filename={`스루-매장조회${fromDate + "~" + toDate}`} element={<CloudDownloadOutlined style={{fontSize: 25, color: "#13166b"}}/>}>
                                <ExcelSheet dataSet={excelList} name="요약"/>
                            </ExcelFile>
                        </div>
                        :
                        <div className="excel_down" onClick={() => message.info("데이터가 존재하지않습니다.")}>
                            <CloudDownloadOutlined style={{fontSize: 25, color: "#13166b"}}/>
                        </div>
                    }
                </div>
                <div className="select_box">
                    <Select
                        mode="tags"
                        bordered={false}
                        placeholder="Please select"
                        onChange={handleChange}
                        value={columnList}
                        style={{ width: '100%' }}
                    >
                        {selectOption.map((item,index) => {
                            return (
                                <Option key={item.index} value={item.key}>{item.name}</Option>
                            )
                        })}
                    </Select>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm={8}>
                <div className="chartLine">
                    {isLoading ?
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                        </div>
                    :
                        <div className="chartBox" data-aos="fade-up" data-aos-delay="300">
                            <div className="chartTitle">
                                <StockOutlined style={{fontSize: "30px"}}/> 
                                <p>등록 매장 수</p>
                            </div>
                            <DoubleLineChart vChart={iOrderList} />
                        </div>
                    }
                </div>
            </Col>
            <Col sm={4}>
                <div className="connectedList" >
                    <div className="user_box" data-aos="fade-up"> 
                        <div className="user_line_title">
                            <BarsOutlined style={{fontSize: "25px"}}/>
                            <p>선택기간동안 등록한 총 매장 수</p>
                        </div>
                        <div className="user_line_count">
                            <p>{sOrderList.totalCount} 개</p>
                        </div>
                    </div>
                    <div className="connected_car_box" data-aos="fade-up" data-aos-delay="200"> 
                        <div className="user_line_title">
                            <ContactsOutlined style={{fontSize: "25px"}}/>
                            <p>선택기간동안 등록한 매장의 등록 정보</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">운영시작</p>
                            <p className="number">{sOrderList.activeCount} 개</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">매장정보등록</p>
                            <p className="number">{sOrderList.storeCount} 개</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">사업자정보등록</p>
                            <p className="number">{sOrderList.businessCount} 개</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">상품등록</p>
                            <p className="number">{sOrderList.productCount} 개</p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="table_title">
                    <ProfileOutlined style={{fontSize: "30px"}}/> 
                    <p>조회결과</p>
                </div>
                <div className="columns_box">
                    <Table columns={sColumns} dataSource={orderList} pagination={true} scroll={{ x: 'calc(250px + 80%)', y: "90vh" }}/>
                    <Divider orientation="left" style={{color: "#d12f22"}}>
                    대외비 관리 주의사항
                    </Divider>
                    <p style={{color: "#d12f22"}}>
                        본자료에는 고객의 개인정보가 포함된 대외비입니다. 본 자료는 마케팅 참고 자료로만 활용이 가능합니다.<br />
                        본 자료를 회사의 동의 또는 허가없이 외부에 공유할 경우 민형사상 책임이 발생합니다<br />
                        다운로드된 자료는 사용후 저장 및 공유를 금지하며, 1회 목적으로 사용후 폐기하여야 합니다<br />
                        다운로드 이력은 추적관리 되며, 관리소홀로 인한 문제 발생시 회사는 최초 자료 조회자를 포함한 모든 관련자에게 민형사상 책임을 물을 수 있습니다
                    </p>
                </div>
            </Col>
        </Row>
        <Drawer
            width={840}
            placement="right"
            closable={false}
            onClose={() => setVisible(false)}
            visible={isVisible}
        >
            {iLoad ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                    <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                </div>
            :
                <StoreDataStyle>
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="store_info_container">
                                <div className="title_box" style={{width: "100%", height: 100, alignItems: "center", justifyContent: "center", borderBottom: "1px solid #dfdfdf"}} data-aos="fade-up"> 
                                    <div className="user_line_title" style={{display: "flex", alignItems: "center"}}>
                                        <BarsOutlined style={{fontSize: "25px"}}/>
                                        <p style={{marginTop: 15, marginLeft: 15, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>상호명</p>
                                    </div>
                                    <div className="user_line_count">
                                        <p style={{fontSize: "1.1rem", fontWeight: "bold", color: "#000"}}>{storeData.name}</p>
                                    </div>
                                </div>
                                <div className="store_info_box" style={{width: "100%", borderBottom: "1px solid #dfdfdf"}} data-aos="fade-up" data-aos-delay="200"> 
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>아이디:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.key}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>입점일:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeCreateAt}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장주소:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeAddress}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장전화번호:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storePhone}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>대표자명:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.ownerNm}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>대표자 전화번호:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.ownerPhone}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>사업자번호:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.businessNm}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>사업자소재지:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.businessAddress}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>이메일:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.email}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>입금은행:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.bankNm}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>계좌번호:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.bankAccount}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>주업종:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.mainType}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>부업종:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.s_coupon}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장소개글:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeDesc}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장공지사항:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeNotice}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>원산지표시사항:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeNotification}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>상품준비시간(여유):</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.easy}분</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>상품준비시간(보통):</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.normal}분</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>상품준비시간(바쁨):</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.busy}분</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>픽업존 근접 알림:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.distance}m</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>픽업존 정차가능 시간:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.parkingTime}분</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>임시휴무일:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.dayOff}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}> 
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장상태:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: storeData.status === "운영중" ? "#13166b" : "#d12d13", textAlign: "right"}} >{storeData.status}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}> 
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>영업처:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}} >{storeData.storeRoute}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <FundProjectionScreenOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>픽업존</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 10}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center"}}>
                                    <div className="title" style={{display: "flex", alignItems: "center",justifyContent: "center", background: "#f9f9f9", borderRadius: 15,margin: "2%", height: 90, width: 250}} onClick={() => setModalVisible(true)}>
                                        <FolderViewOutlined style={{fontSize: "30px"}}/> 
                                        <p style={{marginTop: 15, marginLeft: 10,fontSize: "0.9rem", fontWeight: 500}}>픽업존 확인하기</p>
                                    </div>
                                    <div className="title" style={{marginLeft: 50,display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pickUpZone.parkingImg !== undefined && pickUpZone.parkingImg !== null && pickUpZone.parkingImg !== "" ? pickUpZone.parkingImg : none_image} alt="none"/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <CameraOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>매장사진</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 10}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center",justifyContent: "space-between"}}>
                                    <div className="title" style={{display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pictureList.first !== undefined && pictureList.first !== null && pictureList.first !== "" ? pictureList.first : none_image} alt="none"/>
                                    </div>
                                    <div className="title" style={{display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pictureList.second !== undefined && pictureList.second !== null && pictureList.second !== "" ? pictureList.second : none_image} alt="none"/>
                                    </div>
                                    <div className="title" style={{display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pictureList.third !== undefined && pictureList.third !== null && pictureList.third !== "" ? pictureList.third : none_image} alt="none"/>
                                    </div>
                                    <div className="title" style={{display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pictureList.forth !== undefined && pictureList.forth !== null && pictureList.forth !== "" ? pictureList.forth : none_image} alt="none"/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <ProfileOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>영업시간</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 100}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40, background: "#f9f9f9"}}>
                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "60%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>영업시간</p>
                                    </div>
                                    <div className="count" style={{display: "flex", alignItems: "center", justifyContent: "flex-end", width: "20%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>요일</p>
                                    </div>
                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "20%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>상품준비시간</p>
                                    </div>
                                </div>
                                {operationList !== undefined && operationList.length > 0 &&
                                    <>
                                    {operationList.map((item,index) => {
                                        return (
                                            <div className="order_list_line" key={index}>
                                                <div className="order_list" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40}}>
                                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "60%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.time}</p>
                                                    </div>
                                                    <div className="count" style={{display: "flex", alignItems: "center", justifyContent: "flex-end", width: "20%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.day}</p>
                                                    </div>
                                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "20%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.minute}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <FileDoneOutlined  style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>정기 휴무일</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 100}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40, background: "#f9f9f9"}}>
                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "70%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>몇주차</p>
                                    </div>
                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "30%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>요일</p>
                                    </div>
                                </div>
                                {dayOffList !== undefined && dayOffList.length > 0 &&
                                    <>
                                    {dayOffList.map((item,index) => {
                                        return (
                                            <div className="order_list_line" key={index}>
                                                <div className="order_list" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40}}>
                                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "70%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.week}</p>
                                                    </div>
                                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "30%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.day}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <FileSearchOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>상품분류</p>
                            </div>
                            <div>
                                <Categorylist ilist={categoryList} oProps={sProps}/>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <CalculatorOutlined  style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>옵션</p>
                            </div>
                            <div>
                                <Optionlist ilist={optionList} />
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <FileSearchOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>주문내역</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 100}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40, background: "#f9f9f9"}}>
                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "20%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>전화번호</p>
                                    </div>
                                    <div className="count" style={{display: "flex", alignItems: "center", justifyContent: "center", width: "30%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>금액</p>
                                    </div>
                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "20%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>상태</p>
                                    </div>
                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "30%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>날짜</p>
                                    </div>
                                </div>
                                {storeOrderList !== undefined && storeOrderList.length > 0 &&
                                    <>
                                    {storeOrderList.map((item,index) => {
                                        return (
                                            <div className="order_list_line" key={index}>
                                                <div className="order_list" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40}}>
                                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "20%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.phone}</p>
                                                    </div>
                                                    <div className="count" style={{display: "flex", justifyContent: "center", alignItems: "center", width: "30%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.total}</p>
                                                    </div>
                                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "20%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.status}</p>
                                                    </div>
                                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "30%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.createAt}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                </StoreDataStyle>
            }
        </Drawer>
        <Modal 
            width={"60vw"} 
            visible={modalVisible} 
            centered={true} 
            closable={false} 
            onOk={() => setModalVisible(false)}
            onCancel={() => setModalVisible(false)}
            okText="확인" 
            cancelText="취소"
        >
            <KakaoMap 
                sUserLat={pickUpZone.lat}
                sUserLng={pickUpZone.lng}
                iViewPoint={anglePoint}
            />
        </Modal>
        </>
    )
}

export default StoreList;


export const StoreDataStyle = styled.div`

`;

export const UserStyle = styled.div`
    .order_detail_box_button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100px;
        border: 1px solid #13166b;
        border-radius: 10px;
        cursor: pointer;

        p {
            margin-top: 15px;
            font-size: 0.8rem;
            font-weight: bold;
            color: #13166b;
        }
    }

    .order_detail_box_button:hover {
        background: #13166b;
        p {
            color: #fff;
        }
    }
`;