import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useTrail, animated as a } from "react-spring";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DotChartOutlined, BarsOutlined, FileDoneOutlined, CameraOutlined, ProfileOutlined, FileSearchOutlined, FolderViewOutlined, FundProjectionScreenOutlined, CalculatorOutlined } from '@ant-design/icons';
import { Drawer, Divider, message, Modal } from 'antd';
import "antd/dist/antd.css";

import { Categorylist, Optionlist } from '../../components/store/StoreInfo';

import KakaoMap from '../modal/KakaoMap';
import PieChart from '../charts/PieChart';

import none_image from '../../assets/svg/none_image.svg';

const config = { mass: 5, tension: 1000, friction: 200 };

const UserPage = ({sProps}) => {
    const [sLoad, setLoading] = useState(false);
    const [iLoad, setILoading] = useState(true);
    const [chartLoad, setChartLoad] = useState(true);

    const [modalVisible, setModalVisible] = useState(false);
    const [isVisible, setVisible] = useState(false);
    
    const [orderList, setOrderList] = useState({});
    const [storeData, setStoreData] = useState({});
    const [pickUpZone, setPickUpZone] = useState({});
    const [storeChart, setStoreChart] = useState({});
    const [dayOffList, setDayOffList] = useState([]);
    const [operationList, setOperationList] = useState([]);
    const [optionList, setOptionList] = useState([]);
    const [pictureList, setPictureList] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [tempStoreList, setTempStoreList] = useState([]);
    const [sNm, setNm] = useState(10);

    const [sContext, setContext] = useState("");
    const [classNameUserPhone, setClassNameUserPhone] = useState(null);

    const [anglePoint, setAnglePoint] = useState(
        { 
            pan: 0,
            tilt: 0,
            zoom: 0
        }
    );

    const trail = useTrail(storeList.length, {
        config,
        opacity: 1,
        x: 0,
        height: 80,
        from: { opacity: 0, x: 20, height: 0 }
    });

    const inputChange = async (e) => {
        setContext(e.target.value);
        if(e.target.value === ""){
            setStoreList(tempStoreList);
        }
    }
    
    const onClickEnter = async (e) => {
        if(e.key === "Enter"){
            findStore();
        }
    }

    const onClickUser = () => {
        setClassNameUserPhone("inputClass");
    }

    const getStoreData = async (e) => {
        setILoading(true);
        const oData = {
            sParam: e
        } 
        const oResponse = await sProps.oManager.fDoAxios("/admin/store/storeInfo/v2", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            let temp = {
                pan: oResponse.pickUpZone.parking_pan,
                tilt: oResponse.pickUpZone.parking_tilt,
                zoom: oResponse.pickUpZone.parking_zoom
            }
            setCategoryList(oResponse.categoryList);
            setDayOffList(oResponse.dayOffList);
            setOperationList(oResponse.operationList);
            setOptionList(oResponse.optionList);
            setOrderList(oResponse.orderList);
            setPickUpZone(oResponse.pickUpZone);
            setAnglePoint(temp);
            setPictureList(oResponse.pictureList);
            setStoreData(oResponse.store);
            setVisible(true);
            setILoading(false);
        } else {
            message.error("알수없는 에러입니다.");
        }
    }

    const findStore = async () => {
        setLoading(true);
        if(sContext !== ""){
            const oData = {
                iParam: sContext,
                userId: sProps.UserConfigReducer.UserId,
            }
            const oResponse = await sProps.oManager.fDoAxios("/admin/store/searchStore", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
                console.log("oResponse",oResponse);
                setClassNameUserPhone(null);
                setStoreList(oResponse);
            }
        }
        setLoading(false);
    }

    const getStoreList = async (sIndex) => {
        let sParam = 10;
        if(sIndex !== undefined && sIndex !== null){
            sParam = sIndex;
        }
        const oData = {
            sParam,
            userId: sProps.UserConfigReducer.UserId,
        }
        const oResponse = await sProps.oManager.fDoAxios("/admin/store/storeList/v2", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            const config1 = {
                appendPadding: 10,
                data: oResponse.chart,
                angleField: 'value',
                colorField: 'type',
                radius: 0.75,
                label: {
                    type: 'spider',
                    labelHeight: 28,
                    content: '{name}',
                },
                interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            };
            setStoreChart(config1);
            setChartLoad(false);
            setStoreList(oResponse.list);
            setTempStoreList(oResponse.list);
        }
        setLoading(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getStoreList();
    }, []);

    return (
        <>
        {sLoad ?
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 300}}>
                <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
            </div>
            :
            <>
            <Row>
                <Col>
                    <div className="search_box">
                        <div className="search_name">
                            <input 
                                placeholder="매장명을 입력하세요"
                                className={classNameUserPhone}
                                value={sContext} 
                                onChange={inputChange} 
                                onClick={onClickUser}
                                onKeyPress={onClickEnter}
                            />
                        </div>
                        <div className="click_box" onClick={findStore}>
                            <p>조회하기</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {sLoad ?
                        <div className="search_result_box">
                            <div className="search_result_title">
                                <p>검색중입니다...</p>
                            </div>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 300}}>
                                <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                            </div>
                        </div>
                    :
                        <div className="result_line">
                            <div className="result_left_line">
                                {trail.map(({ x, height, ...rest }, index) => (
                                    <a.div
                                        key={index}
                                        className="search_result_content"
                                        style={{
                                            ...rest,
                                            transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                                        }}
                                    >
                                        <a.div className="type">
                                            {storeList[index].status === "매장등록중" &&<p style={{background: "rgb(0, 173, 171)", borderRadius: "15px"}}>매장등록중</p>}
                                            {storeList[index].status === "영업준비완료" &&<p style={{background: "#1a2fc6", borderRadius: "15px"}}>영업준비완료</p>}
                                        </a.div>
                                        <a.div className="title">
                                            <p>{storeList[index].name}</p>
                                        </a.div>
                                        <a.div className="action">
                                            <a.div className="action_button" onClick={() => getStoreData(storeList[index].id)}>
                                                <p>선택</p>
                                            </a.div>
                                        </a.div>
                                    </a.div>
                                ))}
                            </div>
                            <div className="result__right_line">
                                <div className="subChart">
                                    <div className="rectBox">
                                        <DotChartOutlined style={{fontSize: "30px", color: "#fff"}}/> 
                                    </div>
                                    <div className="textBox">
                                        <p>매장현황</p>
                                    </div>
                                </div>
                                {chartLoad ?
                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 300}}>
                                        <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                                    </div>
                                    :
                                    <PieChart sChart={storeChart}/>
                                }
                            </div>
                        </div>
                    }
                </Col>
            </Row>
            <Drawer
                width={840}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={isVisible}
            >
                {iLoad ?
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                        <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                    </div>
                :
                    <>
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="store_info_container">
                                <div className="title_box" style={{width: "100%", height: 100, alignItems: "center", justifyContent: "center", borderBottom: "1px solid #dfdfdf"}} data-aos="fade-up"> 
                                    <div className="user_line_title" style={{display: "flex", alignItems: "center"}}>
                                        <BarsOutlined style={{fontSize: "25px"}}/>
                                        <p style={{marginTop: 15, marginLeft: 15, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>상호명</p>
                                    </div>
                                    <div className="user_line_count">
                                        <p style={{fontSize: "1.1rem", fontWeight: "bold", color: "#000"}}>{storeData.name}</p>
                                    </div>
                                </div>
                                <div className="store_info_box" style={{width: "100%", borderBottom: "1px solid #dfdfdf"}} data-aos="fade-up" data-aos-delay="200"> 
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>아이디:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.key}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>입점일:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeCreateAt}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장주소:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeAddress}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장전화번호:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storePhone}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>대표자명:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.ownerNm}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>대표자 전화번호:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.ownerPhone}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>사업자번호:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.businessNm}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>사업자소재지:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.businessAddress}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>이메일:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.email}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>입금은행:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.bankNm}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>계좌번호:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.bankAccount}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>주업종:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.mainType}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>부업종:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.s_coupon}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장소개글:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeDesc}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장공지사항:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeNotice}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center"}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>원산지표시사항:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.storeNotification}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>상품준비시간(여유):</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.easy}분</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>상품준비시간(보통):</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.normal}분</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>상품준비시간(바쁨):</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.busy}분</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>픽업존 근접 알림:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.distance}m</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>픽업존 정차가능 시간:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.parkingTime}분</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>임시휴무일:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}}>{storeData.dayOff}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}> 
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장상태:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: storeData.status === "운영중" ? "#13166b" : "#d12d13", textAlign: "right"}} >{storeData.status}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}> 
                                        <p className="title" style={{width: "20%",fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>영업처:</p>
                                        <p className="number" style={{width: "80%",fontSize: "0.9rem", fontWeight: 600, color: "#000", textAlign: "right"}} >{storeData.storeRoute}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <FundProjectionScreenOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>픽업존</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 10}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center"}}>
                                    <div className="title" style={{display: "flex", alignItems: "center",justifyContent: "center", background: "#f9f9f9", borderRadius: 15,margin: "2%", height: 90, width: 250}} onClick={() => setModalVisible(true)}>
                                        <FolderViewOutlined style={{fontSize: "30px"}}/> 
                                        <p style={{marginTop: 15, marginLeft: 10,fontSize: "0.9rem", fontWeight: 500}}>픽업존 확인하기</p>
                                    </div>
                                    <div className="title" style={{marginLeft: 50,display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pickUpZone.parkingImg !== undefined && pickUpZone.parkingImg !== null && pickUpZone.parkingImg !== "" ? pickUpZone.parkingImg : none_image} alt="none"/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <CameraOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>매장사진</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 10}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center",justifyContent: "space-between"}}>
                                    <div className="title" style={{display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pictureList.first !== undefined && pictureList.first !== null && pictureList.first !== "" ? pictureList.first : none_image} alt="none"/>
                                    </div>
                                    <div className="title" style={{display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pictureList.second !== undefined && pictureList.second !== null && pictureList.second !== "" ? pictureList.second : none_image} alt="none"/>
                                    </div>
                                    <div className="title" style={{display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pictureList.third !== undefined && pictureList.third !== null && pictureList.third !== "" ? pictureList.third : none_image} alt="none"/>
                                    </div>
                                    <div className="title" style={{display: "flex", alignItems: "center", margin: "2%", height: 90, width: 90}}>
                                        <img style={{width: "100%", height: "100%"}} src={pictureList.forth !== undefined && pictureList.forth !== null && pictureList.forth !== "" ? pictureList.forth : none_image} alt="none"/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <ProfileOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>영업시간</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 100}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40, background: "#f9f9f9"}}>
                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "60%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>영업시간</p>
                                    </div>
                                    <div className="count" style={{display: "flex", alignItems: "center", justifyContent: "flex-end", width: "20%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>요일</p>
                                    </div>
                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "20%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>상품준비시간</p>
                                    </div>
                                </div>
                                {operationList !== undefined && operationList.length > 0 &&
                                    <>
                                    {operationList.map((item,index) => {
                                        return (
                                            <div className="order_list_line" key={index}>
                                                <div className="order_list" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40}}>
                                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "60%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.time}</p>
                                                    </div>
                                                    <div className="count" style={{display: "flex", alignItems: "center", justifyContent: "flex-end", width: "20%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.day}</p>
                                                    </div>
                                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "20%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.minute}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <FileDoneOutlined  style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>정기 휴무일</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 100}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40, background: "#f9f9f9"}}>
                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "70%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>몇주차</p>
                                    </div>
                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "30%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>요일</p>
                                    </div>
                                </div>
                                {dayOffList !== undefined && dayOffList.length > 0 &&
                                    <>
                                    {dayOffList.map((item,index) => {
                                        return (
                                            <div className="order_list_line" key={index}>
                                                <div className="order_list" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40}}>
                                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "70%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.week}</p>
                                                    </div>
                                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "30%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.day}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <FileSearchOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>상품분류</p>
                            </div>
                            <div>
                                <Categorylist ilist={categoryList} oProps={sProps}/>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <CalculatorOutlined  style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>옵션</p>
                            </div>
                            <div>
                                <Optionlist ilist={optionList} />
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <FileSearchOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>주문내역</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 100}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40, background: "#f9f9f9"}}>
                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "20%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>전화번호</p>
                                    </div>
                                    <div className="count" style={{display: "flex", alignItems: "center", justifyContent: "center", width: "30%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>금액</p>
                                    </div>
                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "20%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>상태</p>
                                    </div>
                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "30%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>날짜</p>
                                    </div>
                                </div>
                                {orderList !== undefined && orderList.length > 0 &&
                                    <>
                                    {orderList.map((item,index) => {
                                        return (
                                            <div className="order_list_line" key={index}>
                                                <div className="order_list" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40}}>
                                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "20%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.phone}</p>
                                                    </div>
                                                    <div className="count" style={{display: "flex", justifyContent: "center", alignItems: "center", width: "30%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.total}</p>
                                                    </div>
                                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "20%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.status}</p>
                                                    </div>
                                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "30%"}}>
                                                        <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.createAt}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                    </>
                }
            </Drawer>
            </>
        }
        <Modal 
            width={"60vw"} 
            visible={modalVisible} 
            centered={true} 
            closable={false} 
            onOk={() => setModalVisible(false)}
            onCancel={() => setModalVisible(false)}
            okText="확인" 
            cancelText="취소"
        >
            <KakaoMap 
                sUserLat={pickUpZone.lat}
                sUserLng={pickUpZone.lng}
                iViewPoint={anglePoint}
            />
        </Modal>
        </>
                    
    )
}

export default UserPage;
