export const Types = {
   SETUSERCONFIG: "user/SETUSERCONFIG"
};

// Initial State
const initialState = {
   LoggedIn: false,

   Roll: '',

   UserId : 0,
   Name: '',
   Team: '',
   Birthday: '',
   Email: '',
};

export function setUserConfig(oResult) {
   return {
      type: Types.SETUSERCONFIG,
      payload: {
         result: oResult
      }
   };
}

const UserConfigReducer = (state = initialState, action) => {
   switch (action.type) {
      case Types.SETUSERCONFIG: {
         let oMerged = { ...state, ...action.payload.result };
         return oMerged;
      }
      default: {
         return state;
      }
   }
};

// Exports
export default UserConfigReducer;