import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';

import { setUserConfig } from "../reducers/UserConfigReducer";

import Header from "../components/header/Header";

import LoginPage from "../screens/login/LoginPage";
import Dashboard from "../screens/dashboard/Dashboard";
import User from "../screens/user/User";
import Store from "../screens/store/Store";
import Adjustment from "../screens/adjustment/Adjustment";
import OrderList from "../screens/orderList/OrderList";
import TaxManage from "../screens/adjustment/TaxManage";
import SalesTeam from "../screens/sales/SalesTeam";
import WebBanner from "../screens/web/banner/WebBanner";
import WebNotice from "../screens/web/notice/WebNotice";
import AppInquiry from "../screens/app/inquiry/AppInquiry";
import AppNotice from "../screens/app/notice/AppNotice";
import WebInquiry from "../screens/web/inquiry/WebInquiry";
import Commercial from "../screens/commercial/Commercial";

const Routes = (oProps) => {
    const [state, setState] = useState("login");
    const [activeNoti, setActiveNoti] = useState(false);

    const changeState = (sIndex) => {
        setState(sIndex);
    }

    const managerCheck = async () => {
        if(oProps.UserConfigReducer.Roll === "master" || oProps.UserConfigReducer.Roll === "sales" || oProps.UserConfigReducer.Roll === "marketing"){
            const oData = { userId: oProps.UserConfigReducer.UserId };
            const oResponse = await oProps.oManager.fDoAxios("/admin/manage/homepage/managerCheck", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
                if(oResponse.resultCd === "0000"){
                    await infoMessage(oResponse.resultMsg);
                    setActiveNoti(true);
                } else {
                    setActiveNoti(false);
                }
            }
        }
    }

    const infoMessage = (sParam) => {
        message.info(sParam);
    };

    const signIn = async (bIndex) => {
        await oProps.reduxSetUserConfig(bIndex);
        setState("dashboard");
    }

    const signOut = async () => {
        let oUserConfig = {};

        oUserConfig['LoggedIn'] = false;
        oUserConfig['Roll'] = "";
        oUserConfig['Name'] = "";
        oUserConfig['Team'] = "";
        oUserConfig['Email'] = "";
        oUserConfig['Birthday'] = "";
        oUserConfig['UserId'] = "";

        await oProps.reduxSetUserConfig(oUserConfig);
        setState("login");
    }

    useEffect(() => {
        if(oProps.UserConfigReducer.LoggedIn){
            setState("dashboard");
        }
    },[]);

    useEffect(() => {
        managerCheck();
    },[state]);
    
    return (
        <div>
            {oProps.UserConfigReducer.LoggedIn && 
                <Header 
                    sActiveNoti={activeNoti}
                    userInfo={oProps.UserConfigReducer}
                    fnChangeState={(bIndex) => changeState(bIndex)}
                    fnSignOut={() => signOut()}
                />
            }
            {state === "login" && 
                <LoginPage 
                    sManager={oProps.oManager}
                    fnLogin={(bIndex) => signIn(bIndex)}
                />
            }
            {state === "dashboard" && <Dashboard sProps={oProps} fnChangeState={(bIndex) => changeState(bIndex)}/>}
            {state === "web_banner" && <WebBanner {...oProps} />}
            {state === "web_notice" && <WebNotice {...oProps} />}
            {state === "app_notice" && <AppNotice {...oProps} />}
            {state === "app_inquiry" && <AppInquiry {...oProps} />}
            {state === "commercial" && <Commercial {...oProps} />}
            {state === "salesTeam" && <SalesTeam {...oProps} />}
            {state === "user" && <User {...oProps} />}
            {state === "store" && <Store {...oProps} />}
            {state === "calculate" && <Adjustment {...oProps} />}
            {state === "order" && <OrderList {...oProps} />}
            {state === "faxfee" && <TaxManage {...oProps} />}
            {state === "web_inquiry" && <WebInquiry {...oProps} />}
            
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        UserConfigReducer: state.UserConfigReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxSetUserConfig: (oData) => dispatch(setUserConfig(oData))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
