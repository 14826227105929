/*global kakao*/
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const KakaoMap = ({ sUserLat, sUserLng, iViewPoint }) => {
    const anglePoint = useRef(null);

    const mapScript = document.createElement("script");

    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_API_KEY}&autoload=false`;

    document.head.appendChild(mapScript);
    
    const onLoadMap = async () => {
        function MapWalker(position){

            let content = document.createElement('div');
            let figure = document.createElement('div');
            let angleBack = document.createElement('div');

            content.className = 'MapWalker';
            figure.className = 'figure';
            angleBack.className = 'angleBack';

            content.appendChild(angleBack);
            content.appendChild(figure);

            let walker = new kakao.maps.CustomOverlay({
                position: position,
                content: content,
                yAnchor: 1
            });

            this.walker = walker;
            this.content = content;
        }

        function toggleRoadview(position){
            roadViewClient.getNearestPanoId(position, 50, function(panoId) {
                if (panoId === null) {
                    roadViewContainer.style.display = 'none';
                    mapWrapper.style.width = '100%';
                    map.relayout();
                    
                } else {
                    mapWrapper.style.width = '50%';
                    map.relayout();
                    roadViewContainer.style.display = 'block';
                    roadView.setPanoId(panoId, position);
                    roadView.setViewpoint({
                        pan: anglePoint.current.pan,
                        tilt: anglePoint.current.tilt,
                        zoom: anglePoint.current.zoom,
                    });
                    roadView.relayout(); 
                }
            });
        }

        MapWalker.prototype.setAngle = function(angle){

            const threshold = 22.5; 
            for(let i=0; i<16; i++){ 
                if(angle > (threshold * i) && angle < (threshold * (i + 1))){

                    const className = 'm' + i;
                    this.content.className = this.content.className.split(' ')[0];
                    this.content.className += (' ' + className);
                    break;
                }
            }
        };

        MapWalker.prototype.setPosition = function(position){
            this.walker.setPosition(position);
        };

        MapWalker.prototype.setMap = function(map){
            this.walker.setMap(map);
        };

        const mapWrapper = document.getElementById('mapWrapper');
        const container = await document.getElementById("kakaomap");
        const mapCenter = await new kakao.maps.LatLng(sUserLat, sUserLng);
        const options = {
            center: mapCenter,
            level: 3
        };

        const map = await new window.kakao.maps.Map(container, options);
        await map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);

        const roadViewContainer = document.getElementById('roadview');
        const roadView = new kakao.maps.Roadview(roadViewContainer); 
        const roadViewClient = new kakao.maps.RoadviewClient(); 

        toggleRoadview(mapCenter);

        const markImage = new kakao.maps.MarkerImage(
            'https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png',
            new kakao.maps.Size(26, 46),
            {
                spriteSize: new kakao.maps.Size(1666, 168),
                spriteOrigin: new kakao.maps.Point(705, 114),
                offset: new kakao.maps.Point(13, 46)
            }
        );
        const rvMarker = new kakao.maps.Marker({
            image : markImage,
            position: mapCenter,
            draggable: true,
            map: map
        });
        
        kakao.maps.event.addListener(rvMarker, 'dragend', function(mouseEvent) {
            var position = rvMarker.getPosition();
            toggleRoadview(position);
        });
        
        kakao.maps.event.addListener(map, 'click', function(mouseEvent){
            var position = mouseEvent.latLng; 
        
            rvMarker.setPosition(position);
            toggleRoadview(position);
        });

        let mapWalker = null;

        kakao.maps.event.addListener(roadView, 'init', async () => {
            mapWalker = new MapWalker(mapCenter);
            mapWalker.setMap(map); 
            roadView.setViewpoint({
                pan: parseFloat(anglePoint.current.pan),
                tilt: parseFloat(anglePoint.current.tilt),
                zoom: parseInt(anglePoint.current.zoom),
            });
            
            kakao.maps.event.addListener(roadView, 'viewpoint_changed', async () => {

                let viewpoint = roadView.getViewpoint();
                mapWalker.setAngle(viewpoint);
            });

            kakao.maps.event.addListener(roadView, 'position_changed', async () => {

                let position = roadView.getPosition();
                mapWalker.setPosition(position);
                map.setCenter(position);

            });
        });
    };

    mapScript.addEventListener("load", onLoadMap);

    useEffect(() => {
        console.log("sUserLat",sUserLat);
        console.log("sUserLng",sUserLng);
        anglePoint.current = iViewPoint;
    }, [iViewPoint]);

    return (
        <Style>
            <div className="map_wrap">
                <div className="mapWrapper_box" id="mapWrapper" >
                    <div id="kakaomap" style={{width:"100%",height:"100%"}}></div> 
                </div>
                <div className="mapWrapper_box" id="rvWrapper" >
                    <div id="roadview" style={{width:"100%",height:"100%"}}></div>
                </div>
            </div>
        </Style>
    )
}

export default KakaoMap;

const Style = styled.div`
    .map_wrap {
        border-radius: 5px;
        overflow:hidden;
        height:60vh;
    }
    .mapWrapper_box {
        width:50%;
        height:60vh;
        float:left;
    }
`;
