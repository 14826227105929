import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import { Table } from 'antd';
import "antd/dist/antd.css";

import { UnorderedListOutlined, CheckSquareOutlined } from '@ant-design/icons';

const WebInquiry = (oProps) => {
    const [sLoading, setLoading] = useState(true);
    
    const [sType, setType] = useState("banner");
    
    const [finList, setFinList] = useState([]);
    const [ingList, setIngList] = useState([]);

    const [iPagination, setIPagination] = useState({
        current: 1,
        pageSize: 15,
    });
    const [fPagination, setFPagination] = useState({
        current: 1,
        pageSize: 15,
    });
   
    const onChangeType = (sIndex) => {
        setType(sIndex);
        asyncFetch(sIndex);
    }
   
    const handleFinList = (pagination) => {
        setFPagination(pagination);
    }

    const handleIngList = (pagination) => {
        setIPagination(pagination);
    }

    const asyncFetch = async (sIndex) => {
        let temp = sType;

        if(sIndex !== undefined && sIndex !== null && sIndex !== ""){
            temp = sIndex;
        }
        const oData = {
            userId: oProps.UserConfigReducer.UserId,
            type: temp
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/manage/commercialList", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            setFinList(oResponse.endList);
            setIngList(oResponse.listing);
        }
        setLoading(false);
    };

    useEffect(() => {
        asyncFetch();
    }, []);

    return (
        <UserStyle>
            {sLoading ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                    <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                </div> 
                :
                <div className="container">
                    <Row>
                        <Col>
                            <div className="header">
                                <div className="header_content">
                                    <p className={sType === "banner" ? "active" : "un_active"} onClick={() => onChangeType("banner")}>메인 배너광고</p>
                                    <p className={sType === "store" ? "active" : "un_active"} onClick={() => onChangeType("store")} style={{marginLeft:"3vh"}}>신규 입점 광고</p>
                                    <p className={sType === "coupon" ? "active" : "un_active"} onClick={() => onChangeType("coupon")} style={{marginLeft:"3vh"}}>내 주변 쿠폰 광고</p>
                                    <p className={sType === "product" ? "active" : "un_active"} onClick={() => onChangeType("product")} style={{marginLeft:"3vh"}}>핫메뉴 광고</p>
                                    <p className={sType === "throoonly" ? "active" : "un_active"} onClick={() => onChangeType("throoonly")} style={{marginLeft:"3vh"}}>스루 온리 광고</p>
                                    <p className={sType === "kit" ? "active" : "un_active"} onClick={() => onChangeType("kit")} style={{marginLeft:"3vh"}}>스루키트</p>
                                    <p className={sType === "placard" ? "active" : "un_active"} onClick={() => onChangeType("placard")} style={{marginLeft:"3vh"}}>야외 광고 배너</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 70}}>
                        <Col sm={12}>
                            <div className="title_line">
                                <UnorderedListOutlined style={{fontSize: "1rem"}}/>
                                <p>진행중인 광고 목록</p>
                            </div>
                            <div className="content_line" style={{ height: (ingList !== undefined && ingList !== null && ingList.length > 0) ? "50vh" : "15vh" }}>
                                <Table 
                                    columns={columns} 
                                    pagination={iPagination} 
                                    dataSource={ingList} 
                                    onChange={handleIngList} 
                                    scroll={{ x: 'calc(700px + 50%)', y: (ingList !== undefined && ingList !== null && ingList.length > 0) ? "50vh" : "5vh" }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: (finList !== undefined && finList !== null && finList.length > 0) ? "20vh" : "5vh"}}>
                        <Col sm={12}>
                            <div className="title_line_2">
                                <CheckSquareOutlined style={{fontSize: "1rem", color: "#666" }}/>
                                <p>종료된 광고 목록</p>
                            </div>
                            <div className="content_line" style={{ height: (finList !== undefined && finList !== null && finList.length > 0) ? "50vh" : "15vh" }}>
                                <Table 
                                    columns={columns} 
                                    pagination={fPagination} 
                                    dataSource={finList} 
                                    onChange={handleFinList} 
                                    scroll={{ x: 'calc(700px + 50%)', y: (finList !== undefined && finList !== null && finList.length > 0) ? "50vh" : "5vh" }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </UserStyle>
    )
}

export default WebInquiry;

export const UserStyle = styled.div`
        
    .header {
        margin-top: 50px;
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 2px solid #bbb;
        align-items: center;

        .header_content {
            width: 90%;
            display: flex;
            align-items: center;
            margin-top: 20px;

            .active {
                color: #000;
                font-size: 1rem;
                font-weight: 700;
                cursor: pointer;
            }
            .un_active {
                color: #BBBBBB;
                font-size: 1rem;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }

    .title_line {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 3vh;
        padding-left: 20px;
        border-radius: 10px;
        width: 100%;

        p {
            margin-left: 10px;
            margin-top: 15px;
            font-size: 0.9rem;
            font-weight: 800;
        }
    }
        
    .title_line_2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 3vh;
        padding-left: 20px;
        border-radius: 10px;
        width: 100%;

        p {
            margin-left: 10px;
            margin-top: 15px;
            font-size: 0.9rem;
            font-weight: 800;
            color: #666;
        }
    }

    .content_line {
        margin-top: 5vh;
        height: 50vh;

        .detail_box {
            display: flex;
            justify-content: center;
            padding-top: 5px;
            height: 3vh;
            width: 3vw;
            border-radius: 5px;
            background : #617BE3;
            color: #fff;
            font-size: 0.9rem;
            cursor: pointer;
        }
    }
`;

const columns = [
    {
        title: '매장명',
        dataIndex: 'storeName',
        key: 'storeName',
        fixed: 'left',
    },
    {
        title: '광고명',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: '광고금액',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: '광고클릭수',
        dataIndex: 'click',
        key: 'click',
    },
    {
        title: '광고노출수',
        dataIndex: 'leak',
        key: 'leak',
    },
    {
        title: '시작 날짜',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    {
        title: '종료된 날짜',
        dataIndex: 'end_at',
        key: 'end_at',
    }
];
