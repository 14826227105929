/*global kakao*/
import React, { useState, useEffect } from "react";
import ReactExport from "react-data-export";
import Loader from "react-loader-spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Table, DatePicker } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import { AdjustmentStyle } from '../../assets/styleComponents/AdjustmentStyle';

const defaultFormat = "YYYY-MM";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const sColumns = [
    {
        title: '매장명',
        dataIndex: 'storeNm',
        key: 'storeNm',
        fixed: 'left',
        width: 70,
    },
    {
        title: '주문금액',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        width: 70,
    },
    {
        title: '할인지원금-스루(A)',
        children: [
            {
                title: '적립금',
                dataIndex: 'point',
                key: 'point',
                width: 50,
            },
            {
                title: '쿠폰',
                dataIndex: 'coupon',
                key: 'coupon',
                width: 50,
            },
        ],
    },
    {
        title: '혜택지원금-파트너(B)',
        children: [
            {
                title: '적립금',
                dataIndex: 'pointP',
                key: 'pointP',
                width: 50,
            },
            {
                title: '쿠폰',
                dataIndex: 'couponP',
                key: 'couponP',
                width: 50,
            },
            {
                title: '스탬프',
                dataIndex: 'stemp',
                key: 'stemp',
                width: 50,
            },
        ]
    },
    {
        title: '카드결제(C)',
        dataIndex: 'payment',
        key: 'payment',
        width: 70,
    },
    {
        title: '결제수수료(D)',
        dataIndex: 'fee',
        key: 'fee',
        width: 70,
    },
    {
        title: '입금받을금액',
        dataIndex: 'return',
        key: 'return',
        width: 70,
    },
    {
        title: '은행',
        dataIndex: 'bankNm',
        key: 'bankNm',
        width: 70,
    },
    {
        title: '계좌번호',
        dataIndex: 'account_nm',
        key: 'account_nm',
        width: 70,
    },
    {
        title: '예금주',
        dataIndex: 'bankUser',
        key: 'bankUser',
        width: 70,
    },
    {
        title: '대표자명',
        dataIndex: 'owner',
        key: 'owner',
        width: 70,
    },
    {
        title: '사업자번호',
        dataIndex: 'business_number',
        key: 'business_number',
        width: 70,
    },
];

const AdjustmentPage = (oProps) => {
    const [dateValue, setDateValue] = useState(moment().format(defaultFormat));

    const [isLoading, setLoading] = useState(true);
    const [sToday, setToday] = useState(null);
    const [orderList, setOrderList] = useState([]);
    const [excelList, setExcelList] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    }

    const onOperatingDate = async (date, dateString) => {
        let temp = "";
        if(date != null){
            temp = dateString;
        } else {
            temp = moment().format(defaultFormat);
        }
        setLoading(true);
        setDateValue(temp);
        asyncAxios(temp);
    }
    
    const asyncAxios = async (sIndex) => {
        let sDate = moment().format(defaultFormat);
        if(sIndex !== undefined && sIndex !== null){
            sDate = sIndex;
        }

        const oData = {
            userId: oProps.UserConfigReducer.UserId,
            sDate
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/getTaxData", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            setOrderList(oResponse.list);
            setToday(oResponse.lineDate);
            setExcelList(oResponse.excelData);
        }
        setLoading(false);
    }

    useEffect(() => {
        asyncAxios();
    }, [])
    
    return (
        <AdjustmentStyle>
            {isLoading ?
                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                    <Loader type="BallTriangle" color="red" height={80} width={80} timeout={9000000}/> 
                </div>
            :
            <Container>
                <Row>
                    <Col sm={6}>
                        <div className="taxContainer">
                            <div className="center_item">
                                <div class="tax_title_box">
                                    <span>매출내역서
                                        <p>기간에 따라 각 매장별 매출액을 확인가능합니다</p>
                                    </span>
                                </div>
                            </div>
                            <div className="date_box">
                                <DatePicker 
                                    picker="month" 
                                    bordered={false} 
                                    style={{marginLeft: "7%", fontSize: "0.7rem"}}
                                    value={moment(dateValue, defaultFormat)} 
                                    onChange={onOperatingDate}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="left_item" style={{marginTop: "10%"}}>
                            <div class="tax_excel_text">
                                <span>엑셀파일 다운로드<p>설정된 기간의 정보를 파일로 저장가능합니다</p></span>
                            </div>
                            <div class="tax_excel_text">
                                <ExcelFile filename={`스루 매출내역-${sToday}`} element={<button className="tax_excel_button">다운로드</button>}>
                                    <ExcelSheet dataSet={excelList} name="요약"/>
                                </ExcelFile>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="content_container">
                            <Table bordered={false} pagination={pagination} columns={sColumns} onChange={handleTableChange} dataSource={orderList} scroll={{ x: 'calc(1500px + 80%)', y: "90vh" }} />
                        </div>
                    </Col>
                </Row>
            </Container>
            }
        </AdjustmentStyle>
    )
}

export default AdjustmentPage;
