import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useTrail, animated as a } from "react-spring";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserOutlined, RocketOutlined, MessageOutlined, DollarOutlined, ProfileOutlined, FileSearchOutlined, FundOutlined, BoldOutlined } from '@ant-design/icons';
import { Drawer, Divider } from 'antd';
import "antd/dist/antd.css";

import { Orderlist, InquiryList, CarList, CardList } from '../../components/user/UserInfo';
import ColumnChart from '../charts/ColumnChart';
import PieChart from '../charts/PieChart';

const config = { mass: 5, tension: 1000, friction: 200 };

const UserPage = ({sProps}) => {
    const [sLoad, setLoading] = useState(true);
    const [oLoad, setOLoad] = useState(true);
    const [chartLoad, setChartLoad] = useState(true);

    const [isVisible, setVisible] = useState(false);

    const [sType, setType] = useState("list");

    const [userStatusChart, setUserStatusChart] = useState({});
    const [iUserData, setUserData] = useState({});
    const [iUserList, setUserList] = useState([]);
    const [iUserCart, setUserChart] = useState([]);
    const [sNm, setNm] = useState(10);

    const [cardKey, setCardKey] = useState(true);
    const [carKey, setCarKey] = useState(true);
    const [inquireKey, setInquireKey] = useState(true);
    const [orderKey, setOrderKey] = useState(true);

    const [sContext, setContext] = useState("");
    const [classNameUserPhone, setClassNameUserPhone] = useState(null);

    const trail = useTrail(iUserList.length, {
        config,
        opacity: 1,
        x: 0,
        height: 80,
        from: { opacity: 0, x: 20, height: 0 }
    });

    const inputChange = async (e) => {
        setContext(e.target.value);
        if(e.target.value === ""){
            getMore();
        }
    }
    
    const findUser = async () => {
        setLoading(true);
        if(sContext !== ""){
            const oData = {
                sParam: sContext,
                userId: sProps.UserConfigReducer.UserId,
            }
            const oResponse = await sProps.oManager.fDoAxios("/admin/user/searchUser", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
                setClassNameUserPhone(null);
                setUserList(oResponse);
                setType("list");
            }
        }
        setLoading(false);
    }

    const getMore = async () => {
        setLoading(true);
        setChartLoad(true);
        setUserStatusChart({});
        setUserList([]);
        setNm(sNm + 10);
        getUserList(sNm + 10);
    }

    const getUserList = async (sIndex) => {
        let sParam = 10;
        if(sIndex !== undefined && sIndex !== null){
            sParam = sIndex;
        }
        const oData = {
            sParam,
            userId: sProps.UserConfigReducer.UserId,
        }
        const oResponse = await sProps.oManager.fDoAxios("/admin/user/userList", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            const config1 = {
                appendPadding: 10,
                data: oResponse.chart,
                angleField: 'value',
                colorField: 'type',
                radius: 0.75,
                label: {
                    type: 'spider',
                    labelHeight: 28,
                    content: '{name}',
                },
                interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            };
            setUserStatusChart(config1);
            setUserList(oResponse.list);
            setType("list");
            setChartLoad(false);
        }
        setLoading(false);
    }

    const onClickUser = () => {
        setClassNameUserPhone("inputClass");
    }

    const onChangeItem = async (e) => {
        if (e === "card") {
            setCardKey(!cardKey);
        } else if (e === "car") {
            setCarKey(!carKey);
        } else if (e === "order") {
            setOrderKey(!orderKey);
        } else {
            setInquireKey(!inquireKey);
        }
    }

    const getUserData = async (e) => {
        const oData = {
            sParam: e,
            userId: sProps.UserConfigReducer.UserId,
        }
        const oResponse = await sProps.oManager.fDoAxios("/admin/user/getUserData", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            const config1 = {
                data: oResponse.userChart,
                xField: 'type',
                yField: 'sales',
                label: {
                    position: 'middle',
                    style: {
                        fill: '#FFFFFF',
                        opacity: 0.6,
                    },
                },
                meta: {
                    type: { alias: '일' },
                    sales: { alias: '건수' },
                },
            };
            setUserChart(config1);
            setOLoad(false);
            setVisible(true);
            setUserData(oResponse);
            setType("info");
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getUserList();
    }, []);

    return (
        <>
        {sLoad ?
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 300}}>
                <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
            </div>
            :
            <>
            <Row>
                <Col>
                    <div className="search_box">
                        <div className="search_name">
                            <input 
                                placeholder="전화번호를 입력하세요"
                                className={classNameUserPhone}
                                value={sContext} 
                                onChange={inputChange} 
                                onClick={onClickUser}
                                onPressEnter={findUser} 
                            />
                        </div>
                        <div className="click_box" onClick={findUser}>
                            <p>조회하기</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="search_result_box">
                        <div className="result_line">
                            <div className="result_left_line">
                                {trail.map(({ x, height, ...rest }, index) => (
                                    <a.div
                                        key={index}
                                        className="search_result_content"
                                        style={{
                                            ...rest,
                                            transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                                        }}
                                    >
                                        <a.div className="type">
                                            {iUserList[index].status === "new" &&<p style={{background: "rgb(0, 173, 171)", borderRadius: "15px"}}>신규</p>}
                                            {iUserList[index].status === "quit" &&<p style={{background: "#dd1212", borderRadius: "15px"}}>탈퇴</p>}
                                            {iUserList[index].status === "hold" &&<p style={{background: "#1a2fc6", borderRadius: "15px"}}>회원</p>}
                                        </a.div>
                                        <a.div className="title">
                                            <p>{iUserList[index].email}</p>
                                        </a.div>
                                        <a.div className="phone">
                                            <p>{iUserList[index].phone}</p>
                                        </a.div>
                                        <a.div className="action">
                                            <a.div className="action_button" onClick={() => getUserData(iUserList[index].id)}>
                                                <p>선택</p>
                                            </a.div>
                                        </a.div>
                                    </a.div>
                                ))}
                            </div>
                            <div className="result__right_line">
                                <div className="subChart">
                                    <div className="rectBox">
                                        <FundOutlined style={{fontSize: "30px"}}/> 
                                    </div>
                                    <div className="textBox">
                                        <p>회원현황</p>
                                    </div>
                                </div>
                                {chartLoad ?
                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 300}}>
                                        <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                                    </div>
                                :
                                    <PieChart sChart={userStatusChart}/>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={isVisible}
            >
                {(!oLoad && iUserData.userData !== undefined) &&
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div className="member_box" style={{marginBottom: 50, height: 500}}>
                            <div className="member_title" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 100}}>
                                <div className="member_title_img" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 100,width: 100, borderRadius: 15, background: "#f9f9f9"}}>
                                    <UserOutlined style={{fontSize: 50, color: "#13166b"}}/>
                                </div>
                            </div>
                            <div className="member_email" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 50}}>
                                <p style={{marginTop: 5, fontSize: "0.9rem", fontWeight: 700}}>{iUserData.userData.email !== undefined ? iUserData.userData.email : "에러"}</p>
                            </div>
                            <div className="member_line_box" style={{display: "flex", alignItems: "center", height: 70}}>
                                <div className="member_line" style={{background: "#13166b", margin: "5%", paddingLeft: 20, width: "50%", height: 70, borderRadius: 15}}>
                                    <p style={{color: "#fff", marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>전화번호</p>
                                    <p style={{color: "#fff", marginTop: 10, fontSize: "0.9rem", fontWeight: 600}} className="strong_color">{iUserData.userData.phone !== undefined ? iUserData.userData.phone : "알수없음"}</p>
                                </div>
                                <div className="member_line" style={{margin: "5%", paddingLeft: 20, width: "50%", height: 70, borderRadius: 15, background: "#f9f9f9"}}>
                                    <p style={{color: "#000", marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>이름</p>
                                    <p style={{color: "#000", marginTop: 10, fontSize: "0.9rem", fontWeight: 600}} className="strong_color">{iUserData.userData.userName !== undefined ? iUserData.userData.userName : "알수없음"}</p>
                                </div>
                            </div>
                            <div className="member_detail_box" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 50, margin: 30, background: "rgba(97, 123, 227, 0.1)", borderRadius: 10}}>
                                <div className="member_detail_line" style={{display: "flex", alignItems: "center", width: "40%", marginLeft: "15%"}}>
                                    <RocketOutlined style={{fontSize: 30, color: "rgb(0, 173, 171)"}}/>
                                    <p style={{marginLeft: 10, marginTop: 15, fontSize: "0.8rem", color: "#bbbbbb"}}>커넥티드카:</p>
                                </div>
                                <div className="member_detail_line2" style={{display: "flex", alignItems: "center", justifyContent:"center", width: "60%"}}>
                                    <p style={{marginTop: 15, fontSize: "0.9rem", fontWeight: 500}}>{(iUserData.userData.blueLink !== undefined && iUserData.userData.blueLink !== null) ? iUserData.userData.blueLink : "미가입"}</p>
                                </div>
                            </div>
                            <div className="member_detail_box" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 50, margin: 30, background: "rgba(97, 123, 227, 0.1)", borderRadius: 10}}>
                                <div className="member_detail_line" style={{display: "flex", alignItems: "center", width: "40%", marginLeft: "15%"}}>
                                    <MessageOutlined style={{fontSize: 30, color: "rgb(0, 173, 171)"}}/>
                                    <p style={{marginLeft: 10, marginTop: 15, fontSize: "0.8rem", color: "#bbbbbb"}}>유입경로:</p>
                                </div>
                                <div className="member_detail_line2" style={{display: "flex", alignItems: "center", justifyContent:"center", width: "60%"}}>
                                    <p style={{marginTop: 15, fontSize: "0.9rem", fontWeight: 500}}>{iUserData.userData.userType !== undefined ? iUserData.userData.userType : "알수없음"}</p>
                                </div>
                            </div>
                            <div className="member_detail_box" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 50, margin: 30, background: "rgba(97, 123, 227, 0.1)", borderRadius: 10}}>
                                <div className="member_detail_line" style={{display: "flex", alignItems: "center", width: "40%", marginLeft: "15%"}}>
                                    <DollarOutlined style={{fontSize: 30, color: "rgb(0, 173, 171)"}}/>
                                    <p style={{marginLeft: 10, marginTop: 15, fontSize: "0.8rem", color: "#bbbbbb"}}>포인트:</p>
                                </div>
                                <div className="member_detail_line2" style={{display: "flex", alignItems: "center", justifyContent:"center", width: "60%"}}>
                                    <p style={{marginTop: 15, fontSize: "0.9rem", fontWeight: 500}}>{iUserData.userData.userPoint !== undefined ? iUserData.userData.userPoint : "0"}point</p>
                                </div>
                            </div>
                            <div className="member_detail_box" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 50, margin: 30, background: "rgba(97, 123, 227, 0.1)", borderRadius: 10}}>
                                <div className="member_detail_line" style={{display: "flex", alignItems: "center", width: "40%", marginLeft: "15%"}}>
                                    <ProfileOutlined style={{fontSize: 30, color: "rgb(0, 173, 171)"}}/>
                                    <p style={{marginLeft: 10, marginTop: 15, fontSize: "0.8rem", color: "#bbbbbb"}}>총 주문 건:</p>
                                </div>
                                <div className="member_detail_line2" style={{display: "flex", alignItems: "center", justifyContent:"center", width: "60%"}}>
                                    <p style={{marginTop: 15, fontSize: "0.9rem", fontWeight: 500}}>{iUserData.orderNm !== undefined ? iUserData.orderNm : "0"}건</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                }
                <Divider />

                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        {oLoad ?
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                                <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                            </div>
                        :
                            <>
                            <div className="member_chart_title" style={{marginTop: 30, marginBottom: 20, marginLeft: 50, marginRight: 50, display: "flex"}}>
                                <FileSearchOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>월별 주문 건수</p>
                            </div>
                            <div className="member_chart" style={{margin: 50, height: 500}}>
                                <ColumnChart aChart={iUserCart}/>
                            </div>
                            </>
                        }
                    </Col>
                </Row>

                <Divider />
                
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div style={{height: orderKey ? 500 : 100}}>
                            <div className="order_list_title" style={{marginTop: 10, paddingTop:15, height: 50, display: "flex", alignItems: "center", background: "#ececec"}} onClick={() => onChangeItem("order")}>
                                <p style={{marginLeft: "5%", fontSize: "0.9rem", fontWeight: 500}}>주문내역</p>
                            </div>
                            {orderKey && <Orderlist iOrderlist={iUserData.orderList} />}
                        </div>
                        <div style={{height: inquireKey ? 500 : 100}}>
                            <div className="order_list_title" style={{marginTop: 10, paddingTop:15, height: 50, display: "flex", alignItems: "center", background: "#ececec"}} onClick={() => onChangeItem("inquire")}>
                                <p style={{marginLeft: "5%", fontSize: "0.9rem", fontWeight: 500}}>문의내역</p>
                            </div>
                            {inquireKey && <InquiryList iInquiryList={iUserData.inquiryList} />}
                        </div>
                        <div style={{height: cardKey ? 500 : 100}}> 
                            <div className="order_list_title" style={{marginTop: 10, paddingTop:15, height: 50, display: "flex", alignItems: "center", background: "#ececec"}} onClick={() => onChangeItem("card")}>
                                <p style={{marginLeft: "5%", fontSize: "0.9rem", fontWeight: 500}}>카드등록</p>
                            </div>
                            {cardKey && <CardList iCardList={iUserData.cardList} />}
                        </div>
                        <div style={{height: carKey ? 500 : 100}}>
                            <div className="order_list_title" style={{marginTop: 10, paddingTop:15, height: 50, display: "flex", alignItems: "center", background: "#ececec"}} onClick={() => onChangeItem("car")}>
                                <p style={{marginLeft: "5%", fontSize: "0.9rem", fontWeight: 500}}>차량등록</p>
                            </div>
                            {carKey && <CarList iCarList={iUserData.carList}/>}
                        </div>
                    </Col>
                </Row>
            </Drawer>
            </>
        }
        </>
                    
    )
}

export default UserPage;
