import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import { Drawer, message } from 'antd';
import "antd/dist/antd.css";

import { UnorderedListOutlined, LineHeightOutlined } from '@ant-design/icons';

const SalesTeam = (oProps) => {
    const [sLoading, setLoading] = useState(true);
    
    const [iVisible, setIVisible] = useState(false);
    const [sVisible, setSVisible] = useState(false);

    const [sList, setSList] = useState([]);
    const [iList, setIList] = useState([]);
    const [nList, setNList] = useState({});

    const [sAnswer, setAnswer] = useState("");

    const openData = (sIndex) => {
        setNList(sIndex);
        setIVisible(true);
    }

    const onChangeType = (event) => {
        const value = event.target.value;
        setAnswer(value);
    }

    const responseData = (sIndex) => {
        setNList(sIndex);
        setSVisible(true);
    }

    const responseInquiry = async (e) => {
        if(sAnswer !== ""){
            setLoading(true);
            const oData = {
                userId: oProps.UserConfigReducer.UserId,
                sContent: sAnswer,
                sKey: e,
            }
            const oResponse = await oProps.oManager.fDoAxios("/admin/manage/app/responseInquiry", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
                if(oResponse){
                    setAnswer("");
                    setNList({});
                    asyncFetch();
                } else {
                    message.error("에러");
                }
            }
        }
    }

    const asyncFetch = async () => {
        const oData = {
            userId: oProps.UserConfigReducer.UserId,
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/manage/app/inquiryList", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            setSList(oResponse.endList);
            setIList(oResponse.listing);
        }
        setSVisible(false)
        setLoading(false);
    };

    useEffect(() => {
        asyncFetch();
    }, []);

    return (
        <UserStyle>
            {sLoading ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                    <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                </div> 
                :
                <div className="container">
                    <Row style={{marginTop: 70}}>
                        <Col sm={5} style={{height: 500}}>
                            <div className="team_row">
                                <div className="title_line">
                                    <LineHeightOutlined style={{fontSize: "1.5rem"}}/>
                                    <p>완료된 1:1문의 목록</p>
                                </div>
                                <div className="content_line">
                                    {sList.map((item,index) => (
                                        <div className="list_box" key={index}>
                                            <div className="list_title">
                                                <div className="list_title_name">
                                                    <p>{item.title}</p>
                                                </div>
                                            </div>
                                            <div className="list_content">
                                                <div className="list_title_name">
                                                    <p>{item.full_name}</p>
                                                </div>
                                            </div>
                                            <div className="list_button_line">
                                                <div onClick={() => openData(item)} className="list_button">
                                                    <p>자세히</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col sm={7} style={{height: 500}}>
                            <div className="list_row">
                                <div className="title_line">
                                    <UnorderedListOutlined style={{fontSize: "1.5rem"}}/>
                                    <p>새로운 1:1문의 목록</p>
                                </div>
                                {iList.length > 0 ?
                                    <div className="content_line">
                                        {iList.map((item,index) => (
                                            <div className="list_box" key={index}>
                                                <div className="list_title">
                                                    <div className="list_title_name">
                                                        <p>{item.title}</p>
                                                    </div>
                                                    <div className="list_title_company">
                                                        <p>{item.date}</p>
                                                    </div>
                                                </div>
                                                <div className="list_content">
                                                    <div className="list_title_name">
                                                        <p>{item.email}</p>
                                                    </div>
                                                    <div className="list_title_company">
                                                        <p>{item.phone_number}</p>
                                                    </div>
                                                </div>
                                                <div className="list_button_line">
                                                    <div onClick={() => responseData(item)} className="list_button">
                                                        <p>자세히</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div className="none_line">
                                        <p>새로운 1:1 문의 내역이 없습니다.</p>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            <Drawer
                title={<DrawerPTag>1:1 문의사항</DrawerPTag>}
                width={440}
                placement="right"
                closable={false}
                onClose={() => setIVisible(false)}
                visible={iVisible}
            >
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>제목</p>
                            <input 
                                type="text"
                                value={nList.title}
                                disabled 
                                style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        {(nList.img_url !== undefined && nList.img_url !== null && nList.img_url !== "") &&
                            <div style={{height: 450}}>
                                <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>이미지</p>
                                <img style={{width: "100%", height: 400}} src={nList.img_url} alt="none"/>
                            </div>
                        }
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>전화번호</p>
                            <input 
                                type="text"
                                value={nList.phone_number}
                                disabled 
                                style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>아이디</p>
                            <input 
                                type="text"
                                value={nList.email}
                                disabled 
                                style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        <div style={{height: 250, marginBottom: "30px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>내용</p>
                            <textarea 
                                type="text"
                                value={nList.content}
                                disabled 
                                style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px", height: 200}}
                            />
                        </div>
                        <div style={{height: 250, marginBottom: "30px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>답변</p>
                            <textarea 
                                type="text"
                                value={nList.answer}
                                disabled
                                style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px", height: 200}}
                            />
                        </div>
                    </Col>
                </Row>
            </Drawer>
            <Drawer
                title={<DrawerPTag>1:1 문의사항</DrawerPTag>}
                width={440}
                placement="right"
                closable={false}
                onClose={() => setSVisible(false)}
                visible={sVisible}
            >
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>제목</p>
                            <input 
                                type="text"
                                value={nList.title}
                                disabled 
                                style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        {(nList.img_url !== undefined && nList.img_url !== null && nList.img_url !== "") &&
                            <div style={{height: 450}}>
                                <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>이미지</p>
                                <img style={{width: "100%", height: 400}} src={nList.img_url} alt="none"/>
                            </div>
                        }
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>전화번호</p>
                            <input 
                                type="text"
                                value={nList.phone_number}
                                disabled 
                                style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>아이디</p>
                            <input 
                                type="text"
                                value={nList.email}
                                disabled 
                                style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        <div style={{height: 250, marginBottom: "30px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>내용</p>
                            <textarea 
                                type="text"
                                value={nList.content}
                                disabled 
                                style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px", height: 200}}
                            />
                        </div>
                        <div style={{height: 250, marginBottom: "30px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>답변</p>
                            <textarea 
                                type="text"
                                value={sAnswer}
                                onChange={onChangeType}
                                style={{width: "100%", border: "2px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px", height: 200}}
                            />
                        </div>
                        <DrawerButton onClick={() => responseInquiry(nList.key)}>
                            <p>답변완료</p>
                        </DrawerButton>
                    </Col>
                </Row>
            </Drawer>
        </UserStyle>
    )
}

export default SalesTeam;

const DrawerPTag = styled.p`
    border-radius: 30px;
    background: #fff;
    white-space: nowrap;
    padding: 0px 5px;
    font-size: 0.9rem;
    outline: none;
    border: none;
    text-decoration: none;
    font-weight: bold;

`;

const DrawerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 10px;
    padding-top: 15px;
    background: #3d5a80;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        font-weight: 500;
        color: #fff;
    }

`;

export const UserStyle = styled.div`
    .container {
        width: 100%;
    }

    .insert_view {
        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
        }
    }

    .list_row {
        border-radius: 10px;
        border: 1px solid #98c1d9;
        width: 100%;
        height: 450px;

        .title_line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 50px;
            margin-left: 20px;

            p {
                margin-left: 10px;
                margin-top: 15px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }
        .none_line {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 360px;

            p {
                font-size: 0.8rem;
                font-weight: 600;
                color: #BBBBBB;
            }
        }

        .content_line {
            position: "relative";
            overflow-y: scroll;
            overflow-x: hidden;
            margin : 20px;
            height: 360px;

            .list_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px; 
                height: 50px;
                border-bottom: 1px solid #293241;

                .list_title {
                    width: 50%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        height: 25px;
                        p {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                    
                    .list_title_company {
                        display: flex;
                        height: 25px;

                        p {
                            font-size: 0.7rem;
                            font-weight: 500;
                            color: #bbbbbb;
                        }
                    }
                }

                .list_content {
                    width: 30%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        height: 25px;
                        p {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                    .list_title_company {
                        display: flex;
                        height: 25px;

                        p {
                            font-size: 0.7rem;
                            font-weight: 500;
                            color: #bbbbbb;
                        }
                    }
                }
                
                .list_button_line {
                    width: 20%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .list_button {
                        width: 60px;
                        height: 30px;
                        border: 1px solid #06d6a0;
                        cursor: pointer;
                        border-radius: 10px;
                        p {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: #06d6a0;
                        }
                    }

                    .list_button:hover {
                        background: #06d6a0;
                        p {
                            color: #fff;
                        }
                    }

                    .list_button_delete {
                        width: 60px;
                        height: 30px;
                        border: 1px solid #db3a34;
                        cursor: pointer;
                        border-radius: 10px;
                        p {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: #db3a34;
                        }
                    }

                    .list_button_delete:hover {
                        background: #db3a34;
                        p {
                            color: #fff;
                        }
                    }
                }
            }
        }

    }

    .team_row {
        border-radius: 10px;
        border: 1px solid #293241;
        width: 100%;
        height: 450px;

        .title_line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 50px;
            margin-left: 20px;

            p {
                margin-left: 10px;
                margin-top: 15px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .content_line {
            position: "relative";
            overflow-y: scroll;
            overflow-x: hidden;
            margin : 20px;
            height: 360px;

            .list_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px; 
                height: 50px;
                border-bottom: 1px solid #293241;

                .list_title {
                    width: 75%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        
                        p {
                            font-size: 0.8rem;
                            font-weight: 500;
                        }
                    }
                }

                .list_content {
                    width: 30%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        p {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                }

                .list_button_line {
                    width: 20%;
                    height: 50px;
                    display: flex;
                    justify-content: center;

                    .list_button {
                        width: 60px;
                        height: 30px;
                        border: 1px solid #003459;
                        cursor: pointer;
                        border-radius: 10px;
                        p {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: #003459;
                        }
                    }

                    .list_button:hover {
                        background: #003459;
                        p {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
`;