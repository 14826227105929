import styled from 'styled-components';

export const AdjustmentStyle = styled.div`
    .left_container{ 
        margin-top: 50px;
        display: flex;
        height: 150px;
        background: #f9fafb;
        border-radius: 10px;
    }

    .taxContainer{ 
        margin-top: 50px;
        display: flex;
        height: 150px;
        border: 1px solid #000000;
        border-radius: 10px;
    }

    .right_container{ 
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
    }
    
    .left_item {
        height: 150px;
        width: 100%;
        display: column;
        padding: 5%;
        background: #eef6f3;
        border-radius: 10px;
    }
    
    .right_item {
        margin-left: 5%;
        height: 150px;
        width: 100%;
        display: column;
        padding: 5%;
        background: #e5f2ff;
        border-radius: 10px;
    }

    .tax_title {
        padding: 5%;
        height: 7.5vh;

        p {
            font-size: 1rem;
            font-weight: 500;
        }
    }

    .tax_data_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 7.5vh;
    }

    .date_box {
        padding: 8%;
        width: 70%;
        height: 80px;
        border-bottom: 1px solid #dfdfdf;
    }
    .excel_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;

    }

    .tax_excel_button {
        background: #fff;
        width: 100px;
        outline: none;
        border: none;
        border-radius: 10px;
        height: 40px;
        font-size: 0.8rem;
        font-weight: 500;
        color: #4593fc;
    }

    .tax_excel_button:hover {
        background: #4593fc;
        color: #fff;
    }

    .center_item {
        height: 150px;
        width: 100%;
        display: flex;
        padding: 5%;
    }

    .inline_text {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-size: 0.8rem;
            font-weight: 500;
        }
        span {
            font-size: 1rem;
            font-weight: 700;
            
        }
    }

    .tax_excel_text {
        display: flex;
        margin-left: 30px;
        p {
            font-size: 0.8rem;
            font-weight: 500;
        }
        span {
            font-size: 1rem;
            font-weight: 700;
            
        }
    }

    .right_inline_text {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-size: 0.8rem;
            font-weight: 500;
        }
        span {
            font-size: 1rem;
            font-weight: 700;
        }
    }

    .inline_title {
        display: flex;
        align-items: center;
        margin-left: 5%;
        
        .p_date {
            font-size: 0.7rem;
            font-weight: 500;
            color: #194aa6;
        }
        p {
            font-size: 0.8rem;
            font-weight: 500;
        }
        span {
            font-size: 1.2rem;
            font-weight: 800;
            
        }
    }

    .tax_title_box {
        display: flex;
        align-items: center;
        
        .p_date {
            font-size: 0.7rem;
            font-weight: 500;
            color: #194aa6;
        }
        p {
            font-size: 0.8rem;
            font-weight: 500;
        }
        span {
            font-size: 1.2rem;
            font-weight: 800;
            
        }
    }

    .inline_button {
        background: #fff;
        margin-left: 20px;
        width: 80px;
        height: 40px;
        outline: none;
        border: none;
        border-radius: 10px;

        font-size: 0.8rem;
        font-weight: 500;
        color: #4593fc;
    }

    .inline_button:hover {
        background: #e5e8eb;
    }

    .center_button {
        width: 100%;
        height: 10vh;
        margin-left: 50%;
        background: #fff;
        outline: none;
        border: none;
        border-radius: 10px;

        font-size: 0.8rem;
        font-weight: 600;
        color: #4593fc;
    }

    .center_button:hover {
        background: #e5e8eb;
    }
    
    .inline2_button {
        background: #fff;
        outline: none;
        border: none;
        margin-left: 30px;
        width: 80px;
        height: 40px;
        border-radius: 10px;

        font-size: 0.8rem;
        font-weight: 500;
    }

    .inline2_button:hover {
        color: #fff;
        background: #4593fc;
    }
    

    .content_container {
        margin-top: 50px;
        margin-bottom: 150px;
        display: flex;
    }
    
    .item_container {
        padding-right: 2%;
        margin: 2%;
        width: 100%;
        background: #fff;
        border-radius: 15px;
    }
`
