import React from 'react';
import { BarChartOutlined, DatabaseOutlined, DotChartOutlined, OrderedListOutlined, StockOutlined, FundOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";

import ChartTest from '../charts/Chart';
import ColumnChart from '../charts/ColumnChart';
import PieChart from '../charts/PieChart';
import BarChart from '../charts/BarChart';
import DoubleLineChart from '../charts/DoubleLineChart';
import DoubleBarChart from '../charts/DoubleBarChart';

export const Store = ({ storeChart }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rect2Box">
                    <FundOutlined style={{fontSize: "30px"}}/> 
                </div>
                <div className="textBox">
                    <p>월별 가맹매장수</p>
                </div>
            </div>
            <div className="boxWrapper">
                <DoubleLineChart vChart={storeChart}/>
            </div>
        </div>
    )
}

export const Order = ({ orderChart }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rect2Box">
                    <FundOutlined style={{fontSize: "30px"}}/> 
                </div>
                <div className="textBox">
                    <p>월별 주문건수</p>
                </div>
            </div>
            <div className="boxWrapper">
                <DoubleLineChart vChart={orderChart}/>
            </div>
        </div>
    )
}

export const WeeklyOrderList = ({ orderChart }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rect2Box">
                    <BarChartOutlined style={{fontSize: "30px"}}/> 
                </div>
                <div className="textBox">
                    <p>일주일간 주문건수</p>
                </div>
            </div>
            <div className="chartBoxWrapper">
                <ColumnChart aChart={orderChart}/>
            </div>
        </div>
    )
}

export const User = ({ userChart }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rect2Box">
                    <StockOutlined style={{fontSize: "30px"}}/> 
                </div>
                <div className="textBox">
                    <p>회원증가추세</p>
                </div>
            </div>
            <div className="boxWrapper">
                <DoubleLineChart vChart={userChart}/>
            </div>
        </div>
    )
}

export const WebsiteUserChart = ({ webTypeChart }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rect2Box">
                    <BarChartOutlined style={{fontSize: "30px"}}/> 
                </div>
                <div className="textBox">
                    <p>홈페이지 경로</p>
                </div>
            </div>
            <div className="website_path_chartBox">
                <DoubleBarChart aChart={webTypeChart}/>
            </div>
        </div>
    )
}

export const WebsiteChart = ({ webUserChart }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rect2Box">
                    <StockOutlined style={{fontSize: "30px"}}/> 
                </div>
                <div className="textBox">
                    <p>홈페이지 방문수</p>
                </div>
            </div>
            <div className="website_chartBox">
                <ChartTest kChart={webUserChart}/>
            </div>
        </div>
    )
}

export const WebsiteCEOPathChart = ({ webTypeChart }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rect2Box">
                    <BarChartOutlined style={{fontSize: "30px"}}/> 
                </div>
                <div className="textBox">
                    <p>사장님 창구 경로</p>
                </div>
            </div>
            <div className="website_path_chartBox">
                <DoubleBarChart aChart={webTypeChart}/>
            </div>
        </div>
    )
}

export const WebsiteCEOChart = ({ webUserChart }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rect2Box">
                    <StockOutlined style={{fontSize: "30px"}}/> 
                </div>
                <div className="textBox">
                    <p>사장님 창구 방문수</p>
                </div>
            </div>
            <div className="website_chartBox">
                <ChartTest kChart={webUserChart}/>
            </div>
        </div>
    )
}

export const OrderList = ({ orderList, fnGoToOrder }) => {
    const moveOrderList = async () => {
        if(fnGoToOrder !== undefined && typeof fnGoToOrder === "function"){
            await fnGoToOrder();
        }
    }

    return (
        <div>
            <div className="subChart">
                <div className="rect3Box">
                    <OrderedListOutlined style={{fontSize: "20px", color: "#fff"}}/> 
                </div>
                <div className="textBox">
                    <p style={{color: "#fff"}}>주문 내역</p>
                </div>
            </div>
            <div className="orderList">
                <div className="boxWrapper2">
                    {orderList.map(item => {
                        return (
                            <div className="orderWrapper" onClick={moveOrderList}>
                                {item.status ?
                                    <p className="orderComplete">주문완료</p>
                                    :
                                    <p className="orderCancel">주문취소</p>
                                }
                                <div className="orderDetail">
                                    <p>{item.name}</p>
                                    <p>{item.amount}원</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export const UserLocation = ({ userPieList }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rectBox">
                    <DotChartOutlined style={{fontSize: "30px", color: "#fff"}}/> 
                </div>
                <div className="textBox">
                    <p>회원 유입경로</p>
                </div>
            </div>
            <div className="chartBoxWrapper">
                <PieChart sChart={userPieList}/>
            </div>
        </div>
    )
}

export const UserType = ({ userList }) => {
    return (
        <div>
            <div className="subChart">
                <div className="rect2Box">
                    <DatabaseOutlined style={{fontSize: "25px"}}/> 
                </div>
                <div className="textBox">
                    <p>유입별 회원수</p>
                </div>
            </div>
            <div className="boxWrapper">
                <div className="userDevice">
                    <div className="sourceTitle">
                        <p>ANDROID</p>
                    </div>
                    <div className="sourceDetail">
                        <p>{userList.androidUser}명</p>
                        <p>{userList.androidUserPercent}%</p>
                    </div>
                    <div className="sourceTitle">
                        <p>IOS</p>
                    </div>
                    <div className="sourceDetail">
                        <p>{userList.appleUser}명</p>
                        <p>{userList.appleUserPercent}%</p>
                    </div>
                </div>
            </div>
            <div className="boxWrapper">
                <div className="usersource">
                    <div className="sourceTitle">
                        <p>카카오</p>
                    </div>
                    <div className="sourceDetail">
                        <p>{userList.kakaoId}명</p>
                        <p>{userList.kakaoPercent}%</p>
                    </div>
                    <div className="sourceTitle">
                        <p>Apple ID</p>
                    </div>
                    <div className="sourceDetail">
                        <p>{userList.appleId}명</p>
                        <p>{userList.applePercent}%</p>
                    </div>
                    <div className="sourceTitle">
                        <p>일반 회원 가입</p>
                    </div>
                    <div className="sourceDetail">
                        <p>{userList.normal}명</p>
                        <p>{userList.normalPercent}%</p>
                    </div>
                </div>
            </div>
            <div className="boxWrapper">
                <div className="userTotal">
                    <div className="sourceTitle">
                        <p>총합</p>
                    </div>
                    <div className="sourceDetail">
                        <p>{userList.total}명</p>
                    </div>
                </div>
            </div>
        </div>
    )
}