import styled from 'styled-components';

export const LoginStyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }
    body {
        width: 100%;
        height: calc(100vh);
    }
    .container {
        width: 30vw;
        margin: 0 auto;
    }
    input:required {
        border-color: #800000;
        border-width: 3px;
    }

    input:required:invalid {
    border-color: #c00000;
    }

    /* LoginStyle */
    width: 100%;
    height: 100vh;

    /* sign */
    .sign {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        margin: 0 auto;
    }
    .sign .logo {
        width: 200px;
        height: 200px;
        transform: translate(100px, 0);
    }

    /* left */
    .left {
        flex: 0 0 40%;
        background-color:#13166b;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }  
    .title h2{ 
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 2.5rem;
        color: #fff;
        font-weight: 800;
        margin-bottom: 3vh;
        margin-right: 3vw;
    }
    .title p{ 
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 1.5rem;
        color: #fff;
        font-weight: 300;
    }

    /* right */
    .right {
        flex: 0 0 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-weight: 400;
    }
    .signUp {
        height: 55vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .signUp h2 {
        font-size: 45px;
        font-weight: 400;
        color: #444;
        white-space: nowrap;
    }
    .signUp form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 30vh;
    }
    .signUp form .inputBox {
        width: 30vw;
        height: 8vh;
        background-color: #efefef;
        border:none;
        padding: 0 40px;
        font-size: 1rem;
        font-family: 'Noto Sans KR', sans-serif;
        font-weight: 600;
    }
    .signUp form input {
        background-color: #efefef;
        font-size: 1.5rem;
        font-family: 'Noto Sans KR', sans-serif;
        font-weight: 600;
    }
    .signUp form p {
        font-size: 0.9rem;
        font-family: 'Noto Sans KR', sans-serif;
        font-weight: 500;
        color: #5854A8;
    }
    .signUp form button {
        width: 15vw;
        height: 6vh;
        border-radius: 5px;
        background-color: #13166b;
        border: none;
        font-size: 20px;
        font-family: 'Noto Sans KR', sans-serif;
        font-weight: 600;
    }
    .signUp form button:hover {
        background-color: #617be3;
    }

    @media screen and (max-width: 1600px) {
        .container {
            width: 27vw;
            margin: 0 auto;
        }

        .title h2{ 
            margin-right: 0vw;
        }

        .signUp h2 {
            font-size: 33px;
        }

        .left {
            flex: 0 0 45%;
        }
        .right {
            flex: 0 0 55%;
        }
        
        .signUp {
            height: 60vh;
        }
        .signUp form button {
            height: 8vh;
        }
        .signUp form {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 40vh;
        }
    }
    @media screen and (max-width: 1300px) {
        .title h2{ 
            font-size: 43px;
        }
        .title p{ 
            font-size: 20px;
        }
        .sign .logo {
            width: 180px;
            height: 180px;
            transform: translate(85px, 0);
        }
    }
    @media screen and (max-width: 1200px) {
        .title h2{ 
            font-size: 40px;
        }
        .title p{ 
            font-size: 18px;
        }
        .sign .logo {
            width: 150px;
            height: 150px;
            transform: translate(75px, 0);
        }
        .signUp {
            height: 52vh;
        }
        .signUp h2 {
            font-size: 28px;
        }
        .signUp form button {
            margin-top: 2vh;
        }
        .signUp form .inputBox {
            font-size: 15px;
        }
        .signUp form input {
            font-size: 15px;
        }
        .signUp form button {
            width: 30vw;
            height: 7vh;
        }
    }
    @media screen and (max-width: 1100px) {
        .container {
            width: 25vw;
            margin: 0 auto;
        }
    }
    @media screen and (max-width: 1000px) {
        .container {
            width: 45vw;
            margin: 0 auto;
        }

        .sign {
            flex-direction: column;
            
        }

        /* left */
        .left {
            flex: 0 0 35%;
            flex-direction: column;
        }
        .sign .logo {
            width: 130px;
            height: 130px;
            transform: translate(0px, 70px);
        }
        .title h2{ 
            font-size: 40px;
            color: #fff;
            font-weight: 800;
            margin-bottom: 1vh;
        }
        .title h2 br {
            display: none;
        }
        .title p br {
            display: none;
        }
        

        /* right */
        .right {
            flex: 0 0 65%;
        }
        .signUp form .inputBox {
            width: 50vw;
        }
        .signUp form button {
            width: 50vw;
        }

        

        
    }
    @media screen and (max-width: 770px) {
        height: calc(100vh - 100px);
        .container {
            width: 60vw;
            margin: 0 auto;
        }
        .title h2{ 
            font-size: 35px;
        }
        .left {
            flex: 0 0 30%;
        }
        .sign {
            height: calc(100vh - 100px);
        }
        
        .right {
            flex: 0 0 70%;
        }
        .signUp {
            height: 42vh;
        }
        .signUp h2 {
            margin-bottom: 3vh;
        }
        .sign .logo {
            width: 100px;
            height: 100px;
            transform: translate(0px, 50px);
        }
        .signUp form .inputBox {
            width: 60vw;
        }
        .signUp form button {
            width: 60vw;
        }
    }
    @media screen and (max-width: 500px) {
        .title h2{ 
            font-size: 25px;
        }
        .title p{ 
            font-size: 11px;
        }
        .sign .logo {
            width: 70px;
            height: 70px;
            transform: translate(0px, 35px);
        }
        .signUp h2 {
            font-size: 20px;
            margin-top: 5vh;
        }
        .signUp form .inputBox {
            width: 65vw;
        }
        .signUp form button {
            width: 65vw;
            font-size: 15px;
        }
        .signUp form p {
            font-size: 11px;
        }
    }
    @media screen and (max-width: 400px) {
        .container {
            width: 70vw;
            margin: 0 auto;
        }
        .signUp form .inputBox {
            width: 70vw;
        }
        .signUp {
            height: 47vh;
        }
    }
`;
