import React, { useState } from "react";
import { Table } from 'antd';
import 'antd/dist/antd.css';

export const Orderlist = ({iOrderlist}) => {
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
    });

    const columns = [
        {
            title: '차량번호',
            dataIndex: 'carNm',
            key: 'carNm',
        },
        {
            title: '주문매장',
            dataIndex: 'storeName',
            key: 'storeName',
        },
        {
            title: '결제금액',
            dataIndex: 'payment',
            key: 'payment',
        },
        {
            title: '합계',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: '상태',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '날짜',
            dataIndex: 'date',
            key: 'date',
        }
    ];

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    }
    return (
        <div className="order_list">
            <Table pagination={pagination} columns={columns} dataSource={iOrderlist} onChange={handleTableChange}/>
        </div>
    );
}

export const InquiryList = ({iInquiryList}) => {
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
    });

    const columns = [
        {
            title: '제목',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '문의날짜',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '답변날짜',
            dataIndex: 'answerDate',
            key: 'answerDate',
        },
        {
            title: '작성자',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: '상태',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    }
    return (
        <div className="order_list">
            <Table pagination={pagination} columns={columns} dataSource={iInquiryList} onChange={handleTableChange}/>
        </div>
    );
}

export const CardList = ({iCardList}) => {
    const columns = [
        {
            title: '카드회사',
            dataIndex: 'cardCompany',
            key: 'cardCompany',
        },
        {
            title: '카드별명',
            dataIndex: 'cardNm',
            key: 'cardNm',
        },
        {
            title: '등록날짜',
            dataIndex: 'date',
            key: 'date',
        }
    ];
    
    return (
        <div className="order_list">
            <Table columns={columns} dataSource={iCardList}/>
        </div>
    );
}

export const CarList = ({iCarList}) => {
    const columns = [
        {
            title: '차량번호',
            dataIndex: 'carNm',
            key: 'carNm',
        },
        {
            title: '차량별명',
            dataIndex: 'carName',
            key: 'carName',
        },
        {
            title: '차량식별정보',
            dataIndex: 'carStyle',
            key: 'carStyle',
        },
        {
            title: '등록날짜',
            dataIndex: 'date',
            key: 'date',
        }
    ];
    
    return (
        <div className="order_list">
            <Table columns={columns} dataSource={iCarList}/>
        </div>
    );
}

