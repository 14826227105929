import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import { Table, message, Drawer, Modal } from 'antd';
import "antd/dist/antd.css";

import { UnorderedListOutlined, LineHeightOutlined, CheckSquareOutlined } from '@ant-design/icons';

const WebInquiry = (oProps) => {
    const [sLoading, setLoading] = useState(true);
    const [iVisible, setVisible] = useState(false);

    const [iCompany, setiCompany] = useState("DHL");
    const [iCompanyNm, setiCompanyNm] = useState("DHL");
    const [iCompanyId, setiCompanyId] = useState("de.dhl");
    const [iNm, setNm] = useState("");

    const [finList, setFinList] = useState([]);
    const [ingList, setIngList] = useState([]);

    const [iPagination, setIPagination] = useState({
        current: 1,
        pageSize: 15,
    });

    const [fPagination, setFPagination] = useState({
        current: 1,
        pageSize: 15,
    });
    const [columns] = useState([
        {
            title: '신청 매장명',
            dataIndex: 'storeName',
            key: 'storeName',
            fixed: 'left',
        },
        {
            title: '신청물',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '신청인 전화번호',
            dataIndex: 'phoneNm',
            key: 'phoneNm',
        },
        {
            title: '신청 날짜',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '상태',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: "설정",
            key: "key",
            render: (text, record, index) => <div className="detail_box" onClick={() => editRow(text)}><p>변경</p></div>
        }
    ]);
    
    const iRef = useRef({});

    const handleFinList = (pagination) => {
        setFPagination(pagination);
    }

    const handleIngList = (pagination) => {
        setIPagination(pagination);
    }

    // const inputCompanyChange = async (event) => {
    //     let sKey = "";
    //     let sNm = "";
    //     let value = event.target.value;
        
    //     for await (const x of deliveryList) {
    //         if(value.toString() === x.id.toString()){
    //             sKey = x.id;
    //             sNm = x.name;
    //         }
    //     }
    //     setiCompanyId(sKey);
    //     setiCompany(value);
    //     setiCompanyNm(sNm);
    // }

    // const inputNmChange = async (e) => {
    //     setNm(e.target.value);
    // }

    const editRow = async (sIndex) => {
        iRef.current = sIndex;
        setVisible(true);
    };

    const responseInquiry = async (e) => {
        let process = true;

        // if(e.state_id.toString() === "1"){
        //     if(iNm === "" || iCompany === ""){
        //         message.error("배송회사, 송장번호를 입력해주세요.");
        //     } else {
        //         process = true;
        //     }
        // } else {
        //     process = true;
        // }

        if(process){
            const oData = {
                userId: oProps.UserConfigReducer.UserId,
                sKey: iRef.current.key.toString(),
                stateId: iRef.current.state_id,
                delivery_nm: iNm,
                delivery_company: iCompanyNm,
                delivery_company_id: iCompanyId
            }
            const oResponse = await oProps.oManager.fDoAxios("/admin/manage/web/responseInquiry2", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
                if(oResponse){
                    setVisible(false);
                    asyncFetch();
                } else {
                    message.error("실패");
                }
            }
        }
    }
    
    const asyncFetch = async () => {
        const oData = {
            userId: oProps.UserConfigReducer.UserId,
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/manage/web/inquiryList2", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            setFinList(oResponse.endList);
            setIngList(oResponse.listing);
        }
        setLoading(false);
    };

    useEffect(() => {
        asyncFetch();
    }, []);

    return (
        <UserStyle>
            {sLoading ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                    <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                </div> 
                :
                <div className="container">
                    <Row style={{marginTop: 70}}>
                        <Col sm={12}>
                                <div className="title_line">
                                    <UnorderedListOutlined style={{fontSize: "1rem"}}/>
                                    <p>진행중인 홍보물 신청 목록</p>
                                </div>
                                <div className="content_line">
                                    <Table 
                                        columns={columns} 
                                        pagination={iPagination} 
                                        dataSource={ingList} 
                                        onChange={handleIngList} 
                                        scroll={{ x: 'calc(700px + 50%)', y: "50vh" }}
                                    />
                                </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20vh"}}>
                        <Col sm={12}>
                                <div className="title_line_2">
                                    <CheckSquareOutlined style={{fontSize: "1rem", color: "#fff" }}/>
                                    <p>완료된 홍보물 신청 목록</p>
                                </div>
                                <div className="content_line">
                                    <Table 
                                        columns={fnColumns} 
                                        pagination={fPagination} 
                                        dataSource={finList} 
                                        onChange={handleFinList} 
                                        scroll={{ x: 'calc(700px + 50%)', y: "50vh" }}
                                    />
                                </div>
                        </Col>
                    </Row>
                </div>
            }
            <Drawer
                title={<DrawerPTag>진행내역</DrawerPTag>}
                width={440}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={iVisible}
            >
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <DrawerContent>

                            <div style={{height: "10vh", marginBottom: "20px" }}>
                                <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>신청 매장명</p>
                                <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                    <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.storeName}</p>
                                </div>
                            </div>
                            <div style={{height: "10vh", marginBottom: "20px" }}>
                                <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>신청물</p>
                                <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                    <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.title}</p>
                                </div>
                            </div>
                            <div style={{height: "10vh", marginBottom: "20px" }}>
                                <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>신청인 전화번호</p>
                                <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                    <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.phoneNm}</p>
                                </div>
                            </div>
                            <div style={{height: "10vh", marginBottom: "20px" }}>
                                <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>배송지</p>
                                <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                    <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.address}</p>
                                </div>
                            </div>
                            <div style={{height: "10vh", marginBottom: "20px" }}>
                                <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>상태</p>
                                <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                    <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.state}</p>
                                </div>
                            </div>
                            <div style={{height: "10vh", marginBottom: "20px" }}>
                                <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>신청날짜</p>
                                <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                    <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.created_at}</p>
                                </div>
                            </div>
                            {iRef.current.state_id >= 1 &&
                                // <>
                                // {iRef.current.state_id > 1 ?
                                //     <>
                                //     <div style={{height: "10vh", marginBottom: "20px" }}>
                                //         <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>배송회사</p>
                                //         <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                //             <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.company}</p>
                                //         </div>
                                //     </div>
                                //     <div style={{height: "10vh", marginBottom: "20px" }}>
                                //         <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>송장번호</p>
                                //         <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                //             <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.deliveryNm}</p>
                                //         </div>
                                //     </div>
                                //     </>
                                //     :
                                //     <>
                                //     <div style={{height: "10vh", marginBottom: "20px" }}>
                                //         <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>배송회사</p>
                                //         <select 
                                //             value={iCompany} 
                                //             onChange={inputCompanyChange}
                                //             style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center", height: "6vh" }}
                                //         >
                                //             {deliveryList.map((item,index) => (
                                //                 <option key={index} value={item.id}>{item.name}</option>
                                //             ))}
                                //         </select>
                                //     </div>
                                //     <div style={{height: "10vh", marginBottom: "20px" }}>
                                //         <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>송장번호</p>
                                //         <input 
                                //             type="text"
                                //             value={iNm}
                                //             onChange={inputNmChange} 
                                //             style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center", height: "6vh" }}
                                //         />
                                //     </div>
                                //     </>
                                // }
                                // </>
                                <></>
                            }
                            {(iRef.current.confirm_at !== "Invalid date") &&
                                <div style={{height: "10vh", marginBottom: "20px" }}>
                                    <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>홍보물 확인 날짜</p>
                                    <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                        <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.confirm_at}</p>
                                    </div>
                                </div>
                            }
                            {(iRef.current.delivered_at !== "Invalid date") &&
                                <div style={{height: "10vh", marginBottom: "20px" }}>
                                    <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>배송 시작날짜</p>
                                    <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                        <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.delivered_at}</p>
                                    </div>
                                </div>
                            }
                            {(iRef.current.updated_at !== "Invalid date") &&
                                <div style={{height: "10vh", marginBottom: "20px" }}>
                                    <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>배송 완료 날짜</p>
                                    <div style={{width: "100%", border: "1px solid #dfdfdf", borderRadius: "4px", outline: "none", display: "flex", justifyContent: "center" }}>
                                        <p style={{fontSize: "0.9rem", fontWeight: "600", color: "#293241", paddingTop: "15px" }}>{iRef.current.updated_at}</p>
                                    </div>
                                </div>
                            }
                                <DrawerButton onClick={() => responseInquiry(iRef.current)}>
                                    {iRef.current.state_id == 0 &&
                                        <p>신청 확인</p>
                                    }
                                    {iRef.current.state_id == 1 &&
                                        <p>발송 완료</p>
                                    }
                                    {iRef.current.state_id == 2 &&
                                        <p>설치 인증 확인</p>
                                    }
                                </DrawerButton>
                        </DrawerContent>
                    </Col>
                </Row>
            </Drawer>
        </UserStyle>
    )
}

export default WebInquiry;

const deliveryList = [
    {
        "id": "de.dhl",
        "name": "DHL",
        "tel": "+8215880001"
    },
    {
        "id": "kr.chunilps",
        "name": "천일택배",
        "tel": "+8218776606"
    },
    {
        "id": "kr.cjlogistics",
        "name": "CJ대한통운",
        "tel": "+8215881255"
    },
    {
        "id": "kr.cupost",
        "name": "CU 편의점택배",
        "tel": "+8215771287"
    },
    {
        "id": "kr.cvsnet",
        "name": "GS Postbox 택배",
        "tel": "+8215771287"
    },
    {
        "id": "kr.cway",
        "name": "CWAY (Woori Express)",
        "tel": "+8215884899"
    },
    {
        "id": "kr.daesin",
        "name": "대신택배",
        "tel": "+82314620100"
    },
    {
        "id": "kr.epost",
        "name": "우체국 택배",
        "tel": "+8215881300"
    },
    {
        "id": "kr.hanjin",
        "name": "한진택배",
        "tel": "+8215880011"
    },
    {
        "id": "kr.hdexp",
        "name": "합동택배",
        "tel": "+8218993392"
    },
    {
        "id": "kr.ilyanglogis",
        "name": "일양로지스",
        "tel": "+8215880002"
    },
    {
        "id": "kr.kdexp",
        "name": "경동택배",
        "tel": "+8218995368"
    },
    {
        "id": "kr.logen",
        "name": "로젠택배",
        "tel": "+8215889988"
    },
    {
        "id": "kr.lotte",
        "name": "롯데택배",
        "tel": "+8215882121"
    },
]

const DrawerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
    border-radius: 10px;
    padding-top: 15px;
    background: #3d5a80;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        font-weight: 500;
        color: #fff;
    }

`;

const DrawerContent = styled.div`
    height: 100%;
    overflow-y: scroll;

`;

const DrawerPTag = styled.p`
    border-radius: 30px;
    background: #fff;
    white-space: nowrap;
    padding: 0px 5px;
    font-size: 0.9rem;
    outline: none;
    border: none;
    text-decoration: none;
    font-weight: bold;

`;

export const UserStyle = styled.div`
        
    .title_line {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 6vh;
        padding-left: 20px;
        border-radius: 10px;
        width: 100%;
        background : #F1F3F7;

        p {
            margin-left: 10px;
            margin-top: 15px;
            font-size: 0.9rem;
            font-weight: 800;
        }
    }
        
    .title_line_2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 6vh;
        padding-left: 20px;
        border-radius: 10px;
        width: 100%;
        background : #BBBBBB;

        p {
            margin-left: 10px;
            margin-top: 15px;
            font-size: 0.9rem;
            font-weight: 800;
            color: #fff;
        }
    }

    .content_line {
        margin-top: 5vh;
        height: 50vh;

        .detail_box {
            display: flex;
            justify-content: center;
            padding-top: 5px;
            height: 3vh;
            width: 3vw;
            border-radius: 5px;
            background : #617BE3;
            color: #fff;
            font-size: 0.9rem;
            cursor: pointer;
        }
    }
`;

const fnColumns = [
    {
        title: '신청 매장명',
        dataIndex: 'storeName',
        key: 'storeName',
        fixed: 'left',
    },
    {
        title: '신청물',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: '신청인 전화번호',
        dataIndex: 'phoneNm',
        key: 'phoneNm',
    },
    {
        title: '신청 날짜',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    {
        title: '상태',
        dataIndex: 'state',
        key: 'state',
    }
];