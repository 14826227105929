import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import { Drawer, message, Table } from 'antd';
import "antd/dist/antd.css";

import DoubleLineChart from '../../components/charts/DoubleLineChart';

import { UnorderedListOutlined, LineChartOutlined, LineHeightOutlined } from '@ant-design/icons';
import addImg from '../../assets/svg/salesTeam.svg';

const SalesTeam = (oProps) => {
    const [sLoading, setLoading] = useState(true);

    const [isVisible, setVisible] = useState(false);

    const [chartData, setChartData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [teamList, setTeamList] = useState([]);

    const [companyGroup, setCompanyGroup] = useState("신규");
    const [group_name, setGroupName] = useState("");
    const [userName, setUserName] = useState("");
    const [content, setContent] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [pw, setPw] = useState("");
    const [group_id, setGroup_id] = useState("");

    const onChangeCompanyGroup = async (event) => {
        let sKey = 0;
        let sNm = "";
        let value = event.target.value;

        if(value === "신규"){
            sKey = (teamData[teamData.length - 1].key) + 1;
        } else {
            for await (const x of teamData) {
                if(value.toString() === x.name.toString()){
                    sKey = x.key;
                    sNm = x.name;
                }
            }
        }
        setGroup_id(sKey);
        setGroupName(sNm);
        setCompanyGroup(value);
    }
    const onChangeGroupName = (event) => {
        const value = event.target.value;
        setGroupName(value);
    }
    const onChangeUserName = (event) => {
        const value = event.target.value;
        setUserName(value);
    }
    const onChangeContent = (event) => {
        const value = event.target.value;
        setContent(value);
    }
    const onChangeUserPhone = (event) => {
        const value = event.target.value;
        setUserPhone(value);
    }
    const onChangeUserEmail = (event) => {
        const value = event.target.value;
        setUserEmail(value);
    }
    const onChangePw = (event) => {
        const value = event.target.value;
        setPw(value);
    }
    
    const changeStatus = async (e,a) => {
        let oData = {
            userId: oProps.UserConfigReducer.UserId,
            sParam: e,
            aParam: a
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/sales/changeSalesUserStatus", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            if(oResponse){
                message.info("변경완료");
                setLoading(true);
                asyncFetch();
            } else {
                message.error("실패");
            }
        }
    };

    const insertSalesUser = async (e) => {
        let oData = {
            userPhone,
            group_name,
            userName,
            content,
            userEmail,
            pw,
            group_id,
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/sales/authenticate/signUp/v2", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            if(oResponse){
                message.info("등록완료");
                setLoading(true);
                asyncFetch();
            } else {
                message.error("실패");
            }
        }
    };

    const asyncFetch = async () => {
        let oData = {
            userId: oProps.UserConfigReducer.UserId,
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/sales/salesData", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            const config1 = {
                data: oResponse.chart,
                xField: 'month',
                yField: 'scales',
                seriesField: 'name',
                yAxis: {},
                smooth: true,
                animation: {
                    appear: {
                        animation: 'path-in',
                        duration: 5000,
                    },
                },
            };
            let sList = oResponse.teamList;
            let aList = [
                {
                   name : "신규"
                }
            ]
            aList = aList.concat(oResponse.teamList);
            setGroup_id((sList[sList.length - 1].key) + 1)
            setTeamList(aList);
            setChartData(config1);
            setUserData(oResponse.userList);
            setTeamData(oResponse.teamList);
            setLoading(false);
        }
    };

    useEffect(() => {
        asyncFetch();
    }, []);

    return (
        <UserStyle>
            {sLoading ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                    <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                </div> 
                :
                <div className="container">
                    <Row style={{marginTop: 70}}>
                        <Col sm={4} style={{height: 500}}>
                            <div className="add_row">
                                <div className="title">
                                    <div className="title_line">
                                        <div className="insert_button">
                                            <p>새로운 영업팀이나 영업인원 추가하기</p>
                                        </div>
                                    </div>
                                    <div className="insert_button_line">
                                        <div className="insert_button" onClick={() => setVisible(true)}>
                                            <p>추가하기</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <img src={addImg} alt="none" />
                                </div>
                            </div>
                        </Col>
                        <Col sm={8} style={{height: 500}}>
                            <div className="list_row">
                                <div className="title_line">
                                    <UnorderedListOutlined style={{fontSize: "1.5rem"}}/>
                                    <p>등록된 영업인원 목록</p>
                                </div>
                                <div className="content_line">
                                    {userData.map((item,index) => (
                                        <div className="list_box" key={index}>
                                            <div className="list_title">
                                                <div className="list_title_name">
                                                    <p>{item.name}</p>
                                                </div>
                                                <div className="list_title_company">
                                                    <p>{item.groupNm}</p>
                                                </div>
                                            </div>
                                            <div className="list_content">
                                                <div className="list_title_name">
                                                    <p>{item.email}</p>
                                                </div>
                                                <div className="list_title_company">
                                                    <p>{item.content}</p>
                                                </div>
                                            </div>
                                            <div className="list_button_line" onClick={() => changeStatus(item.key,item.status)}>
                                                <div className={item.status !== undefined && item.status !== null && item.status ? "list_button_delete" : "list_button"}>
                                                    <p>{item.status ? "비활성화" : "활성화"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} style={{height: 500}}>
                            <div className="team_row">
                                <div className="title_line">
                                    <LineHeightOutlined style={{fontSize: "1.5rem"}}/>
                                    <p>등록된 영업팀 목록</p>
                                </div>
                                <div className="content_line">
                                    {teamData.map((item,index) => (
                                        <div className="list_box" key={index}>
                                            <div className="list_title">
                                                <div className="list_title_name">
                                                    <p>{item.name}</p>
                                                </div>
                                            </div>
                                            <div className="list_content">
                                                <div className="list_title_name">
                                                    <p>{item.count} 건</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col sm={8} style={{height: 500}}>
                            <div className="chart_row">
                                <div className="title_line">
                                    <LineChartOutlined style={{fontSize: "1.5rem"}}/>
                                    <p>영업팀별 매장등록수</p>
                                </div>
                                <div className="content_line">
                                    <DoubleLineChart vChart={chartData} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            <Drawer
                title={<DrawerPTag>영업팀 추가하기</DrawerPTag>}
                width={440}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={isVisible}
            >
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>회사선택</p>
                            <select 
                                value={companyGroup} 
                                onChange={onChangeCompanyGroup}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            >
                                {teamList.map((item,index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        {companyGroup !== undefined && companyGroup === "신규" &&
                            <div style={{height: 80, marginBottom: "20px"}}>
                                <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>회사명</p>
                                <input 
                                    type="text" 
                                    value={group_name} 
                                    onChange={onChangeGroupName}
                                    style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}

                                />
                            </div>
                        }
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>사원이름</p>
                            <input 
                                type="text" 
                                value={userName} 
                                onChange={onChangeUserName}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}

                            />
                        </div>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>이메일</p>
                            <input 
                                type="text" 
                                value={userEmail} 
                                onChange={onChangeUserEmail}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}

                            />
                        </div>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>비밀번호</p>
                            <input 
                                type="text" 
                                value={pw} 
                                onChange={onChangePw}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}

                            />
                        </div>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>사원정보</p>
                            <input 
                                type="text" 
                                value={content} 
                                onChange={onChangeContent}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}

                            />
                        </div>
                        <div style={{height: 80, marginBottom: "50px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>전화번호</p>
                            <input 
                                type="text" 
                                value={userPhone} 
                                onChange={onChangeUserPhone}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        <DrawerButton onClick={insertSalesUser}>
                            <p>추가하기</p>
                        </DrawerButton>
                    </Col>
                </Row>
            </Drawer>
        </UserStyle>
    )
}

export default SalesTeam;


const DrawerPTag = styled.p`
    border-radius: 30px;
    background: #fff;
    white-space: nowrap;
    padding: 0px 5px;
    font-size: 0.9rem;
    outline: none;
    border: none;
    text-decoration: none;
    font-weight: bold;

`;


const DrawerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 10px;
    padding-top: 15px;
    background: #3d5a80;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        font-weight: 500;
        color: #fff;
    }

`;

export const UserStyle = styled.div`
    .container {
        width: 100%;
    }

    .insert_view {
        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
        }
    }

    .add_row {
        border-radius: 10px;
        border: 1px solid #3d5a80;
        width: 100%;
        height: 450px;


        .title {
            height: 300px;

            .title_line {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                height: 120px;

                p {
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }

            .insert_button_line {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                height: 180px;
                
                .insert_button {
                    border-radius: 15px;
                    width: 150px;
                    height: 40px;
                    border: 1px solid #06d6a0;
                    cursor: pointer;

                    p {
                        margin-top: 10px;
                        text-align: center;
                        font-size: 0.9rem;
                        font-weight: 500;
                        color: #06d6a0;
                    }
                }

                .insert_button:hover {
                    background: #06d6a0;
                    p {
                        color: #fff;
                    }
                }
            }
        }
        .content {
            height: 140px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .list_row {
        border-radius: 10px;
        border: 1px solid #98c1d9;
        width: 100%;
        height: 450px;

        .title_line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 50px;
            margin-left: 20px;

            p {
                margin-left: 10px;
                margin-top: 15px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .content_line {
            position: "relative";
            overflow-y: scroll;
            overflow-x: hidden;
            margin : 20px;
            height: 360px;

            .list_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px; 
                height: 50px;
                border-bottom: 1px solid #293241;

                .list_title {
                    width: 30%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        height: 25px;
                        p {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                    .list_title_company {
                        display: flex;
                        height: 25px;

                        p {
                            font-size: 0.7rem;
                            font-weight: 500;
                            color: #bbbbbb;
                        }
                    }
                }
                .list_content {
                    width: 50%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        height: 25px;
                        p {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                    .list_title_company {
                        display: flex;
                        height: 25px;

                        p {
                            font-size: 0.7rem;
                            font-weight: 500;
                            color: #bbbbbb;
                        }
                    }
                }
                .list_button_line {
                    width: 20%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .list_button {
                        width: 60px;
                        height: 30px;
                        border: 1px solid #06d6a0;
                        cursor: pointer;
                        border-radius: 10px;
                        p {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: #06d6a0;
                        }
                    }

                    .list_button:hover {
                        background: #06d6a0;
                        p {
                            color: #fff;
                        }
                    }

                    .list_button_delete {
                        width: 60px;
                        height: 30px;
                        border: 1px solid #db3a34;
                        cursor: pointer;
                        border-radius: 10px;
                        p {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: #db3a34;
                        }
                    }

                    .list_button_delete:hover {
                        background: #db3a34;
                        p {
                            color: #fff;
                        }
                    }
                }
            }
        }

    }

    .team_row {
        border-radius: 10px;
        border: 1px solid #293241;
        width: 100%;
        height: 450px;

        .title_line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 50px;
            margin-left: 20px;

            p {
                margin-left: 10px;
                margin-top: 15px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .content_line {
            position: "relative";
            overflow-y: scroll;
            overflow-x: hidden;
            margin : 20px;
            height: 360px;

            .list_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px; 
                height: 50px;
                border-bottom: 1px solid #293241;

                .list_title {
                    width: 70%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        
                        p {
                            font-size: 0.8rem;
                            font-weight: 500;
                        }
                    }
                }
                .list_content {
                    width: 30%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;

                        p {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .chart_row {
        border-radius: 10px;
        border: 1px solid #007ea7;
        width: 100%;
        height: 450px;

        .title_line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 50px;
            margin-left: 20px;

            p {
                margin-left: 10px;
                margin-top: 15px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .content_line {
            margin : 20px;
            height: 360px;
        }
    }
`;