import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import { StockOutlined, BarsOutlined, ContactsOutlined, ProfileOutlined } from '@ant-design/icons';
import { Table, DatePicker, message, Divider } from 'antd';
import moment from 'moment-timezone';
import Aos from 'aos';
import "aos/dist/aos.css";
import 'moment/locale/ko';
import "antd/dist/antd.css";

import DoubleLineChart from '../../components/charts/DoubleLineChart';

const { RangePicker } = DatePicker;
const defaultFormat = "YYYY/MM/DD";

const UserPage = ({ sProps, fnGetOrderInfo }) => {
    const [isLoading, setLoading] = useState(true);

    const [fromDate, setFromDate] = useState(moment().add(-1,"months").format(defaultFormat));
    const [toDate, setToDate] = useState(moment().format(defaultFormat));

    const [sOrderList, setSOrderList] = useState({});
    const [iOrderList, setIOrderList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [sColumns] = useState([
        {
            title: "매장이름",
            key: "store_name",
            dataIndex: "store_name",
            fixed: 'left',
            width: 200,
        },
        {
            title: "주문일시",
            key: "date",
            dataIndex: "date",
            width: 200,
        },
        {
            title: "매장 전화번호",
            key: "store_phone",
            dataIndex: "store_phone",
            width: 200,
        },
        {
            title: "고객 이메일",
            key: "user_email",
            dataIndex: "user_email",
            width: 200,
        },
        {
            title: "고객 전화번호",
            key: "user_phone",
            dataIndex: "user_phone",
            width: 200,
        },
        {
            title: "주문금액",
            key: "total",
            dataIndex: "total",
            width: 200,
        },
        {
            title: "결제금액",
            key: "payment",
            dataIndex: "payment",
            width: 200,
        },
        {
            title: "할인 금액",
            key: "discount",
            dataIndex: "discount",
            width: 200,
        },
        {
            title: "주문 상태",
            key: "status",
            dataIndex: "status",
            width: 200,
        },
        {
            key: "key",
            dataIndex: "key",
            fixed: 'right',
            width: 200,
            render: (text, record, index) => <div className="order_detail_box_button" onClick={() => openOrderDetail(text)}><p>자세히</p></div>
        },
        
    ])

    const openOrderDetail = async (sIndex) => {
        if(fnGetOrderInfo !== undefined && typeof fnGetOrderInfo === "function"){
            await fnGetOrderInfo(sIndex);
        }
    }

    const onOperatingDate = (value, dateString) => {
        if(dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== ""){
            if(fromDate === moment().format(defaultFormat)){
                setFromDate(dateString[0]);
            } else {
                setFromDate(dateString[0]);
                setToDate(dateString[1]);
            }
        } else {
            setFromDate(moment().format(defaultFormat));
            setToDate(moment().add(1, 'days').format(defaultFormat));
        }
    }

    const onSearch = async () => {
        setLoading(true);

        await asyncData();
    }

    const asyncData = async (iResult) => {
        const oData = {
            userId: sProps.UserConfigReducer.UserId,
            fromDate,
            toDate,
        }
        const oResponse = await sProps.oManager.fDoAxios("/admin/orderList/v2", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            const config1 = {
                data: oResponse.orderChart,
                xField: 'date',
                yField: 'scales',
                seriesField: 'name',
                yAxis: {},
                smooth: true,
                animation: {
                    appear: {
                        animation: 'path-in',
                        duration: 5000,
                    },
                },
            };
            setIOrderList(config1);
            setLoading(false);
            setSOrderList(oResponse.orderData);
            setOrderList(oResponse.orderList);
        }
    }
    useEffect(() => {
        asyncData();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        Aos.init({ duration: 1000 });
    })

    return (
        <>
        <Row>
            <Col>
                <div className="search_box">
                    <div className="select_date">
                        <RangePicker 
                            bordered={false} 
                            className="date_picker"
                            style={{marginLeft: "5%",fontSize: "0rem", width: "90%"}}
                            format={defaultFormat}
                            value={[moment(fromDate, defaultFormat),moment(toDate, defaultFormat)]} 
                            onChange={onOperatingDate}
                        />
                    </div>
                    <div className="click_box" onClick={onSearch}>
                        <p>조회하기</p>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm={8}>
                <div className="chartLine">
                    {isLoading ?
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                        </div>
                    :
                        <div className="chartBox" data-aos="fade-up" data-aos-delay="300">
                            <div className="chartTitle">
                                <StockOutlined style={{fontSize: "30px"}}/> 
                                <p>주간 주문 수</p>
                            </div>
                            <DoubleLineChart vChart={iOrderList} />
                        </div>
                    }
                </div>
            </Col>
            <Col sm={4}>
                <div className="connectedList" >
                    <div className="user_box" data-aos="fade-up"> 
                        <div className="user_line_title">
                            <BarsOutlined style={{fontSize: "25px"}}/>
                            <p>선택기간동안 총 매출액</p>
                        </div>
                        <div className="user_line_count">
                            <p>{sOrderList.sOrderPrice} 원</p>
                        </div>
                    </div>
                    <div className="connected_car_box" data-aos="fade-up" data-aos-delay="200"> 
                        <div className="user_line_title">
                            <ContactsOutlined style={{fontSize: "25px"}}/>
                            <p>선택기간동안 주문건수</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">전체</p>
                            <p className="number">{sOrderList.sTotal} 건</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">픽업완료</p>
                            <p className="number">{sOrderList.sSuccess} 건</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">매장취소</p>
                            <p className="number">{sOrderList.sCancel} 건</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">자동취소</p>
                            <p className="number">{sOrderList.aCancel} 건</p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="table_title">
                    <ProfileOutlined style={{fontSize: "30px"}}/> 
                    <p>조회결과</p>
                </div>
                <div className="columns_box">
                    <Table columns={sColumns} dataSource={orderList} pagination={true} scroll={{ x: 'calc(250px + 80%)', y: "90vh" }}/>
                    <Divider orientation="left" style={{color: "#d12f22"}}>
                    대외비 관리 주의사항
                    </Divider>
                    <p style={{color: "#d12f22"}}>
                        본자료에는 고객의 개인정보가 포함된 대외비입니다. 본 자료는 마케팅 참고 자료로만 활용이 가능합니다.<br />
                        본 자료를 회사의 동의 또는 허가없이 외부에 공유할 경우 민형사상 책임이 발생합니다<br />
                        다운로드된 자료는 사용후 저장 및 공유를 금지하며, 1회 목적으로 사용후 폐기하여야 합니다<br />
                        다운로드 이력은 추적관리 되며, 관리소홀로 인한 문제 발생시 회사는 최초 자료 조회자를 포함한 모든 관련자에게 민형사상 책임을 물을 수 있습니다
                    </p>
                </div>
            </Col>
        </Row>
        </>
    )
}

export default UserPage;

