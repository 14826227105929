import React from 'react';
import { Column } from '@ant-design/charts';

interface ConfigData {
  aChart: any;
}

const DoubleBarChart: React.FC<ConfigData> = props => {
  
  return <Column {...props.aChart} />;
};

export default DoubleBarChart;