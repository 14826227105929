import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FileSearchOutlined } from '@ant-design/icons';
import { Drawer, Table } from 'antd';
import "antd/dist/antd.css";

import none_image from '../../assets/svg/none_image.svg';

export const Categorylist = ({ilist, oProps}) => {
    const [isVisible, setVisible] = useState(false);

    const [sList, setList] = useState([]);

    const [sPagination, setSPagination] = useState({
        current: 1,
        pageSize: 5,
    });
    const [iPagination, setIPagination] = useState({
        current: 1,
        pageSize: 5,
    });

    
    const columns = [
        {
            title: '분류명',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '상태',
            dataIndex: 'useYn',
            key: 'useYn',
        },
        {
            title: '메인',
            dataIndex: 'isMain',
            key: 'isMain',
        },
        {
            key: "key",
            dataIndex: "id",
            render: (text, record, index) => <div style={{background: "#13166b", borderRadius: 5, paddingTop: 15, height: 30, width: 50, justifyContent: "center", display: "flex", alignItems: "center", cursor: "pointer"}} onClick={() => getProductData(text)}><p style={{color: "#fff", fontSize: "0.8rem"}}>자세히</p></div>
        },
    ];
    const sColumns = [
        {
            title: '상품사진',
            dataIndex: 'img',
            key: 'img',
            render: (text, record, index) =>
            <div style={{width: 80, height: 80}}>
                <img style={{width: "100%", height: "100%"}} src={text !== undefined && text !== null && text !== "" ? text : none_image} alt="none" />
            </div>
            
        },
        {
            title: '상품이름',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '가격',
            dataIndex: 'oPrice',
            key: 'oPrice',
        },
        {
            title: '할인가격',
            dataIndex: 'bPrice',
            key: 'bPrice',
        },
    ];

    const sTableChange = (pagination) => {
        setSPagination(pagination);
    }
    const iTableChange = (pagination) => {
        setIPagination(pagination);
    }

    const getProductData = async (sParam) => {
        console.log("sParam",sParam);
        const oResponse = await oProps.oManager.fDoAxios("/admin/product/productInfo/v2-" + sParam, "get", null, null);
        if (oResponse !== undefined && oResponse !== null) {
            console.log("oResponse",oResponse);
            setList(oResponse);
            setVisible(true);
        } 
    }

    return (
        <div className="order_list">
            <Table pagination={sPagination} columns={columns} dataSource={ilist} onChange={sTableChange}/>
            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={isVisible}
            >
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                            <FileSearchOutlined style={{fontSize: "30px"}}/> 
                            <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>상품리스트</p>
                        </div>
                        <div>
                            <Table pagination={iPagination} columns={sColumns} dataSource={sList} onChange={iTableChange}/>
                        </div>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
}

export const Optionlist = ({ilist}) => {
    const [isVisible, setVisible] = useState(false);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
    });

    const columns = [
        {
            title: '옵션명',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '타입',
            dataIndex: 'type',
            key: 'type',
        }
    ];

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    }
    
    return (
        <div className="order_list">
            <Table pagination={pagination} columns={columns} dataSource={ilist} onChange={handleTableChange}/>
            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={isVisible}
            >
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                            <FileSearchOutlined style={{fontSize: "30px"}}/> 
                            <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>상품리스트</p>
                        </div>
                        <div>
                            <div className="order_list_title" style={{marginTop: 10, paddingTop:15, height: 50, display: "flex", alignItems: "center", background: "#ececec"}}>
                                <p style={{marginLeft: "5%", fontSize: "0.9rem", fontWeight: 500}}>주문내역</p>
                            </div>
                            <Table pagination={pagination} columns={columns} dataSource={ilist} onChange={handleTableChange}/>
                        </div>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
}
