/*global kakao*/
import React, { useState } from "react";
import Loader from "react-loader-spinner";
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserOutlined, RocketOutlined, MessageOutlined, FunctionOutlined, ProfileOutlined, BarsOutlined, PushpinOutlined, RestOutlined, NodeExpandOutlined } from '@ant-design/icons';
import { Drawer, Divider, message, Modal } from 'antd';
import "antd/dist/antd.css";

import OrderList from '../../components/order/OrderList';
import OrderInfo from '../../components/order/OrderInfo';
import KakaoMap from '../../components/modal/KakaoMap';
import KakaoRoute from '../../components/modal/KakaoRoute';

const UserPage = (oProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setVisible] = useState(false);

    const [orderInfo, setOrderInfo] = useState({});
    const [orderId, setOrderId] = useState(null);

    const [fnCancel, setFnCancel] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [routeVisible, setRouteVisible] = useState(false);
    
    const [pickUp, setPickUp] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [orderData, setOrderData] = useState({});

    const [sContext, setContext] = useState("");

    const [userLat, setUserLat] = useState(37.56637919891677);
    const [userLng, setUserLng] = useState(126.97914589375286);
    const [anglePoint, setAnglePoint] = useState(
        { 
            pan: 0,
            tilt: 0,
            zoom: 0
        }
    );

    const inputChange = async (e) => {
        setContext(e.target.value);
    }

    const cancelOrder = async () => {
        if(sContext === "cancel"){
            const oData = {
                userId: oProps.UserConfigReducer.UserId,
                sOrderId: orderId
            }
            const oResponse = await oProps.oManager.fDoAxios("/admin/orderInfo/cancel", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
                if(oResponse.result_cd === "0000"){
                    setFnCancel(false);
                    message.info(oResponse.result_msg);
                } else {
                    message.error(oResponse.result_msg);
                }
            }
        }
    }

    const getOrderInfo = async (sIndex) => {
        setVisible(true);

        const oData = {
            userId: oProps.UserConfigReducer.UserId,
            sParam: sIndex
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/orderInfo/v2", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            window.scrollTo(0, 0);
            if(oResponse.userLat !== undefined && oResponse.userLat !== null){
                setUserLat(oResponse.userLat);
            }
            if(oResponse.userLng !== undefined && oResponse.userLng !== null){
                setUserLng(oResponse.userLng);
            }
            if(oResponse.anglePoint !== undefined && oResponse.anglePoint !== null){
                setAnglePoint(oResponse.anglePoint);
            }
            if(oResponse.orderData !== undefined && oResponse.orderData !== null){
                setOrderData(oResponse.orderData);
            }
            if(oResponse.orderList !== undefined && oResponse.orderList !== null){
                setOrderList(oResponse.orderList);
            }
            if(oResponse.pickUpRoute !== undefined && oResponse.pickUpRoute !== null){
                setPickUp(oResponse.pickUpRoute);
            }

            setOrderId(sIndex);
            setIsLoading(false);
        }
    }

    return (
        <UserStyle>
            <div className="container">
                <Row>
                    <Col>
                        <div className="header">
                            <div className="header_title">
                                <p>주문관리</p>
                            </div>
                            <div className="header_content">
                            </div>
                        </div>
                    </Col>
                </Row>
                <OrderList 
                    sProps={oProps}
                    fnGetOrderInfo={(sIndex) => getOrderInfo(sIndex)}
                />
            </div>
            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={isVisible}
            >
                {isLoading ?
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                        <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                    </div>
                :
                    <>
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="store_info_container">
                                <div className="title_box" style={{width: "100%", height: 100, alignItems: "center", justifyContent: "center", borderBottom: "1px solid #dfdfdf"}} data-aos="fade-up"> 
                                    <div className="user_line_title" style={{display: "flex", alignItems: "center"}}>
                                        <BarsOutlined style={{fontSize: "25px"}}/>
                                        <p style={{marginTop: 15, marginLeft: 15, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>매장 이름</p>
                                    </div>
                                    <div className="user_line_count">
                                        <p style={{fontSize: "1.1rem", fontWeight: "bold", color: "#000"}}>{orderData.name}</p>
                                    </div>
                                </div>
                                <div className="store_info_box" style={{width: "100%", borderBottom: "1px solid #dfdfdf"}} data-aos="fade-up" data-aos-delay="200"> 
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장주소:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.address}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장전화번호:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.phone}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>대표:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.owner}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>주문일시:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.date}</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>총 금액:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.total} 원</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>결제금액:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.payment} 원</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>스루 포인트 사용액:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.t_point} 원</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>스루 쿠폰 사용액:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.t_coupon} 원</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장 포인트 사용액:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.s_point} 원</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}>
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>매장 쿠폰 사용액:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: "#000"}}>{orderData.s_coupon} 원</p>
                                    </div>
                                    <div className="store_info_line" style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center", height: 40}}> 
                                        <p className="title" style={{fontSize: "0.8rem", fontWeight: 600, color: "#666666"}}>주문상태:</p>
                                        <p className="number" style={{fontSize: "0.9rem", fontWeight: 600, color: orderData.state_id === "none" ? "#13166b" : "#d12d13"}} >{orderData.status}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="order_member_box" style={{marginTop: 50}}>
                                <div className="user_line_title" style={{display: "flex", alignItems: "center"}}>
                                    <BarsOutlined style={{fontSize: "25px"}}/>
                                    <p style={{marginTop: 15, marginLeft: 15, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>고객 정보</p>
                                </div>
                                <div className="member_title" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 100}}>
                                    <div className="member_title_img" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 100,width: 100, borderRadius: 15, background: "#f9f9f9"}}>
                                        <UserOutlined style={{fontSize: 50, color: "#13166b"}}/>
                                    </div>
                                </div>
                                <div className="member_email" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 50}}>
                                    <p style={{marginTop: 5, fontSize: "0.9rem", fontWeight: 700}}>{orderData.user_email}</p>
                                </div>
                                <div className="member_line_box" style={{display: "flex", alignItems: "center", height: 70}}>
                                    <div className="member_line" style={{background: "#13166b", margin: "5%", paddingLeft: 20, width: "50%", height: 70, borderRadius: 15}}>
                                        <p style={{color: "#fff", marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>전화번호</p>
                                        <p style={{color: "#fff", marginTop: 10, fontSize: "0.9rem", fontWeight: 600}} className="strong_color">{orderData.user_phone}</p>
                                    </div>
                                    <div className="member_line" style={{margin: "5%", paddingLeft: 20, width: "50%", height: 70, borderRadius: 15, background: "#f9f9f9"}}>
                                        <p style={{color: "#000", marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>이름</p>
                                        <p style={{color: "#000", marginTop: 10, fontSize: "0.9rem", fontWeight: 600}}className="strong_color">{orderData.user_name}</p>
                                    </div>
                                </div>
                                <div className="member_detail_box" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 50, margin: 30, background: "rgba(97, 123, 227, 0.1)", borderRadius: 10}}>
                                    <div className="member_detail_line" style={{display: "flex", alignItems: "center", width: "40%", marginLeft: "15%"}}>
                                        <RocketOutlined style={{fontSize: 30, color: "rgb(0, 173, 171)"}}/>
                                        <p style={{marginLeft: 10, marginTop: 15, fontSize: "0.8rem", color: "#bbbbbb"}}>차량:</p>
                                    </div>
                                    <div className="member_detail_line2" style={{display: "flex", alignItems: "center", justifyContent:"center", width: "60%"}}>
                                        <p style={{marginTop: 15, fontSize: "0.9rem", fontWeight: 500}}>{orderData.user_car}</p>
                                    </div>
                                </div>
                                <div className="member_detail_box" style={{display: "flex", alignItems: "center", justifyContent:"center", height: 50, margin: 30, background: "rgba(97, 123, 227, 0.1)", borderRadius: 10}}>
                                    <div className="member_detail_line" style={{display: "flex", alignItems: "center", width: "40%", marginLeft: "15%"}}>
                                        <MessageOutlined style={{fontSize: 30, color: "rgb(0, 173, 171)"}}/>
                                        <p style={{marginLeft: 10, marginTop: 15, fontSize: "0.8rem", color: "#bbbbbb"}}>전달하는 말:</p>
                                    </div>
                                    <div className="member_detail_line2" style={{display: "flex", alignItems: "center", justifyContent:"center", width: "60%"}}>
                                        <p style={{marginTop: 15, fontSize: "0.9rem", fontWeight: 500}}>{orderData.message}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Divider />  
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="order_function_box" style={{marginTop: 50, marginBottom: 50}}>
                                <div className="user_line_title" style={{display: "flex", alignItems: "center"}}>
                                    <FunctionOutlined style={{fontSize: "25px"}}/>
                                    <p style={{marginTop: 15, marginLeft: 15, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>주문 정보</p>
                                </div>
                                <div className="function_box_line" style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <div className="box_line" style={{padding: "5%", marginTop: 10, width: "30%", height: 120, background: "#f9f9f9", borderRadius: 10, cursor: "pointer"}} onClick={() => setModalVisible(true)}>
                                        <PushpinOutlined style={{fontSize: "30px"}}/> 
                                        <p style={{marginTop: 15, fontSize: "0.9rem", fontWeight: "bold"}}>픽업존확인하기</p>
                                    </div>
                                    <div className="box_line" style={{padding: "5%", marginTop: 10, width: "30%", height: 120, background: "#f9f9f9", borderRadius: 10, cursor: "pointer"}} onClick={() => setRouteVisible(true)}>
                                        <NodeExpandOutlined style={{fontSize: "30px"}}/> 
                                        <p style={{marginTop: 15, fontSize: "0.9rem", fontWeight: "bold", color: "#13166b"}}>픽업루트확인하기</p>
                                    </div>
                                    <div className="box_line" style={{padding: "5%", marginTop: 10, width: "30%", height: 120, background: "#f9f9f9", borderRadius: 10, cursor: "pointer"}} onClick={() => setFnCancel(true)}>
                                        <RestOutlined  style={{fontSize: "30px"}}/> 
                                        <p style={{marginTop: 15, fontSize: "0.9rem", fontWeight: "bold", color: "#d12d13"}}>주문취소로 변경</p>
                                    </div>
                                </div>
                            </div>
                        </Col>    
                    </Row>
                    <Divider />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <div className="table_title" style={{marginTop: 50, display: "flex", height: 50}}>
                                <ProfileOutlined style={{fontSize: "30px"}}/> 
                                <p style={{marginTop: 5, marginLeft: 10, fontSize: "0.9rem", fontWeight: "bold", color: "#999999"}}>주문내역</p>
                            </div>
                            <div className="order_list_box" style={{marginBottom: 100}}>
                                <div className="order_title" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40, background: "#f9f9f9"}}>
                                    <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "60%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>품목</p>
                                    </div>
                                    <div className="count" style={{display: "flex", alignItems: "center", justifyContent: "flex-end", width: "10%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>수량</p>
                                    </div>
                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "30%"}}>
                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>가격</p>
                                    </div>
                                </div>

                                {orderList !== undefined && orderList.length > 0 &&
                                    <>
                                        {orderList.map((item,index) => {
                                            return (
                                                <div className="order_list_line" key={index}>
                                                    <div className="order_list" style={{marginTop: 10, display: "flex", alignItems: "center", height: 40}}>
                                                        <div className="title" style={{marginLeft: 20, display: "flex", alignItems: "center", width: "60%"}}>
                                                            <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.name}</p>
                                                        </div>
                                                        <div className="count" style={{display: "flex", justifyContent: "flex-end", alignItems: "center", width: "10%"}}>
                                                            <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.count}</p>
                                                        </div>
                                                        <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "30%"}}>
                                                            <p style={{marginTop: 10, fontSize: "0.9rem", fontWeight: 700}}>{item.price} 원</p>
                                                        </div>
                                                    </div>
                                                    {item.list.length > 0 &&
                                                        <>
                                                        {item.list.map((sItem,sIndex) => {
                                                            return (
                                                                <div className="order_option_list" style={{display: "flex", alignItems: "center"}} key={sIndex}>
                                                                    <div className="title" style={{marginLeft: "3%", display: "flex", alignItems: "center", width: "59%"}}>
                                                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>+ {sItem.name}</p>
                                                                    </div>
                                                                    <div className="count" style={{display: "flex", justifyContent: "flex-end", alignItems: "center", width: "10%"}}>
                                                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}></p>
                                                                    </div>
                                                                    <div className="price" style={{marginRight: 20, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "30%"}}>
                                                                        <p style={{marginTop: 10, fontSize: "0.8rem", fontWeight: 500}}>{sItem.price} 원</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        </>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                    </>

                }
            </Drawer>
            <Modal
                title="주문취소"
                visible={fnCancel}
                onOk={cancelOrder}
                onCancel={() => setFnCancel(false)}
                okText="확인"
                cancelText="취소"
            >
                <p>매장이름 : {orderData.name}</p>
                <p>결제금액: {orderData.payment} 원</p>
                <p>선택된 주문을 취소하시겠습니까?</p>
                <input 
                    placeholder="취소를 입력해주세요"
                    value={sContext} 
                    onChange={inputChange} 
                />
            </Modal>
            <Modal 
                width={"60vw"} 
                visible={modalVisible} 
                centered={true} 
                closable={false} 
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                okText="확인" 
                cancelText="취소"
            >
                <KakaoMap 
                    sUserLat={userLat}
                    sUserLng={userLng}
                    iViewPoint={anglePoint}
                />
            </Modal>
            <Modal 
                width={"60vw"} 
                visible={routeVisible} 
                centered={true} 
                closable={false} 
                onOk={() => setRouteVisible(false)}
                onCancel={() => setRouteVisible(false)}
                okText="확인" 
                cancelText="취소"
            >
                <KakaoRoute 
                    sUserLat={userLat}
                    sUserLng={userLng}
                    sPickUp={pickUp}
                />
            </Modal>
        </UserStyle>
    )
}

export default UserPage;


export const UserStyle = styled.div`
    .order_detail_box_button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100px;
        border: 1px solid #13166b;
        border-radius: 10px;
        cursor: pointer;

        p {
            margin-top: 15px;
            font-size: 0.8rem;
            font-weight: bold;
            color: #13166b;
        }
    }

    .order_detail_box_button:hover {
        background: #13166b;
        p {
            color: #fff;
        }
    }

    .member_chart_title {
        margin-top: 100px;
        margin-bottom: 20px;
        margin-left: 50px;
        margin-right: 50px;
        display: flex;
        p {
            margin-top: 5px;
            margin-left: 10px;
            font-size: 0.9rem;
            font-weight: bold;
            color: #999999;
        }
    }

    .member_chart {
        margin-bottom: 100px;
        margin-left: 50px;
        margin-right: 50px;
        height: 500px;
    }

    .member_box {
        margin-top: 100px;
        margin-bottom: 80px;
        height: 500px;

        .member_title {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;

            .member_title_img {
                display: flex;
                height: 100px;
                width: 100px;
                border-radius: 15px;
                background: #f9f9f9;
                align-items: center;
                justify-content: center;
            }
        }

        .member_email {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;

            p {
                margin-top: 5px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .member_line_box {
            display: flex;
            align-items: center;
            height: 70px;

            .member_line {
                margin: 5%;
                padding-left: 20px;
                width: 50%;
                height: 70px;
                border-radius: 15px;
                background: #f9f9f9;

                .strong_color {
                    margin-top: 10px;
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: #000;
                }
                p {
                    margin-top: 10px;
                    font-size: 0.8rem;
                    font-weight: 500;
                    color: #BBBBBB;
                }
            }
        }

        .member_detail_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px;
            height: 50px;
            background: rgba(97, 123, 227, 0.1);
            border-radius: 10px;

            .member_detail_line {
                display: flex;
                align-items: center;
                width: 40%;
                margin-left: 15%;

                p {
                    margin-left: 10px;
                    margin-top: 15px;
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }
            .member_detail_line2 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60%;

                p {
                    margin-top: 15px;
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }
        }
    }

    .order_function_box {
        margin-top: 50px;
        .user_line_title {
            display: flex;
            align-items: center;

            p {
                margin-top: 15px;
                margin-left: 15px;
                font-size: 0.9rem;
                font-weight: bold;
                color: #999999;
            }
        }

        .function_box_line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .box_line {
                padding: 5%;
                margin-top: 10px;
                width: 30%;
                height: 120px;
                background: #f9f9f9;
                border-radius: 10px;
                cursor: pointer;

                p {
                    margin-top: 15px;
                    font-size: 0.9rem;
                    font-weight: bold;
                }
            }
        }
    }

    .order_member_box {
        margin-top: 80px;

        .user_line_title {
            display: flex;
            align-items: center;

            p {
                margin-top: 15px;
                margin-left: 15px;
                font-size: 0.9rem;
                font-weight: bold;
                color: #999999;
            }
        }

        .member_title {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;

            .member_title_img {
                display: flex;
                height: 100px;
                width: 100px;
                border-radius: 15px;
                background: #f9f9f9;
                align-items: center;
                justify-content: center;
            }
        }

        .member_email {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;

            p {
                margin-top: 5px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .member_line_box {
            display: flex;
            align-items: center;
            height: 70px;

            .member_line {
                margin: 5%;
                padding-left: 20px;
                width: 50%;
                height: 70px;
                border-radius: 15px;
                background: #f9f9f9;

                .strong_color {
                    margin-top: 10px;
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: #000;
                }
                p {
                    margin-top: 10px;
                    font-size: 0.8rem;
                    font-weight: 500;
                    color: #BBBBBB;
                }
            }
        }

        .member_detail_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px;
            height: 50px;
            background: rgba(97, 123, 227, 0.1);
            border-radius: 10px;

            .member_detail_line {
                display: flex;
                align-items: center;
                width: 40%;
                margin-left: 15%;

                p {
                    margin-left: 10px;
                    margin-top: 15px;
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }
            .member_detail_line2 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60%;

                p {
                    margin-top: 15px;
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }
        }

    }
    .table_title {
        margin-top: 50px;
        display: flex;
        height: 50px;

        p {
            margin-top: 5px;
            margin-left: 10px;
            font-size: 0.9rem;
            font-weight: bold;
            color: #999999;
        }
    }

    .search_result_box {
        margin-top: 50px;
        margin-bottom: 100px;
        padding-bottom: 10px;
        background: rgba(97, 123, 227, 0.1);

        .search_result_title {
            display: flex;
            align-items: center;
            padding-top: 30px;
            height: 60px;
            margin-left: 50px;

            p {
                font-size: 1rem;
                font-weight: 700;
            }
        }

        .search_result_content {
            display: flex;
            height: 80px;
            margin: 15px;
            background: #fff;

            .type {
                display: flex;
                align-items: center;
                padding-left: 40px;
                width: 10%;
                height: 80px;

                p {
                    margin-top: 15px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #fff;
                }
            }

            .title {
                display: flex;
                align-items: center;
                width: 30%;
                height: 80px;

                p {
                    margin-top: 15px;
                    font-size: 1rem;
                    font-weight: 500;
                }
            }
            .phone {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40%;
                height: 80px;

                p {
                    margin-top: 15px;
                    font-size: 1rem;
                    font-weight: 500;
                }
            }
            .action {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20%;
                height: 80px;

                .action_button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                    height: 50px;
                    border-radius: 10px;
                    border: 1px solid #13166b;
                    cursor: pointer;

                    p {
                        margin-top: 15px;
                        font-size: 1rem;
                        font-weight: 700;
                        color: #13166b;
                    }
                }

                .action_button:hover {
                    background: #13166b;

                    p {
                        color: #fff;
                    }
                }

            }
        }
    }

    .order_list_title {

        margin-top: 10px;
        padding-top: 15px;
        height: 50px;
        display: flex;
        align-items: center;
        background: #ececec;
        
        p {
            margin-left: 5%;
            font-size: 0.9rem;
            font-weight: 500;
        }
    }

    .order_list {
        height: 500px;
    }
    .null_list {
        height: 100px;
    }
    .container {
        width: 100%;
    }

    .header {
        margin-top: 50px;
        height: 80px;
        width: 100%;
        border-bottom: 2px solid #1b6d74;
        display: flex;
        justify-content: center;
        align-items: center;

        .header_title {
            width: 45%;
            display: flex;
            align-items: center;
            margin-top: 15px;
            
            p {
                margin-left: 20%;
                font-size: 1.1rem;
                font-weight: bold;
            }
        }
        .header_content {
            width: 65%;
            display: flex;
            align-items: center;
            margin-top: 20px;

            .active {
                color: #000;
                font-size: 1rem;
                font-weight: 700;
                text-decoration: underline;
                cursor: pointer;
            }
            .un_active {
                color: #bbbbbb;
                font-size: 1rem;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }

    .select_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        margin-left: 30px;
        height: 50px;
        border-radius: 5px;
    }

    .search_box {
        margin-top: 70px;
        margin-left: 30px;
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;

        .select_date {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35%;
            height: 50px;
            border-bottom: 1px solid #bbbbbb;
            margin-right: 2%;
        }

        .search_name {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35%;
            height: 50px;
            border-bottom: 1px solid #BBBBBB;
            border-radius: 4px;

            input {
                width: 100%;
                padding: 13px;
                outline: none;
                border: none;
            }

            .inputClass {
                border-bottom: 1px solid #13166b;
            }
        }


        .excel_down {
            margin-left: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 5%;
            height: 50px;
            cursor: pointer;
            border-bottom: 1px solid #13166b;
        }

        .click_box {
            margin-left: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            height: 50px;
            border-radius: 20px;
            background: #13166b;
            cursor: pointer;

            p {
                margin-top: 15%;
                color: #fff;
                font-size: 1rem;
                font-weight: 700;
            }
        }

    }

    .order_list_box {
        margin-bottom: 100px;
        .order_title {
            margin-top: 10px;
            display: flex;
            align-items: center;
            height: 40px;
            background: #f9f9f9;

            .title {
                margin-left: 20px;
                display: flex;
                align-items: center;
                width: 60%;
            }
            .count {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 10%;
            }
            .price {
                margin-right: 20px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 30%;
            }
            p {
                margin-top: 10px; 
                font-size: 0.8rem;
                font-weight: 500;
            }
        }

        .order_list_line {
            .order_option_list {
                display: flex;
                align-items: center;
                .title {
                    margin-left: 3%;
                    display: flex;
                    align-items: center;
                    width: 59%;
                }
                .count {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 10%;
                }
                .price {
                    margin-right: 20px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 30%;
                }
                p {
                    margin-top: 10px; 
                    font-size: 0.8rem;
                    font-weight: 500;
                }
            }

            .order_list {
                margin-top: 10px;
                display: flex;
                align-items: center;
                height: 40px;

                .title {
                    margin-left: 20px;
                    display: flex;
                    align-items: center;
                    width: 60%;
                }
                .count {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 10%;
                }
                .price {
                    margin-right: 20px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 30%;
                }
                p {
                    margin-top: 10px; 
                    font-size: 0.9rem;
                    font-weight: 700;
                }
            }
        }
    }

    .columns_box {
        margin-bottom: 150px;
        height: 900px;
    }
    
    .chartBox {
        width: 100%;

        .chartTitle {
            display: flex;
            height: 50px;

            p {
                margin-top: 5px;
                margin-left: 10px;
                font-size: 0.9rem;
                font-weight: bold;
                color: #999999;
            }
        }
    }

    .chartLine {
        margin-top: 70px;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .connectedList {
        margin-top: 70px;
        //background: #f9f9f9;
        border-radius: 25px;
        height: 500px;

        .user_box {
            width: 100%;
            height: 30%;
            margin-left: 30px;
            padding-top: 30px;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #dfdfdf;
            
            .user_line_title {
                display: flex;
                align-items: center;

                p {
                    margin-top: 15px;
                    margin-left: 15px;
                    font-size: 0.9rem;
                    font-weight: bold;
                    color: #999999;
                }
            }

            .user_line_count {
                p {
                    font-size: 1.1rem;
                    font-weight: bold;
                    color: #000;
                }
            }

            p {
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }

        .connected_car_box {
            width: 100%;
            height: 300px;
            margin: 30px;
            border-bottom: 1px solid #dfdfdf;
            .user_line_title {
                display: flex;
                align-items: center;
                p {
                    margin-top: 15px;
                    margin-left: 15px;
                    font-size: 0.9rem;
                    font-weight: bold;
                    color: #999999;
                }
            }

            p {
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
            
            .connected_car_line {
                width: 70%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: #666666;
                }

                .number {
                    font-size: 1.1rem;
                    font-weight: 700;
                    color: #000;
                }
            }
        }
    }
    .store_info_container {
        margin-top: 70px;
        //height: 500px;
        //background: red;

        .title_box {
            width: 100%;
            height: 100px;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #dfdfdf;
            
            .user_line_title {
                display: flex;
                align-items: center;

                p {
                    margin-top: 15px;
                    margin-left: 15px;
                    font-size: 0.9rem;
                    font-weight: bold;
                    color: #999999;
                }
            }

            .user_line_count {
                p {
                    font-size: 1.1rem;
                    font-weight: bold;
                    color: #000;
                }
            }

            p {
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }

        .store_info_box {
            width: 100%;
            border-bottom: 1px solid #dfdfdf;

            .store_info_line {
                width: 100%;
                display: flex;
                margin-top: 10px;
                justify-content: space-between;
                align-items: center;
                height: 40px;

                .title {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: #666666;
                }

                .number {
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: #000;
                }
            }
        }
    }
`;