import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import { Drawer, message } from 'antd';
import "antd/dist/antd.css";

import { UnorderedListOutlined } from '@ant-design/icons';
import addImg from '../../../assets/svg/appNotice.svg';

const WebNotice = (oProps) => {
    const [sLoading, setLoading] = useState(true);

    const [isVisible, setVisible] = useState(false);
    const [dVisible, setDVisible] = useState(false);

    const [data, setData] = useState([]);
    const [sList, setList] = useState({});

    const [sTitle, setTitle] = useState("");
    const [sContent, setContent] = useState("");

    const openData = (sIndex) => {
        setList(sIndex)
        setDVisible(true)
    }

    const insertNoticeData = async () => {
        if(sTitle !== "" && sContent !== ""){
            setLoading(true);
            const oData = {
                userId: oProps.UserConfigReducer.UserId,
                sTitle,
                sContent,
            }
            const oResponse = await oProps.oManager.fDoAxios("/admin/manage/app/insertNotice", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
                if(oResponse){
                    setTitle("");
                    setContent("");
                    asyncFetch();
                } else {
                    message.error("에러");
                }
            }
        }
    }

    const onChangeType = (event) => {
        const value = event.target.value;
        setTitle(value);
    }
    const onChangeName = (event) => {
        const value = event.target.value;
        setContent(value);
    }

    const asyncFetch = async () => {
        const oData = {
            userId: oProps.UserConfigReducer.UserId,
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/manage/app/noticeList", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            setData(oResponse);
        }
        setDVisible(false)
        setLoading(false);
    };

    useEffect(() => {
        asyncFetch();
    }, []);

    return (
        <UserStyle>
            {sLoading ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 500}}>
                    <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                </div> 
                :
                <div className="container">
                    <Row style={{marginTop: 70}}>
                        <Col sm={4} style={{height: 500}}>
                            <div className="add_row">
                                <div className="title">
                                    <div className="title_line">
                                        <div className="insert_button">
                                            <p>스루앱 공지사항 추가하기</p>
                                        </div>
                                    </div>
                                    <div className="insert_button_line">
                                        <div className="insert_button" onClick={() => setVisible(true)}>
                                            <p>추가하기</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <img src={addImg} alt="none" />
                                </div>
                            </div>
                        </Col>
                        <Col sm={8} style={{height: 500}}>
                            <div className="list_row">
                                <div className="title_line">
                                    <UnorderedListOutlined style={{fontSize: "1.5rem"}}/>
                                    <p>스루앱 공지사항 목록</p>
                                </div>
                                <div className="content_line">
                                    {data.map((item,index) => (
                                        <div className="list_box" key={index}>
                                            <div className="list_title">
                                                <div className="list_title_name">
                                                    <p>{item.title}</p>
                                                </div>
                                            </div>
                                            <div className="list_content">
                                                <div className="list_title_name">
                                                    <p>{item.short}</p>
                                                </div>
                                            </div>
                                            <div className="list_button_line">
                                                <div onClick={() => openData(item)} className="list_button">
                                                    <p>자세히</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            <Drawer
                title={<DrawerPTag>공지사항 추가하기</DrawerPTag>}
                width={440}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={isVisible}
            >
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>제목</p>
                            <input 
                                type="text"
                                value={sTitle} 
                                onChange={onChangeType}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        <div style={{height: 250, marginBottom: "30px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>내용</p>
                            <textarea
                                type="text"
                                value={sContent} 
                                onChange={onChangeName}
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px", height: 200}}
                            />
                        </div>
                        <DrawerButton onClick={insertNoticeData}>
                            <p>추가하기</p>
                        </DrawerButton>
                    </Col>
                </Row>
            </Drawer>
            <Drawer
                title={<DrawerPTag>공지사항</DrawerPTag>}
                width={440}
                placement="right"
                closable={false}
                onClose={() => setDVisible(false)}
                visible={dVisible}
            >
                <Row className="justify-content-md-center">
                    <Col sm={12}>
                        <div style={{height: 80, marginBottom: "20px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>제목</p>
                            <input 
                                type="text"
                                value={sList.title}
                                disabled 
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px"}}
                            />
                        </div>
                        <div style={{height: 500, marginBottom: "30px"}}>
                            <p style={{fontSize: "0.8rem", fontWeight: "600", color: "#293241"}}>내용</p>
                            <textarea 
                                type="text"
                                value={sList.content}
                                disabled
                                style={{width: "100%", border: "1px solid #3d5a80", borderRadius: "4px", outline: "none", padding: "12px 12px 12px 12px", height: 400}}
                            />
                        </div>
                    </Col>
                </Row>
            </Drawer>
        </UserStyle>
    )
}

export default WebNotice;


const DrawerPTag = styled.p`
    border-radius: 30px;
    background: #fff;
    white-space: nowrap;
    padding: 0px 5px;
    font-size: 0.9rem;
    outline: none;
    border: none;
    text-decoration: none;
    font-weight: bold;

`;


const DrawerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 10px;
    padding-top: 15px;
    background: #3d5a80;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        font-weight: 500;
        color: #fff;
    }

`;

export const UserStyle = styled.div`
    .container {
        width: 100%;
    }

    .insert_view {
        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
        }
    }

    .add_row {
        border-radius: 10px;
        border: 1px solid #3d5a80;
        width: 100%;
        height: 450px;


        .title {
            height: 300px;

            .title_line {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                height: 120px;

                p {
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }

            .insert_button_line {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                height: 180px;
                
                .insert_button {
                    border-radius: 15px;
                    width: 150px;
                    height: 40px;
                    border: 1px solid #06d6a0;
                    cursor: pointer;

                    p {
                        margin-top: 10px;
                        text-align: center;
                        font-size: 0.9rem;
                        font-weight: 500;
                        color: #06d6a0;
                    }
                }

                .insert_button:hover {
                    background: #06d6a0;
                    p {
                        color: #fff;
                    }
                }
            }
        }
        .content {
            height: 140px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .list_row {
        border-radius: 10px;
        border: 1px solid #98c1d9;
        width: 100%;
        height: 450px;

        .title_line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 50px;
            margin-left: 20px;

            p {
                margin-left: 10px;
                margin-top: 15px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .content_line {
            position: "relative";
            overflow-y: scroll;
            overflow-x: hidden;
            margin : 20px;
            height: 360px;

            .list_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px; 
                height: 50px;
                border-bottom: 1px solid #293241;

                .list_title {
                    width: 40%;
                    height: 50px;
                    .list_title_name {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        p {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                }
                .list_content {
                    width: 40%;
                    height: 50px;

                    .list_title_name {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        p {
                            font-size: 0.8rem;
                            font-weight: 700;
                        }
                    }
                }
                .list_button_line {
                    width: 20%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .list_button {
                        width: 60px;
                        height: 30px;
                        border: 1px solid #0077b6;
                        cursor: pointer;
                        border-radius: 10px;
                        p {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 600;
                            color: #0077b6;
                        }
                    }

                    .list_button:hover {
                        background: #0077b6;
                        p {
                            color: #fff;
                        }
                    }

                    .list_button_delete {
                        width: 60px;
                        height: 30px;
                        border: 1px solid #db3a34;
                        cursor: pointer;
                        border-radius: 10px;
                        p {
                            margin-top: 5px;
                            text-align: center;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: #db3a34;
                        }
                    }

                    .list_button_delete:hover {
                        background: #db3a34;
                        p {
                            color: #fff;
                        }
                    }
                }
            }
        }

    }
`;