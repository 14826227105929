import React, {useState} from 'react';
import SVG from 'react-inlinesvg';
import { Form, Input, Button } from 'antd';
import "antd/dist/antd.css";
import '../../assets/font/font.css';

import { LoginStyle } from "../../assets/styleComponents/LoginStyle";

import throo from "../../assets/svg/throo.svg";

const LoginPage = ({ sManager, fnLogin}) => {
    const [iStep, setStep] = useState(false);

    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    const [sInfo, setInfo] = useState({});

    const [errorMessage, setErrorMessage] = useState("")

    const onIdHandler = (event) => {
        setErrorMessage("");
        setId(event.currentTarget.value);
    }

    const onPasswordHandler = (event) => {
        setErrorMessage("");
        setPassword(event.currentTarget.value);
    }

    const changeState = async () => {
        if (typeof (fnLogin) === 'function') {
            await fnLogin(sInfo);
        }
    }

    const userIdCheckIn = async (oData) => {
        let oUserConfig = {};
        const oResponse = await sManager.fDoAxios("/admin/authenticate", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            if (oResponse.resultCd === "0000") {
                oUserConfig['LoggedIn'] = true;
                oUserConfig['Roll'] = await oResponse.roll;
                oUserConfig['UserId'] = await oResponse.userId;
                oUserConfig['Name'] = await oResponse.full_name;
                oUserConfig['Birthday'] = await oResponse.birthday;
                oUserConfig['Email'] = await oResponse.email;
                oUserConfig['Team'] = await oResponse.team;

                setInfo(oUserConfig);
                setStep(true);

            } else if (oResponse.resultCd === "1111"){
                setErrorMessage("아이디를 다시 확인해주세요");
            } else {
                setErrorMessage("올바르지않은 정보입니다");
            }
        }

        return oUserConfig;
    }

    const passwordCheckIn = async (oData) => {
        const oResponse = await sManager.fDoAxios("/admin/authenticate", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            if (oResponse.resultCd === "0000") {
                changeState();
            } else if (oResponse.resultCd === "2222"){
                setErrorMessage("올바른 정보가 아닙니다");
            } else {
                setErrorMessage("올바르지않은 정보입니다");
            }
        }
    }

    const onSubmitHandler = async () => {
        let oData = { 
            id,
            sParam : "" 
        }

        if(id === ""){
            setErrorMessage("아이디를 입력해주세요.");
        } else {
            if(sInfo.LoggedIn !== undefined && sInfo.LoggedIn){
                oData = { 
                    id,
                    password,
                    sParam : "confirm" 
                }
                await passwordCheckIn(oData);
            } else {
                const result = await userIdCheckIn(oData);
                if(result.LoggedIn !== undefined && result.LoggedIn !== null && !result.LoggedIn){
                    setInfo(result);
                    setStep(true);
                }
            }
        }
    }
    
    return (
        <LoginStyle>
            <div className="loginScreen">
                <div className="sign">
                    <div className="left">
                        <div className="title">
                            <h2>THROO<br /> ADMIN</h2>
                            <p>데이터 확인 및 관리가 가능한<br />  관리 페이지 입니다.</p>
                        </div>
                        <SVG className="logo" src={throo}  title="throologo"/>
                    </div>
                    <div className="right">
                        <div className="container">
                            <div className="signUp">
                                <Form name="login" onFinish={onSubmitHandler}>
                                    {iStep ?
                                        <Input className="inputBox" placeholder="본인인증번호를 입력하세요" value={password} onChange={onPasswordHandler} />
                                    :
                                        <Input className="inputBox" placeholder="아이디를 입력하세요" value={id} onChange={onIdHandler} />
                                    }
                                   
                                    {errorMessage === '' ? <p>스루의 임직원만 로그인 가능합니다.</p> : <p style={{color:"rgb(221, 18, 18)"}}>{errorMessage}</p>}

                                    <Form.Item style={{marginTop: "5%"}}>
                                        <Button type="primary" htmlType="submit">로그인</Button>
                                    </Form.Item>
                                </Form>
                                
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </LoginStyle>
    )
}

export default LoginPage;