import React from 'react';
import { Line } from '@ant-design/charts';


interface ConfigData {
  kChart: any;
}

const Chart: React.FC<ConfigData> = props => {

  return <Line {...props.kChart} />;
};

export default Chart;