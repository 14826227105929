/*global kakao*/
import React, { useState, useEffect } from "react";
import ReactExport from "react-data-export";
import Loader from "react-loader-spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Table, Tag, notification } from 'antd';
import "antd/dist/antd.css";

import { AdjustmentStyle } from '../../assets/styleComponents/AdjustmentStyle';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const sColumns = [
    {
        title: '매장명',
        dataIndex: 'storeNm',
        key: 'storeNm',
        fixed: 'left',
        width: 70,
    },
    {
        title: '입금상태',
        key: 'complete',
        dataIndex: 'complete',
        width: 50,
        fixed: 'left',
        render: complete => {
            let color = complete ? 'geekblue' : 'volcano';
            let tag = complete ? '입금완료' : '입금대기';
            return (
                <Tag color={color}>
                    {tag}
                </Tag>
            )
        }
    },
    {
        title: '주문금액',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        width: 70,
    },
    {
        title: '할인지원금-스루(A)',
        children: [
            {
                title: '적립금',
                dataIndex: 'point',
                key: 'point',
                width: 50,
            },
            {
                title: '쿠폰',
                dataIndex: 'coupon',
                key: 'coupon',
                width: 50,
            },
        ],
    },
    {
        title: '혜택지원금-파트너(B)',
        children: [
            {
                title: '적립금',
                dataIndex: 'pointP',
                key: 'pointP',
                width: 50,
            },
            {
                title: '쿠폰',
                dataIndex: 'couponP',
                key: 'couponP',
                width: 50,
            },
            {
                title: '스탬프',
                dataIndex: 'stemp',
                key: 'stemp',
                width: 50,
            },
        ]
    },
    {
        title: '카드결제(C)',
        dataIndex: 'payment',
        key: 'payment',
        width: 70,
    },
    {
        title: '결제수수료(D)',
        dataIndex: 'fee',
        key: 'fee',
        width: 70,
    },
    {
        title: '입금받을금액',
        dataIndex: 'return',
        key: 'return',
        width: 70,
    },
    {
        title: '은행',
        dataIndex: 'sBank',
        key: 'sBank',
        width: 70,
    },
    {
        title: '계좌번호',
        dataIndex: 'sBankNm',
        key: 'sBankNm',
        width: 100,
    },
    {
        title: '예금주',
        dataIndex: 'sBankUser',
        key: 'sBankUser',
        width: 70,
    },
    {
        title: '대표자 이메일',
        dataIndex: 'sUserEmail',
        key: 'sUserEmail',
        width: 70,
    },
    {
        title: '대표자명',
        dataIndex: 'sUserName',
        key: 'sUserName',
        width: 70,
    },
    {
        title: '대표자전화번호',
        dataIndex: 'sUserPhone',
        key: 'sUserPhone',
        width: 70,
    },
    {
        title: '사업자번호',
        dataIndex: 'sBNm',
        key: 'sBNm',
        width: 70,
    },
];

const AdjustmentPage = (oProps) => {
    const [isLoading, setLoading] = useState(true);
    const [sComplete, setComplete] = useState(false);
    const [sToday, setToday] = useState(null);
    const [orderList, setOrderList] = useState([]);
    const [excelList, setExcelList] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    }

    const completeSettlement = async () => {
        if(orderList.length > 0){
            setLoading(true);
            const oData = {
                userId: oProps.UserConfigReducer.UserId,
                orderList
            }
            const oResponse = await oProps.oManager.fDoAxios("/admin/completeSettlement", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
                if(oResponse){
                    await openNotification("완료 처리되었습니다.");
                    asyncAxios();
                }
            }
        } else {
            openNotification("데이터가 없습니다");
        }
    }

    const openNotification = (sParam) => {
        notification.open({description:sParam});
    };

    const asyncAxios = async () => {
        const oData = {
            userId: oProps.UserConfigReducer.UserId,
        }
        const oResponse = await oProps.oManager.fDoAxios("/admin/settlement", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            setComplete(oResponse.complete);
            setOrderList(oResponse.list);
            setToday(oResponse.lineDate);
            setExcelList(oResponse.excelData);
        }
        setLoading(false);
    }

    useEffect(() => {
        asyncAxios();
    }, [])
    
    return (
        <AdjustmentStyle>
            {isLoading ?
                <div style={{display: "flex", justifyContent: "center", height: 500, alignItems: "center"}}>
                    <Loader type="BallTriangle" color="red" height={80} width={80} timeout={9000000}/> 
                </div>
            :
            <Container>
                <Row>
                    <Col sm={6}>
                        <div className="left_container">
                            <div className="center_item">
                                <div class="inline_title">
                                    <span>정산내역
                                        <p>정산을 받아야하는 각 매장별 정산금액을 확인 가능합니다</p>
                                        <p className="p_date">{sToday} 기준</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="right_container">
                            <div className="left_item">
                                <div class="inline_text">
                                    <span>정산내역서<p>정산 내역서를 확인 가능합니다.</p></span>
                                </div>
                                <ExcelFile filename={`스루_정산명세서-${sToday}`} element={<button className="inline_button">다운로드</button>}>
                                    <ExcelSheet dataSet={excelList} name="요약"/>
                                </ExcelFile>
                            </div>
                            <div className="right_item">
                                <div class="right_inline_text">
                                    <span>정산완료<p>정산완료 상태로 변경합니다</p></span>
                                </div>
                                <button className="inline2_button" disabled={sComplete} onClick={completeSettlement}>변경하기</button>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="content_container">
                            <Table bordered={false} pagination={pagination} columns={sColumns} onChange={handleTableChange} dataSource={orderList} scroll={{ x: 'calc(1500px + 80%)', y: "90vh" }} />
                        </div>
                    </Col>
                </Row>
            </Container>
            }
        </AdjustmentStyle>
    )
}

export default AdjustmentPage;
