import React, { useState } from "react";
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UserList from '../../components/user/UserList';
import UserDetail from '../../components/user/UserDetail';

const UserPage = (oProps) => {
    const [isType, setType] = useState("list");

    return (
        <UserStyle>
            <div className="container">
                <Row>
                    <Col>
                        <div className="header">
                            <div className="header_title">
                                <p>회원정보</p>
                            </div>
                            <div className="header_content">
                                <p className={isType === "list" ? "active" : "un_active"} onClick={() => setType("list")}>회원리스트 조회</p>
                                <p className={isType !== "list" ? "active" : "un_active"} onClick={() => setType("info")} style={{marginLeft:"20px"}}>회원정보 조회</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                {isType === "list" ?
                    <UserList sProps={oProps}/>
                    :
                    <UserDetail sProps={oProps}/>
                }
            </div>
        </UserStyle>
    )
}

export default UserPage;


export const UserStyle = styled.div`

    .result_line {
        margin-top: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;

        .result_left_line {
            border-radius: 10px;
            margin: 10px;
            width: 50%;
            height: 500px;
            background: rgba(97, 123, 227, 0.1);
            position: "relative";
            overflow-y: scroll;
            overflow-x: hidden;

            .search_result_content {
                display: flex;
                height: 80px;
                margin: 15px;
                background: #fff;

                .type {
                    display: flex;
                    align-items: center;
                    padding-left: 5%;
                    width: 20%;
                    height: 80px;

                    p {
                        margin-top: 15px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 0.7rem;
                        font-weight: 400;
                        color: #fff;
                    }
                }

                .title {
                    display: flex;
                    align-items: center;
                    width: 40%;
                    height: 80px;

                    p {
                        margin-top: 15px;
                        font-size: 0.9rem;
                        font-weight: 500;
                    }
                }

                .phone {
                    display: flex;
                    align-items: center;
                    width: 25%;
                    height: 80px;

                    p {
                        margin-top: 15px;
                        font-size: 0.9rem;
                        font-weight: 500;
                    }
                }
                
                .action {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 15%;
                    height: 80px;

                    .action_button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 50%;
                        height: 30px;
                        border-radius: 10px;
                        border: 1px solid #13166b;
                        cursor: pointer;

                        p {
                            margin-top: 15px;
                            font-size: 0.8rem;
                            font-weight: 700;
                            color: #13166b;
                        }
                    }

                    .action_button:hover {
                        background: #13166b;

                        p {
                            color: #fff;
                        }
                    }

                }
            }

        }
        .result__right_line {
            margin: 10px;
            width: 50%;
            height: 500px;

                        
            .subChart {
                display: flex;
                align-items: center;
                height: 50px;

                .rectBox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 5%;
                    border-radius: 15px;
                    background: #f9f9f9;
                    width : 40px;
                    height: 40px;
                }

                .textBox {
                    margin-top: 10px;
                    font-size: 0.9rem;
                    font-weight: bold;
                }
            }
        }
    }
    .member_chart_title {
        margin-top: 100px;
        margin-bottom: 20px;
        margin-left: 50px;
        margin-right: 50px;
        display: flex;
        p {
            margin-top: 5px;
            margin-left: 10px;
            font-size: 0.9rem;
            font-weight: bold;
            color: #999999;
        }
    }

    .member_chart {
        margin-bottom: 100px;
        margin-left: 50px;
        margin-right: 50px;
        height: 500px;
    }

    .member_box {
        margin-top: 100px;
        margin-bottom: 80px;
        height: 500px;

        .member_title {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;

            .member_title_img {
                display: flex;
                height: 100px;
                width: 100px;
                border-radius: 15px;
                background: #f9f9f9;
                align-items: center;
                justify-content: center;
            }
        }

        .member_email {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;

            p {
                margin-top: 5px;
                font-size: 0.9rem;
                font-weight: 700;
            }
        }

        .member_line_box {
            display: flex;
            align-items: center;
            height: 70px;

            .member_line {
                margin: 5%;
                padding-left: 20px;
                width: 50%;
                height: 70px;
                border-radius: 15px;
                background: #f9f9f9;

                .strong_color {
                    margin-top: 10px;
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: #000;
                }
                p {
                    margin-top: 10px;
                    font-size: 0.8rem;
                    font-weight: 500;
                    color: #BBBBBB;
                }
            }
        }

        .member_detail_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px;
            height: 50px;
            background: rgba(97, 123, 227, 0.1);
            border-radius: 10px;

            .member_detail_line {
                display: flex;
                align-items: center;
                width: 40%;
                margin-left: 15%;

                p {
                    margin-left: 10px;
                    margin-top: 15px;
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }
            .member_detail_line2 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60%;

                p {
                    margin-top: 15px;
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }
        }

    }
    .table_title {
        margin-top: 50px;
        display: flex;
        height: 50px;

        p {
            margin-top: 5px;
            margin-left: 10px;
            font-size: 0.9rem;
            font-weight: bold;
            color: #999999;
        }
    }

    .order_list_title {

        margin-top: 10px;
        padding-top: 15px;
        height: 50px;
        display: flex;
        align-items: center;
        background: #ececec;
        
        p {
            margin-left: 5%;
            font-size: 0.9rem;
            font-weight: 500;
        }
    }

    .order_list {
        height: 500px;
    }
    .null_list {
        height: 100px;
    }
    .container {
        width: 100%;
    }

    .header {
        margin-top: 50px;
        height: 80px;
        width: 100%;
        border-bottom: 2px solid #576490;
        display: flex;
        justify-content: center;
        align-items: center;

        .header_title {
            width: 45%;
            display: flex;
            align-items: center;
            margin-top: 15px;
            
            p {
                margin-left: 20%;
                font-size: 1.1rem;
                font-weight: bold;
            }
        }
        .header_content {
            width: 65%;
            display: flex;
            align-items: center;
            margin-top: 20px;

            .active {
                color: #000;
                font-size: 1rem;
                font-weight: 700;
                text-decoration: underline;
                cursor: pointer;
            }
            .un_active {
                color: #BBBBBB;
                font-size: 1rem;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }

    .select_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        margin-left: 30px;
        height: 50px;
        border-radius: 5px;
    }

    .search_box {
        margin-top: 70px;
        margin-left: 30px;
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;

        .select_date {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35%;
            height: 50px;
            border-bottom: 1px solid #bbbbbb;
            margin-right: 2%;
        }

        .search_name {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35%;
            height: 50px;
            border-bottom: 1px solid #BBBBBB;
            border-radius: 4px;

            input {
                width: 100%;
                padding: 13px;
                outline: none;
                border: none;
            }

            .inputClass {
                border-bottom: 1px solid #13166b;
            }
        }


        .excel_down {
            margin-left: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 5%;
            height: 50px;
            cursor: pointer;
            border-bottom: 1px solid #13166b;
        }

        .click_box {
            margin-left: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            height: 50px;
            border-radius: 20px;
            background: #13166b;
            cursor: pointer;

            p {
                margin-top: 15%;
                color: #fff;
                font-size: 1rem;
                font-weight: 700;
            }
        }

    }

    .columns_box {
        margin-bottom: 150px;
        height: 900px;
    }
    
    .chartBox {
        width: 100%;

        .chartTitle {
            display: flex;
            height: 50px;

            p {
                margin-top: 5px;
                margin-left: 10px;
                font-size: 0.9rem;
                font-weight: bold;
                color: #999999;
            }
        }
    }

    .chartLine {
        margin-top: 70px;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .connectedList {
        margin-top: 70px;
        //background: #f9f9f9;
        border-radius: 25px;
        height: 500px;

        .user_box {
            width: 100%;
            height: 30%;
            margin-left: 30px;
            padding-top: 30px;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #dfdfdf;
            
            .user_line_title {
                display: flex;
                align-items: center;

                p {
                    margin-top: 15px;
                    margin-left: 15px;
                    font-size: 0.9rem;
                    font-weight: bold;
                    color: #999999;
                }
            }

            .user_line_count {
                p {
                    font-size: 1.1rem;
                    font-weight: bold;
                    color: #000;
                }
            }

            p {
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }

        .connected_car_box {
            width: 100%;
            height: 300px;
            margin: 30px;
            border-bottom: 1px solid #dfdfdf;
            .user_line_title {
                display: flex;
                align-items: center;
                p {
                    margin-top: 15px;
                    margin-left: 15px;
                    font-size: 0.9rem;
                    font-weight: bold;
                    color: #999999;
                }
            }

            p {
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
            
            .connected_car_line {
                width: 70%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: #666666;
                }

                .number {
                    font-size: 1.1rem;
                    font-weight: 700;
                    color: #000;
                }
            }
        }
    }
`;