import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import ReactExport from "react-data-export";
import { StockOutlined, CloudDownloadOutlined, BarsOutlined, ContactsOutlined, ProfileOutlined } from '@ant-design/icons';
import {
    osName,
    osVersion,
    isMobile
} from "react-device-detect";
import { Table, Select, DatePicker, message, Divider } from 'antd';
import moment from 'moment-timezone';
import Aos from 'aos';
import "aos/dist/aos.css";
import 'moment/locale/ko';
import "antd/dist/antd.css";

import DoubleLineChart from '../../components/charts/DoubleLineChart';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const { Option } = Select;
const { RangePicker } = DatePicker;
const defaultFormat = "YYYY/MM/DD";

const selectOption = [
    {key: "id", name: "이름" },
    {key: "phone", name: "전화번호" },
    {key: "email", name: "email" },
    {key: "date", name: "가입일시" },
    {key: "route", name: "가입경로" },
    {key: "connectedCar", name: "커넥티드카" },
    {key: "totalCount", name: "총구매건수" },
    {key: "mCount", name: "당월구매건수" },
    {key: "recentDate", name: "최근주문일" },
    {key: "carNm", name: "등록차량수량" },
    {key: "cardYn", name: "등록카드수량" },
];

const UserPage = ({ sProps }) => {
    const [isLoading, setLoading] = useState(true);

    const [columnList, setColumnList] = useState(["id","phone","email","date"]);

    const [fromDate, setFromDate] = useState(moment().add(-1,"months").format(defaultFormat));
    const [toDate, setToDate] = useState(moment().format(defaultFormat));

    const [sUserList, setSUserList] = useState({});
    const [iUserList, setIUserList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [excelList, setExcelList] = useState([]);
    const [sColumns,setColumns] = useState([
        {
            title: "전화번호",
            key: "phone",
            dataIndex: "phone",
            fixed: 'left',
            width: 150,
        },
        {
            title: "이름",
            key: "id",
            dataIndex: "id",
            width: 150,
        },
        {
            title: "email",
            key: "email",
            dataIndex: "email",
            width: 200,
        },
        {
            title: "가입일시",
            key: "date",
            dataIndex: "date",
            width: 200,
        }
        
    ])

    const handleChange = async (value) => {
        setColumnList(value);
    }

    const onOperatingDate = (value, dateString) => {
        if(dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== ""){
            if(fromDate === moment().format(defaultFormat)){
                setFromDate(dateString[0]);
            } else {
                setFromDate(dateString[0]);
                setToDate(dateString[1]);
            }
        } else {
            setFromDate(moment().format(defaultFormat));
            setToDate(moment().add(1, 'days').format(defaultFormat));
        }
    }

    const onSearch = async () => {
        setLoading(true);

        let iResult = [];
        for await(const x of columnList) {
            let temp = {};
            temp.title = "";
            temp.key = x;
            temp.dataIndex = x;
            temp.width = 150;
            if(x === "phone"){
                temp.fixed = "left";
            }
             
            for await (const y of selectOption) {
                if(x === y.key){
                    temp.title = y.name;
                }
            }
            iResult.push(temp);
        }
        setColumns(iResult);

        await asyncData(iResult);
    }

    const excelDownload = async () => {
        const oData = { 
            userId: sProps.UserConfigReducer.UserId,
            osVersion: osName + osVersion,
        };
        await sProps.oManager.fDoAxios("/admin/user/userListExcelDownload", "post", null, oData);
        message.info("파일이 정상적으로 다운로드 되었습니다");
    }

    const asyncData = async (iResult) => {
        let oData = {
            userId: sProps.UserConfigReducer.UserId,
            fromDate,
            toDate,
            osVersion: osName + osVersion,
            isMobile,
            columnList: sColumns,
        }
        if(iResult !== undefined){
            oData = {
                userId: sProps.UserConfigReducer.UserId,
                fromDate,
                toDate,
                osVersion: osName + osVersion,
                isMobile,
                columnList: iResult,
            }
        }
        const oResponse = await sProps.oManager.fDoAxios("/admin/user/userData", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            const config1 = {
                data: oResponse.userChartList,
                xField: 'date',
                yField: 'scales',
                seriesField: 'name',
                yAxis: {},
                smooth: true,
                animation: {
                    appear: {
                        animation: 'path-in',
                        duration: 5000,
                    },
                },
            };
            setIUserList(config1);
            setLoading(false);
            setSUserList(oResponse.userData);
            setUserList(oResponse.userList);
            setExcelList(oResponse.excelData);
        }
    }
    useEffect(() => {
        asyncData();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        Aos.init({ duration: 1000 });
    })

    return (
        <>
        <Row>
            <Col>
                <div className="search_box">
                    <div className="select_date">
                        <RangePicker 
                            bordered={false} 
                            className="date_picker"
                            style={{marginLeft: "5%",fontSize: "0rem", width: "90%"}}
                            format={defaultFormat}
                            value={[moment(fromDate, defaultFormat),moment(toDate, defaultFormat)]} 
                            onChange={onOperatingDate}
                        />
                    </div>
                    <div className="click_box" onClick={onSearch}>
                        <p>조회하기</p>
                    </div>
                    {(excelList.length > 0 && !isMobile)?
                        <div className="excel_down" onClick={excelDownload}>
                            <ExcelFile filename={`스루-유저조회${fromDate + "~" + toDate}`} element={<CloudDownloadOutlined style={{fontSize: 25, color: "#13166b"}}/>}>
                                <ExcelSheet dataSet={excelList} name="요약"/>
                            </ExcelFile>
                        </div>
                        :
                        <div className="excel_down" onClick={() => message.info("데이터가 존재하지않습니다.")}>
                            <CloudDownloadOutlined style={{fontSize: 25, color: "#13166b"}}/>
                        </div>
                    }
                </div>
                <div className="select_box">
                    <Select
                        mode="tags"
                        bordered={false}
                        placeholder="Please select"
                        onChange={handleChange}
                        value={columnList}
                        style={{ width: '100%' }}
                    >
                        {selectOption.map((item,index) => {
                            return (
                                <Option key={item.index} value={item.key}>{item.name}</Option>
                            )
                        })}
                    </Select>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm={8}>
                <div className="chartLine">
                    {isLoading ?
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Loader type="BallTriangle" color="#13166b" height={60} width={60} timeout={9000000}/> 
                        </div>
                    :
                        <div className="chartBox" data-aos="fade-up" data-aos-delay="300">
                            <div className="chartTitle">
                                <StockOutlined style={{fontSize: "30px"}}/> 
                                <p>주간 유저 수</p>
                            </div>
                            <DoubleLineChart vChart={iUserList} />
                        </div>
                    }
                </div>
            </Col>
            <Col sm={4}>
                <div className="connectedList" >
                    <div className="user_box" data-aos="fade-up"> 
                        <div className="user_line_title">
                            <BarsOutlined style={{fontSize: "25px"}}/>
                            <p>선택기간동안 가입한 총 유저 수</p>
                        </div>
                        <div className="user_line_count">
                            <p>{sUserList.userCount} 명</p>
                        </div>
                    </div>
                    <div className="connected_car_box" data-aos="fade-up" data-aos-delay="200"> 
                        <div className="user_line_title">
                            <ContactsOutlined style={{fontSize: "25px"}}/>
                            <p>선택기간동안 가입한 커넥티드 카 유저수</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">전체</p>
                            <p className="number">{sUserList.sCount} 명</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">현대</p>
                            <p className="number">{sUserList.hCount} 명</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">기아</p>
                            <p className="number">{sUserList.kCount} 명</p>
                        </div>
                        <div className="connected_car_line">
                            <p className="title">제네시스</p>
                            <p className="number">{sUserList.gCount} 명</p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="table_title">
                    <ProfileOutlined style={{fontSize: "30px"}}/> 
                    <p>조회결과</p>
                </div>
                <div className="columns_box">
                    <Table columns={sColumns} dataSource={userList} pagination={true} scroll={{ x: 'calc(250px + 80%)', y: "90vh" }}/>
                    <Divider orientation="left" style={{color: "#d12f22"}}>
                    대외비 관리 주의사항
                    </Divider>
                    <p style={{color: "#d12f22"}}>
                        본자료에는 고객의 개인정보가 포함된 대외비입니다. 본 자료는 마케팅 참고 자료로만 활용이 가능합니다.<br />
                        본 자료를 회사의 동의 또는 허가없이 외부에 공유할 경우 민형사상 책임이 발생합니다<br />
                        다운로드된 자료는 사용후 저장 및 공유를 금지하며, 1회 목적으로 사용후 폐기하여야 합니다<br />
                        다운로드 이력은 추적관리 되며, 관리소홀로 인한 문제 발생시 회사는 최초 자료 조회자를 포함한 모든 관련자에게 민형사상 책임을 물을 수 있습니다
                    </p>
                </div>
            </Col>
        </Row>
        </>
    )
}

export default UserPage;

