import styled from 'styled-components';

export const MasterPageStyle = styled.div`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans KR', sans-serif;
}
.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    max-width: 1300px;
}

/* contetn1 */
.content1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 5vh;
}
.content1 .box {
    width: 100%;
    height: 55vh;
    border-radius: 20px;
}
.content1 .box:nth-child(1) {
    flex: 0 0 20%;
    background: #FFB802;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.25);
}
.content1 .box:nth-child(2) {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    flex: 0 0 25%;
    background: #fff;
}
.content1 .box:nth-child(3) {
    flex: 0 0 20%;
    background: #094152;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.25);
}
.content1 .box:nth-child(4) {
    flex: 0 0 30%;
    background: #F2F5FD;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.25);
}

.orderList {
    position: "absolute";
    height: 450px;
    background : #fff;
    border-radius : 15px;
    margin: 5%;
}

.userTotal {
    height: 60px;
    background : #fff;
    border-radius: 15px;
    .sourceTitle {
        display: flex;
        height: 50%;
        align-items: center;
        p {
            margin-left: 10%;
            font-size 0.9rem;
        }
    }

    .sourceDetail {
        display: flex;
        height: 50%;
        align-items: center;
        justify-content: space-between;
        p {
            margin-left: 10%;
            margin-right: 5%;
            font-weight: bold;
            font-size 0.9rem;
        }
    }
}

.userDevice {
    height: 140px;
    background : #fff;
    border-radius: 15px;
    .sourceTitle {
        display: flex;
        height: 20%;
        align-items: center;
        p {
            margin-left: 10%;
            font-size 0.9rem;
        }
    }

    .sourceDetail {
        display: flex;
        height: 30%;
        align-items: center;
        justify-content: space-between;
        p {
            margin-left: 10%;
            margin-right: 5%;
            font-weight: bold;
            font-size 0.9rem;
        }
    }
}

.usersource {
    height: 200px;
    background : #fff;
    border-radius: 15px;
    .sourceTitle {
        display: flex;
        height: 13%;
        align-items: center;
        p {
            margin-left: 10%;
            font-size 0.9rem;
        }
    }

    .sourceDetail {
        display: flex;
        height: 20%;
        align-items: center;
        justify-content: space-between;
        p {
            margin-left: 10%;
            margin-right: 5%;
            font-weight: bold;
            font-size 0.9rem;
        }
    }
}


.subChart {
    display: flex;
    align-items: center;
    height: 60px;

    .rectBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5%;
        border-radius: 15px;
        background: #f4a261;
        width : 40px;
        height: 40px;
    }

    .rect2Box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5%;
        border-radius: 15px;
        background: #fff;
        width : 40px;
        height: 40px;
    }

    .rect3Box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5%;
        border-radius: 15px;
        background: #000000;
        width : 40px;
        height: 40px;
    }

    .textBox {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        font-weight: bold;
    }
}

.leftchartBox {
    margin-top: 50px;
    margin-bottom: 100px;
    margin-right: 20px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    height: 600px;
    border-radius: 1vw;
}

.rightchartBox {
    margin-top: 50px;
    margin-bottom: 100px;
    margin-left: 20px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    min-height: 200px;
    max-height: 720px;
    border-radius: 1vw;
}

.left_website_chartBox {
    margin-bottom: 100px;
    margin-right: 20px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    height: 470px;
    border-radius: 1vw;
}

.right_website_chartBox {
    margin-bottom: 100px;
    margin-left: 20px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    height: 470px;
    border-radius: 1vw;
}

.boxWrapper {
    margin : 4%;
}

.website_chartBox {
    margin-left: 6%;
    margin-right: 12%;
    height: 350px;
}

.website_path_chartBox {
    margin : 5%;
    height: 300px;
}

.chartBoxWrapper {
    margin : 8%;
    height: 450px;
}

.boxWrapper2 {
    position: "relative";
    overflow-y: scroll;
    overflow-x: hidden;
    margin : 5%;
    height: 450px;
}

.orderWrapper {
    margin: 5%;
    padding: 3%;

    .orderComplete {
        font-size: 0.7rem;
        font-weight: 900;
        color: #666666;
    }

    .orderCancel {
        font-size: 0.7rem;
        font-weight: 900;
        color: #dd1212;
    }
}

.orderWrapper:hover {
    background-color: #fff;
    border-bottom: 1px solid #000000;
}
.orderDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

li:nth-child(1) {
    font-weight: 900;
    font-size: 0.6rem;
}
.orderDetail p {
    font-size: 0.7rem;
    font-weight: 600;
    color: #666666;
}

@media screen and (max-width: 1000px) {
    /* content1 */
    .content1 {
        flex-direction: column;
    }
    .content1 .box {
        margin-bottom: 2vh;
    }
}

@media screen and (max-width: 840px) {
    .leftchartBox {
        margin-right: 0vh;
    }
    .rightchartBox {
        margin-left: 0vh;
    }
}
@media screen and (max-width: 610px) {
    .leftchartBox {
        margin-right: 0vh;
    }
    .rightchartBox {
        margin-left: 0vh;
    }
}
@media screen and (max-width: 550px) {
    .leftchartBox {
        margin-right: 0vh;
    }
    .rightchartBox {
        margin-left: 0vh;
    }
}
@media screen and (max-width: 450) {
    .leftchartBox {
        margin-right: 0vh;
    }
    .rightchartBox {
        margin-left: 0vh;
    }
}
`;